import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'

export const useQuotes = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()
	const loggedInUser = useSelector((store: AppStore) => store.user)

	const fetchQuotes = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.getAllQuotes
			)
			if (loggedInUser.branch === 'Head Office') return response?.data as any[]
			const filteredQuotes = response.data.filter(
				(quote: any) => quote.branch === loggedInUser.branch
			)
			return filteredQuotes
		} catch (error) {
			showError('Something went wrong getting quotes')
			throw new Error('Something went wrong getting quotes')
		}
	}

	const { data, isLoading, error } = useQuery(['quotes'], fetchQuotes)

	return { data, isLoading, error }
}
