interface IProps {
	void_legend?: boolean
	all?: boolean
}

const visitTypes: { [key: string]: string } = {
	Install: '#C2E4A9',
	Dismantle: '#E0AC89',
	Adjustment: '#EF4444',
	Remedial: '#fbb724',
	Reinstall: '#D6A2D9',
	Removal: '#E0AC89',
	Travel: '#E5B066',
	'Strip/Move': '#FDFEB5',
	Raise: '#D6A2D9',
	Lower: '#ACDAF5',
	Modify: '#A99EF3',
	Drop: '#ACDAF5',
}

const Legend: React.FC<IProps> = ({ void_legend = false, all = false }) => {
	const voidProtectionTypes: string[] = [
		'Install',
		'Drop',
		'Reinstall',
		'Removal',
	]
	const otherJobTypes: string[] = [
		'Install',
		'Raise',
		'Lower',
		'Modify',
		'Strip/Move',
		'Dismantle',
		'Travel',
	]

	const legendItems: string[] = all
		? [...voidProtectionTypes, ...otherJobTypes].filter((value, index, self) => self.indexOf(value) === index)
		: void_legend
		? voidProtectionTypes
		: otherJobTypes

	return (
		<div style={styles.container}>
			<h3 style={styles.title}>Legend</h3>
			<ul style={styles.list}>
				{legendItems.map((type) => (
					<li
						key={type}
						style={{ ...styles.listItem, backgroundColor: visitTypes[type] }}>
						{type}
					</li>
				))}
			</ul>
		</div>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '20px',
	},
	title: {
		marginBottom: '10px',
	},
	list: {
		display: 'flex',
		flexDirection: 'row',
		listStyleType: 'none',
		padding: 0,
	},
	listItem: {
		display: 'flex',
		alignItems: 'center',
		padding: '5px 10px',
		margin: '0 5px',
		borderRadius: '5px',
		boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
		color: '#333',
		fontWeight: 'bold',
		textAlign: 'left',
	},
	colorBox: {
		display: 'inline-block',
		width: '20px',
		height: '20px',
		marginRight: '10px',
	},
}

export default Legend
