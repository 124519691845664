import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'
import { AppStore } from 'redux/store'
import { Roles } from 'models'

export const useWeeklyHireInvoicesByJobId = (job_id?: number) => {
	const userState = useSelector((store: AppStore) => store.user)
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const enableCreateUpdate = userState.userType === Roles.admin

	const fetchWeeklyHireInvoicesByJobId = async (id: number) => {
		try {
			const response = await getRequest(
				AppRoutes.serverInvoiceRoutes.getWeeklyInvoicesByJobId,
				id
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting Weekly Hire Invoices')
			throw new Error('Something went wrong getting Weekly Hire Invoices')
		}
	}

	if (!job_id) {
		return {
			data: null,
			isLoading: null,
			error: null,
			enableCreateUpdate: false,
			fetchWeeklyHireInvoicesByJobId,
		}
	}

	const { data, isLoading, error } = useQuery(['job_weekly_hire', job_id], () =>
		fetchWeeklyHireInvoicesByJobId(job_id)
	)

	return {
		data,
		isLoading,
		error,
		enableCreateUpdate,
		fetchWeeklyHireInvoicesByJobId,
	}
}
