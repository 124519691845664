import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import queryClient from 'context/queryClient'

export const useSendHandoverEmail = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()

	const sendHandoverEmail = async (id: number) => {
		try {
			const response = await postRequest(
				AppRoutes.serverJobsRoutes.sendHandoverEmail,
				null,
				id
			)
			showSuccess('Sending email successful')
			return response
		} catch (error) {
			showError('Sending email failed. Please try again')
			throw new Error((error as Error).message)
		}
	}
	return { sendHandoverEmail }
}
