import { Table } from 'common'
import columns from './Columns'
import { useNotesJobs } from 'services/notes'
import { useAppFiles } from 'services/appfiles'
import { useMemo } from 'react'
import moment from 'moment'

interface JobsTableProps {
	id: string | number
	setShowNotesForm: (open: boolean) => void
	setNoteID: (id: string) => void
}

export const JobsTable = ({
	id,
	setShowNotesForm,
	setNoteID,
}: JobsTableProps) => {
	const { data: notes, isLoading: notesLoading } = useNotesJobs(id)
	const { data: files, isLoading: filesLoading } = useAppFiles()

	const jobFiles = files?.filter((file: any) => file.job_id === id)

	const jobFilesAsNotes = useMemo(() => {
		return jobFiles?.map((file: any) => ({
			id: null,
			fileName: 'Job File',
			fileType: file.file_type,
			fileDescription: file.file_description,
			notes: '',
			fileUrl: file.link,
			createdDate: moment(file.created_at).format('DD/MM/YYYY'),
		}))
	}, [jobFiles])

	const allData = useMemo(() => {
		return [...(notes ?? []), ...(jobFilesAsNotes ?? [])]
	}, [notes, jobFilesAsNotes])

	return (
		<div>
			<Table
				columns={columns({ setShowNotesForm, setNoteID })}
				data={allData}
				isLoading={notesLoading || filesLoading}
				title="Job Notes & Files"
				setShowNotesForm={setShowNotesForm}
			/>
		</div>
	)
}
