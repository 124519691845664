import {
	Document,
	Image,
	Link,
	Page,
	Text,
	View,
	StyleSheet,
} from '@react-pdf/renderer'
import { styles } from './Styles'
import { Heading } from './Heading'
import { ClientInfo } from './Heading/ClientInfo'
import { Table } from './Table'
import moment from 'moment'
import { numberFormat } from 'utilities'

interface DocumentProps {
	invoice_data: any
}

const tableStyles = StyleSheet.create({
	section: {
		fontFamily: 'Rosario',
		margin: 0,
		padding: 0,
		flexGrow: 1,
	},
	tableHeader: {
		width: '100%',
		flexDirection: 'row',
	},
	tableRow: {
		flexDirection: 'row',
		borderBottomColor: '#ebebeb',
	},
	tableHeaderColStart: {
		backgroundColor: '#f0f0f0',
		fontWeight: 'bold',
		padding: 5,
		borderLeft: 1,
		borderTop: 1,
		borderRight: 1,
		borderBottom: 1,
		borderBottomColor: 'black',
		flex: 6,
	},
	tableHeaderCol: {
		backgroundColor: '#f0f0f0',
		fontWeight: 'bold',
		padding: 5,
		borderTop: 1,
		borderRight: 1,
		borderBottom: 1,
		borderBottomColor: 'black',
		flex: 2,
	},
	tableHeaderColEnd: {
		backgroundColor: '#f0f0f0',
		fontWeight: 'bold',
		padding: 5,
		borderTop: 1,
		borderRight: 1,
		borderBottom: 1,
		borderBottomColor: 'black',
		flex: 3,
	},
	tableColStart: {
		flex: 6,
		padding: 5,
		borderLeft: 1,
		borderRight: 1,
		// borderBottom: 1,
		borderBottomColor: 'grey',
	},
	tableCol: {
		flex: 2,
		padding: 5,
		borderRight: 1,
		// borderBottom: 1,
		borderBottomColor: 'grey',
	},
	tableColEnd: {
		flex: 3,
		padding: 5,
		// borderBottom: 1,
		borderRight: 1,
		borderBottomColor: 'grey',
	},
	tableCell: {
		fontSize: 8,
	},
	tableSummaryStart: {
		flex: 6,
		padding: 5,
	},
	tableSummaryMiddle: {
		flex: 2,
		padding: 5,
		textAlign: 'right',
	},
	tableSummaryEnd: {
		flex: 3,
		padding: 5,
		borderLeft: 1,
		borderBottom: 1,
		borderRight: 1,
	},
})

export const InvoiceDocumentPDF = ({ invoice_data }: DocumentProps) => {
	const tableColumnsED = [
		{
			id: 'description',
			heading: 'Description',
			style: 'description',
		},
		{
			id: 'column_ed_1',
			heading: 'Quantity',
			style: 'column_1',
		},
		{
			id: 'column_ed_2',
			heading: 'Price',
			style: 'column_2',
		},
	]

	const tableColumnsWeekly = [
		{
			id: 'description',
			heading: 'Description',
			style: 'description',
		},
		{
			id: 'column_weekly_1',
			heading: 'Quantity',
			style: 'column_1',
		},
		{
			id: 'column_weekly_2',
			heading: 'Price',
			style: 'column_2',
		},
	]

	enum InvoiceType {
		ED = 1,
		WEEKLY = 2,
	}

	const edInvoiceStages = invoice_data.ed_invoices.map(
		(item: any) => item.zone_label
	)

	const weeklyHireStages = invoice_data.weekly_hire_invoices.map(
		(item: any) => item.zone_label
	)
	console.log('ED', edInvoiceStages)
	console.log('WH', weeklyHireStages)

	const stagesSet = Array.from(
		new Set([...edInvoiceStages, ...weeklyHireStages])
	)
	console.log('SS', stagesSet)

	const branch = invoice_data.jobData.branch
	const client = invoice_data.clientData
	const cashAccountCode = branch === 'Adelaide' ? '500410' : '600650'
	const customerCode = client.finance_type === 'Credit' ? client.customer_code : cashAccountCode


	const uniqueStages = []

	for (const stage of stagesSet) {
		const stageEDInvoices = invoice_data.ed_invoices.filter(
			(item: any) => item.zone_label === stage
		)
		const stageWeeklyHireInvoices = invoice_data.weekly_hire_invoices.filter(
			(item: any) => item.zone_label === stage
		)
		const newStage = {
			label: stage,
			ed_invoices: stageEDInvoices,
			weekly_hire_invoices: stageWeeklyHireInvoices,
		}
		uniqueStages.push(newStage)
	}
	console.log('US', uniqueStages)
	console.log(invoice_data)
	// TODO: FIX FLOATING POINT CALCULATION ERRORS USE A FINANCIAL LIBRARY
	const weeklyHireTotals = invoice_data.weekly_hire_invoices.reduce(
		(acc: number, item: any) => {
			const startMoment = moment(item.completed_date, 'DD/MM/YYYY')
			const endMoment = moment(item.date_on_hire, 'DD/MM/YYYY')
			const days = startMoment.diff(endMoment, 'days') + 1
			return acc + (item.weekly_hire_rate / 7) * days
		},
		0
	)

	const edInvoiceTotals = invoice_data.ed_invoices.reduce(
		(acc: number, item: any) => acc + item.invoice_amount,
		0
	)

	const invoiceSubTotal = Number(Number(weeklyHireTotals + edInvoiceTotals).toFixed(2))
	const invoiceSubTotalGST = Number(Number(invoiceSubTotal * 0.1).toFixed(2))
	const invoiceTotal = Number(Number(invoiceSubTotal + invoiceSubTotalGST).toFixed(2))

	const formatDate = (dateStr: string) => {
		const [year, month, day] = dateStr.split('-')
		return `${day}/${month}/${year}`
	}

	const clientSite: string = invoice_data.clientData.site
	const clientSiteSplitByComma = clientSite ? clientSite.split(',') : []

	return (
		<Document title="Oldfields Scaffolding Invoice">
			<Page size="A4" style={styles.page}>
				<Heading />
				<View style={{ flexDirection: 'row', display: 'flex' }}>
					<View
						style={{
							fontFamily: 'Rosario',
							flexDirection: 'column',
							display: 'flex',
							width: '50%',
							paddingRight: 8,
						}}>
						<Text style={{ fontSize: 16 }}>
							Adelaide Scaffold Solutions Pty Ltd
						</Text>
						<Text style={{ fontSize: 10, marginBottom: 8 }}>
							ABN 61 111 683 697
						</Text>
						<Text style={{ fontSize: 8 }}>5-7 Peekarra St</Text>
						<Text style={{ fontSize: 8 }}>Regency Park</Text>
						<Text style={{ fontSize: 8 }}>South Australia, 5010</Text>
						<Text style={{ fontSize: 8, textAlign: 'right' }}>
							Phone: 13 62 60
						</Text>
						<Text style={{ fontSize: 8, textAlign: 'right' }}>
							E-mail: receivables@oldfields.com.au
						</Text>
					</View>
					<View
						style={{
							fontFamily: 'Rosario',
							flexDirection: 'column',
							display: 'flex',
							width: '50%',
							alignContent: 'flex-end',
							justifyContent: 'flex-end',
							paddingLeft: 8,
						}}>
						<Text style={{ fontSize: 8, textAlign: 'right' }}>
							Job Number: {invoice_data.jobData.job_num}
						</Text>
						<Text style={{ fontSize: 8, textAlign: 'right' }}>
							Invoice #: {invoice_data.id}
						</Text>
						<Text style={{ fontSize: 8, textAlign: 'right' }}>
							Date: {new Date(invoice_data.invoice_date).toDateString()}
						</Text>
						<Text style={{ fontSize: 8, textAlign: 'right' }}>
							Purchase Order No:{' '}
							{invoice_data?.jobData?.po_number
								? invoice_data?.jobData?.po_number
								: 'N/A'}
						</Text>
						<Text style={{ fontSize: 8, textAlign: 'right' }}>
							Account #: {customerCode}
						</Text>
					</View>
				</View>
				<View
					style={{ flexDirection: 'row', display: 'flex', marginBottom: 16 }}>
					<View
						style={{
							fontFamily: 'Rosario',
							flexDirection: 'column',
							display: 'flex',
							width: '50%',
							marginTop: 16,
							paddingRight: 8,
						}}>
						<Text style={{ fontSize: 10, fontWeight: 'heavy' }}>Customer:</Text>
						<Text style={{ fontSize: 9, marginTop: 4 }}>
							{invoice_data.clientData.client_name}
						</Text>
						{clientSite
								? clientSiteSplitByComma.map((item: string, index: number) => {
										const length = clientSiteSplitByComma.length
										const isLast = index === length - 1
										const isSecondToLast = index === length - 2
										// If is second to last, return secondTolast, Last
										// If last return nothing
										if (isLast) {
											return null
										}
										if (isSecondToLast) {
											return (
												<Text key={index} style={{ fontSize: 9 }}>
													{item.trim()}, {clientSiteSplitByComma[length - 1].trim()}
												</Text>
											)
										}
										return (
											<Text key={index} style={{ fontSize: 9 }}>
												{item.trim()}{isLast ? '' : ','}
											</Text>
										)
								  })
								: ''}
					</View>
					<View
						style={{
							fontFamily: 'Rosario',
							flexDirection: 'column',
							display: 'flex',
							width: '50%',
							alignContent: 'flex-start',
							justifyContent: 'flex-start',
							marginTop: 16,
							paddingLeft: 8,
						}}>
						<Text style={{ fontSize: 10, fontWeight: 'heavy' }}>
							Site Address:
						</Text>
						<Text style={{ fontSize: 8, textAlign: 'left' }}>
							{invoice_data.jobData.site}
						</Text>
					</View>
				</View>

				<View style={tableStyles.section}>
					<View style={tableStyles.tableHeader}>
						<View style={[tableStyles.tableHeaderColStart]}>
							<Text style={tableStyles.tableCell}>Description</Text>
						</View>
						<View style={tableStyles.tableHeaderCol}>
							<Text style={tableStyles.tableCell}>Qty (Days)</Text>
						</View>
						<View style={tableStyles.tableHeaderCol}>
							<Text style={tableStyles.tableCell}>Daily Rate</Text>
						</View>
						<View style={tableStyles.tableHeaderColEnd}>
							<Text style={tableStyles.tableCell}>Amount</Text>
						</View>
					</View>
					{uniqueStages.map((item: any, index: number) => {
						const stage_total = item.ed_invoices.reduce(
							(acc: number, invoice_item: any) =>
								acc + invoice_item.total_invoiceable,
							0
						)
						const EDInvoicesDisplay = () => {
							return item.ed_invoices.map((invoice: any) => {
								return (
									<View key={index}>
										<View style={[tableStyles.tableRow]}>
											<View style={tableStyles.tableColStart}>
												<Text
													style={[tableStyles.tableCell, { paddingLeft: '8' }]}>
													<Text style={{ fontWeight: 'bold' }}>
														{invoice.zone_label === 'Additional Items'
															? `${invoice.type}`
															: 'Labour'}{' '}
														-{' '}
													</Text>
													{invoice.description}{' '}
													{invoice.type === 'Void Protection'
														? `- Hire Period: ${formatDate(
																invoice.jobData.start_date
														  )} to ${formatDate(invoice.jobData.end_date)}`
														: null}
													{/* {invoice_po_number !== ''
														? `(PO: ${invoice_po_number})`
														: ''} */}
												</Text>
											</View>
											<View style={tableStyles.tableCol}>
												<Text style={tableStyles.tableCell}></Text>
											</View>
											<View style={tableStyles.tableCol}>
												<Text style={tableStyles.tableCell}></Text>
											</View>
											<View style={tableStyles.tableColEnd}>
												<Text
													style={[
														tableStyles.tableCell,
														{ textAlign: 'right' },
													]}>
													{numberFormat.format(invoice.invoice_amount)}
												</Text>
											</View>
										</View>
									</View>
								)
							})
						}

						const WeeklyHireInvoicesDisplay = () => {
							return item.weekly_hire_invoices.map(
								(item: any, index: number) => {
									console.log(item.date_on_hire)
									console.log(item.completed_date)
									const startMoment = moment(item.completed_date, 'DD/MM/YYYY')
									const endMoment = moment(item.date_on_hire, 'DD/MM/YYYY')
									const days = startMoment.diff(endMoment, 'days') + 1
									console.log(days)
									return (
										<View key={index}>
											<View style={tableStyles.tableRow}>
												<View style={tableStyles.tableColStart}>
													<Text
														style={[
															tableStyles.tableCell,
															{ paddingLeft: '8' },
														]}>
														<Text style={{ fontWeight: 'bold' }}>Hire - </Text>
														{item.date_on_hire}-{item.completed_date} (Weekly
														Rate {numberFormat.format(item.weekly_hire_rate)})
													</Text>
												</View>
												<View style={tableStyles.tableCol}>
													<Text
														style={[
															tableStyles.tableCell,
															{ textAlign: 'right' },
														]}>
														{days}
													</Text>
												</View>
												<View style={tableStyles.tableCol}>
													<Text
														style={[
															tableStyles.tableCell,
															{ textAlign: 'right' },
														]}>
														{numberFormat.format(item.weekly_hire_rate / 7)}
													</Text>
												</View>
												<View style={tableStyles.tableColEnd}>
													<Text
														style={[
															tableStyles.tableCell,
															{ textAlign: 'right' },
														]}>
														{numberFormat.format(
															(item.weekly_hire_rate / 7) * days
														)}
													</Text>
												</View>
											</View>
										</View>
									)
								}
							)
						}

						return (
							<div key={index}>
								<View style={tableStyles.tableRow}>
									<View style={tableStyles.tableColStart}>
										<Text style={tableStyles.tableCell}>
											<Text style={{ fontWeight: 'bold' }}>
												Stage - {item.label} -
											</Text>
											Total Quoted: {numberFormat.format(stage_total)}
										</Text>
									</View>
									<View style={tableStyles.tableCol}>
										<Text style={tableStyles.tableCell}></Text>
									</View>
									<View style={tableStyles.tableCol}>
										<Text style={tableStyles.tableCell}></Text>
									</View>
									<View style={tableStyles.tableColEnd}>
										<Text style={tableStyles.tableCell}></Text>
									</View>
								</View>
								<EDInvoicesDisplay />
								<WeeklyHireInvoicesDisplay />
							</div>
						)
					})}
					<View style={tableStyles.tableRow}>
						<View style={tableStyles.tableColStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'bold' }}></Text>
							</Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableColEnd}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
					</View>
					<View style={tableStyles.tableRow}>
						<View style={tableStyles.tableColStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'bold' }}></Text>
							</Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableColEnd}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
					</View>
					<View style={tableStyles.tableRow}>
						<View style={tableStyles.tableColStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'bold' }}></Text>
							</Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableColEnd}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
					</View>
					<View style={tableStyles.tableRow}>
						<View style={tableStyles.tableColStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'bold' }}></Text>
							</Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableColEnd}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
					</View>
					<View style={tableStyles.tableRow}>
						<View style={tableStyles.tableColStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'bold' }}></Text>
							</Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableColEnd}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
					</View>
					<View style={tableStyles.tableRow}>
						<View style={tableStyles.tableColStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'bold' }}></Text>
							</Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableColEnd}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
					</View>
					<View style={tableStyles.tableRow}>
						<View style={tableStyles.tableColStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'bold' }}></Text>
							</Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableColEnd}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
					</View>
					<View style={tableStyles.tableRow}>
						<View style={tableStyles.tableColStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'bold' }}></Text>
							</Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableColEnd}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
					</View>
					<View style={[tableStyles.tableRow, { borderBottom: 1 }]}>
						<View style={tableStyles.tableColStart}>
							<Text style={tableStyles.tableCell}>
								{/* <Text style={{ fontWeight: 'bold' }}>
									Final invoice for Job #:
								</Text> */}
							</Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableCol}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableColEnd}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
					</View>
					<View style={[tableStyles.tableRow]}>
						<View style={tableStyles.tableSummaryStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'bold' }}></Text>
							</Text>
						</View>
						<View style={tableStyles.tableSummaryMiddle}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableSummaryMiddle}>
							<Text style={tableStyles.tableCell}>SUB TOTAL</Text>
						</View>
						<View style={tableStyles.tableSummaryEnd}>
							<Text style={[tableStyles.tableCell, { textAlign: 'right' }]}>
								{numberFormat.format(invoiceSubTotal)}
							</Text>
						</View>
					</View>
					<View style={[tableStyles.tableRow]}>
						<View style={tableStyles.tableSummaryStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'bold' }}></Text>
							</Text>
						</View>
						<View style={tableStyles.tableSummaryMiddle}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableSummaryMiddle}>
							<Text style={tableStyles.tableCell}>GST</Text>
						</View>
						<View style={tableStyles.tableSummaryEnd}>
							<Text style={[tableStyles.tableCell, { textAlign: 'right' }]}>
								{numberFormat.format(invoiceSubTotalGST)}
							</Text>
						</View>
					</View>
					<View style={[tableStyles.tableRow]}>
						<View style={tableStyles.tableSummaryStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'bold' }}></Text>
							</Text>
						</View>
						<View style={tableStyles.tableSummaryMiddle}>
							<Text style={tableStyles.tableCell}></Text>
						</View>
						<View style={tableStyles.tableSummaryMiddle}>
							<Text style={tableStyles.tableCell}>TOTAL</Text>
						</View>
						<View style={tableStyles.tableSummaryEnd}>
							<Text style={[tableStyles.tableCell, { textAlign: 'right' }]}>
								{numberFormat.format(invoiceTotal)}
							</Text>
						</View>
					</View>
				</View>
				<View style={{ flexDirection: 'row', display: 'flex' }}>
					<View
						style={{
							fontFamily: 'Rosario',
							flexDirection: 'column',
							display: 'flex',
							width: '50%',
							marginTop: 16,
							paddingRight: 8,
						}}>
						<Text style={{ fontSize: 10, fontWeight: 'heavy' }}>
							<Link src="https://oldfields.com.au/wp-content/uploads/2024/04/OLH-HIRE-TCs-24.pdf">
								Terms and Conditions (LINK TO VIEW)
							</Link>
						</Text>
						<Text style={{ fontSize: 10, fontWeight: 'heavy', marginTop: 4 }}>
							Payment Options/Terms:
						</Text>
						<Text style={{ fontSize: 9, marginTop: 4 }}>Credit Card:</Text>
						<Text style={{ fontSize: 9, marginTop: 4 }}>
							Please phone 13 62 60 and pay over the phone.
						</Text>
						<Text style={{ fontSize: 9, marginTop: 4 }}>
							(Surcharges Apply)
						</Text>
					</View>
					<View
						style={{
							fontFamily: 'Rosario',
							flexDirection: 'column',
							display: 'flex',
							width: '50%',
							marginTop: 16,
							paddingRight: 8,
						}}>
						<Text style={{ fontSize: 9, marginTop: 38 }}>Direct Deposit:</Text>
						<Text style={{ fontSize: 9, marginTop: 4 }}>
							NAME: Adelaide Scaffold Solutions
						</Text>
						<Text style={{ fontSize: 9, marginTop: 4 }}>BSB: 062-340</Text>
						<Text style={{ fontSize: 9, marginTop: 4 }}>ACC: 1075 8175</Text>
					</View>
				</View>
				<View
					style={{
						fontFamily: 'Rosario',
						flexDirection: 'column',
						display: 'flex',
						textAlign: 'center',
						width: '100%',
						marginTop: 16,
						paddingRight: 8,
					}}>
					<Text style={{ fontSize: 14 }}>Thank you for your business!</Text>
				</View>
			</Page>
		</Document>
	)
}
