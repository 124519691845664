import { Font, StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: 'row',
	},
	heading: {
		fontFamily: 'Rosario',
		fontWeight: 'heavy',
		fontSize: 12,
	},
	description: {
		fontFamily: 'Rosario',
		fontSize: 9,
		marginBottom: 12,
	},
})

Font.register({
	family: 'Rosario',
	src: 'http://fonts.gstatic.com/s/rosario/v11/ezOfwrf8tfghIjoxXsTT9g.ttf',
})
