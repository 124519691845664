export const finance_type_options = [
	{
		value: 'Cash',
		label: 'Cash',
	},
	{
		value: 'Credit',
		label: 'Credit',
	},
]
