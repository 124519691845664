import { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { TimesheetServices, DataTableServices } from 'services'
import { Badge, Container, DataTableHeader, PageHeading } from 'common'
import { Column } from 'primereact/column'
import moment from 'moment'
import { ApproveTimesheet } from 'components/Timesheets'
import { FilterMatchMode } from 'primereact/api'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { EditTimesheet } from 'components/Timesheets/Form'

export const TimesheetsPage = () => {
	const [timesheetsSelected, setTimesheetsSelected] = useState([])
	const [openTimesheetForm, setOpenTimesheetForm] = useState(false)
	const [timesheetId, setTimesheetId] = useState(null)
	const { data, isLoading } = TimesheetServices.useTimesheetsByStatus('Pending')

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'status',
				filterInitialValue: 'Pending',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Pending'],
			},
		],
		additionalGlobalFilterFields: ['staff', 'date', 'comments', 'status'],
	})

	function calcHours(
		startTime: string,
		finishTime: string,
		lunchBreak: number
	) {
		const start = moment(startTime, 'HH:mm')
		const finish = moment(finishTime, 'HH:mm')

		if (finishTime && finish) {
			const duration = moment.duration(finish.diff(start))
			const hours = duration.asHours() - Number(lunchBreak) / 60
			return hours
		}
		return null
	}

	const calculateTimesheetTotal = (row: {
		staff_id: number
		comments: string
	}) => {
		let total = 0

		if (data) {
			for (const timesheet of data) {
				if (
					Number(timesheet.staff_id) === row.staff_id &&
					timesheet.status === 'Pending'
				) {
					const hours = timesheet.time_off
						? Number(
								calcHours(
									timesheet.time_on,
									timesheet.time_off,
									timesheet.lunch_break
								)
						  )
						: 0
					total += hours
				}
			}
		}
		return total
	}

	const headerTemplate = (data: {
		staffData: {
			staff_name: string
		}
	}) => (
		<span className="text-gray-900 font-bold">{data.staffData.staff_name}</span>
	)

	const footerTemplate = (data: any) => (
		<>
			<td
				colSpan={7}
				style={{ textAlign: 'right' }}
				className="bg-gray-100 font-normal">
				Total Hours
			</td>
			<td colSpan={1} className="bg-gray-100 font-semibold">
				{calculateTimesheetTotal(data)}
			</td>
		</>
	)

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	return (
		<div className="card">
			<PageHeading title="Timesheets" />
			<Container>
				<div className="flex justify-between ml-4">
					<ApproveTimesheet timesheets={timesheetsSelected} />
				</div>
				<DataTable
					scrollable
					scrollHeight="70vh"
					ref={dataTableReference}
					value={data}
					loading={isLoading}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowGroupMode="subheader"
					groupRowsBy="staffData.staff_name"
					rowGroupHeaderTemplate={headerTemplate}
					rowGroupFooterTemplate={footerTemplate}
					rowsPerPageOptions={[25, 50, 100]}
					dataKey="id"
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					selection={timesheetsSelected}
					onSelectionChange={(e) => setTimesheetsSelected(e.value as any)}
					emptyMessage="No Timesheets found.">
					<Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
					<Column field="staffData.staff_name" header="Staff" />
					<Column
						header="Date"
						filterField="date"
						field="date"
						style={{ minWidth: '10rem' }}
						filter
						filterType="date"
					/>
					<Column
						header="Actual Start"
						field="actual_start"
						filterField="time_on"
						style={{ minWidth: '10rem' }}
					/>
					<Column
						header="Adjusted Start"
						field="time_on"
						filterField="time_on"
						style={{ minWidth: '10rem' }}
					/>

					<Column
						field="actual_finish"
						header="Actual Finish"
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '12rem' }}
					/>
					<Column
						field="time_off"
						header="Adjusted Finish"
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '12rem' }}
					/>
					<Column
						header="Total Hours"
						body={(row) => {
							return calcHours(row.time_on, row.time_off, row.lunch_break)
						}}
						showFilterMatchModes={false}
						style={{ minWidth: '4rem' }}
					/>
					<Column
						header="Lunch Break"
						field="lunch_break"
						showFilterMatchModes={false}
						style={{ minWidth: '4rem' }}
					/>
					<Column
						field="comments"
						header="Comments"
						bodyClassName="p-text-center"
						style={{ minWidth: '8rem' }}
					/>
					<Column
						header="Status"
						bodyClassName="p-text-center"
						style={{ width: '4rem' }}
						body={(row) => <Badge type={row.status} text={row.status} />}
					/>
					<Column
						field=""
						body={(row) => (
							<button
								onClick={() => {
									setTimesheetId(row.id)
									setOpenTimesheetForm(true)
								}}>
								Edit
							</button>
						)}
					/>
				</DataTable>
			</Container>
			<EditTimesheet
				timesheet_id={timesheetId}
				heading="Edit Timesheet"
				setOpen={setOpenTimesheetForm}
				open={openTimesheetForm}
			/>
		</div>
	)
}
