export function isVoidOrMobileJob(jobType: string) {
	return jobType === 'Void Protection' || jobType === 'Mobile Scaffold'
}

export function isVoidOnly(jobType: string) {
	return jobType === 'Void Protection'
}

export function isMobileOnly(jobType: string) {
	return jobType === 'Mobile Scaffold'
}
