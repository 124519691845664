import { IRates, IRatesHandlers } from 'models'
import { Button, ConfirmationDialog } from 'common'
import { branchOptions } from 'models/branch'
import { QuoteServices } from 'services'
import { XMarkIcon } from '@heroicons/react/24/solid'

interface RatesFormProps {
	rates: IRates[]
	ratesHandlers: IRatesHandlers
	admin?: boolean | undefined
}

export const RatesForm = ({ rates, ratesHandlers, admin }: RatesFormProps) => {
	console.log('Rates admin', admin)
	const { deleteRate } = QuoteServices.useDeleteServiceRate()
	return (
		<>
			<div className="w-full">
				<table className="w-full divide-y divide-gray-200">
					<thead className="bg-gray-100">
						<tr>
							<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
								Service
							</th>
							<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
								Fee
							</th>
							{admin && (
								<>
									<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
										Branch
									</th>
									<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
										Delete
									</th>
								</>
							)}
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{rates?.map((rate, index) => (
							<tr key={index}>
								<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
									<input
										id={`service${rate?.id}`}
										type="text"
										defaultValue={rate?.service}
										className="h-7 rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none px-2"
										name="service"
										onChange={(e) =>
											ratesHandlers.updateRate(index, 'service', e.target.value)
										}
									/>
								</td>
								<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
									<input
										id={`fee${rate?.id}`}
										type="number"
										defaultValue={rate?.fee}
										className="h-7 rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none px-2"
										name="fee"
										onChange={(e) =>
											ratesHandlers.updateRate(
												index,
												'fee',
												Number(e.target.value)
											)
										}
									/>
								</td>
								{admin && (
									<>
										<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
											<select
												id={`branch${rate?.id}`}
												defaultValue={rate?.branch || branchOptions[0].value}
												className="h-7 rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none px-2"
												name="branch"
												onChange={(e) =>
													ratesHandlers.updateRate(
														index,
														'branch',
														e.target.value
													)
												}>
												{branchOptions.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</td>
										<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
											{rate?.id && (
												<ConfirmationDialog
													icon="info"
													title="Delete Rate"
													body="Are you sure you want to delete this rate?"
													triggerButton={
														<button
															type="button"
															id={`${rate?.id}`}
															className="inline-flex items-center text-sm font-medium focus:outline-none rounded-md border-gray-400 border px-1 hover:bg-red-400 hover:text-red-700">
															<XMarkIcon
																className="text-red-600 w-4 h-4 mr-2"
																aria-hidden="true"
															/>
															Delete
														</button>
													}
													confirmButton={
														<Button
															size="sm"
															variant="danger"
															onClick={async () => {
																if (rate?.id) deleteRate(rate?.id)
															}}>
															Delete
														</Button>
													}></ConfirmationDialog>
											)}
										</td>
									</>
								)}
							</tr>
						))}
					</tbody>
				</table>
				<br />
				<Button
					size="sm"
					variant="primary"
					className="w-full"
					onClick={() => ratesHandlers.addNewRate()}>
					Add new rate
				</Button>
			</div>
		</>
	)
}
