import { MakeBlobQuote } from 'components/PDFOutput/Quotes/MakeBlob'
import { QuoteServices } from 'services'
import { useFileUpload } from 'services/api'
export const useUploadQuote = () => {
	const { fetchQuoteById } = QuoteServices.useQuoteById()
	const { fetchQuoteLinesById } = QuoteServices.useQuoteLinesById()
	const { fetchQuoteAddOnsById } = QuoteServices.useQuoteAddonsById()
	const { fetchQuoteFilesByQuoteId } = QuoteServices.useQuoteFilesByQuoteId()
	const { uploadFile } = useFileUpload()

	const uploadQuote = async (quote_id: number) => {
		if (!quote_id) {
			return
		}
		if (
			fetchQuoteById !== undefined &&
			fetchQuoteLinesById !== undefined &&
			fetchQuoteAddOnsById !== undefined
		) {
			const quote_data = await fetchQuoteById(quote_id)
			const quote_lines = await fetchQuoteLinesById(quote_id)
			const quote_addons = await fetchQuoteAddOnsById(quote_id)
			const quote_files = await fetchQuoteFilesByQuoteId(quote_id)
			const blob = await MakeBlobQuote({
				quote_data,
				quote_lines,
				quote_addons,
				quote_files,
			})

			const file = new File([blob], 'quote.pdf', { type: 'application/pdf' })
			const resultUpload = await uploadFile(file, 'quote.pdf')

			return resultUpload?.data?.url
		}
	}

	return { uploadQuote }
}
