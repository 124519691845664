import { pdf } from '@react-pdf/renderer'
import { InvoiceDocumentPDF } from './EDInvoiceOutput'

interface InvoicePDFBlobProps {
	invoice_data: any
}

export const MakeBlobInvoice = async ({
	invoice_data,
}: InvoicePDFBlobProps) => {
	const newBlob = await pdf(InvoiceDocumentPDF({ invoice_data })).toBlob()

	return newBlob
}
