import { useContext, useRef } from 'react'
import { Button, Dropdown, Spinner, Tabs, TextArea } from 'common'
import { QuoteServices, JobsServices } from 'services'
import {
	ZonesForm,
	RatesForm,
	QuoteLinesForm,
	QuoteAdditionalLines,
	Totals,
} from '../FormComponents'
import { useState } from 'react'
import { GeneralInfo } from '../FormComponents/GeneralInfo'
import { RatesAdmin } from '../FormComponents/Rates/RatesAdmin'
import SiteFiles from '../FormComponents/SiteFiles/SiteFiles'
import { NotificationsContext } from 'context/notifications/toastContext'
import { standardAdditionalConditions } from 'models'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import SiteFilesMulti from '../FormComponents/SiteFilesMulti/SiteFilesMulti'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { branchOptions } from 'models/branch'

interface FormProps {
	quoteID?: number
}

export const Form = ({ quoteID }: FormProps) => {
	const userState = useSelector((store: AppStore) => store.user)
	const { showMultipleErrors } = useContext(NotificationsContext)
	const formRef = useRef<HTMLDivElement | null>(null)
	const [tabIndex, setTabIndex] = useState(0)

	const { data: quote_data, isLoading: quote_loading } =
		QuoteServices.useQuoteById(quoteID)

	const { data: quote_lines, isLoading: quote_lines_loading } =
		QuoteServices.useQuoteLinesById(quoteID)

	const { data: quote_addons, isLoading: quote_addons_loading } =
		QuoteServices.useQuoteAddonsById(quoteID)

	const { data: quote_zones, isLoading: quote_zones_loading } =
		QuoteServices.useQuoteZonesById(quoteID)

	const { data: quote_files, isLoading: quote_files_loading } =
		QuoteServices.useQuoteFilesById(quoteID)

	const { data: quote_rates, isLoading: quote_rates_loading } =
		QuoteServices.useQuoteRatesById(quoteID)

	const { data: jobs_data, isLoading: jobs_loading } = JobsServices.useJobs()

	const {
		formik,
		quoteLinesHandlers,
		quoteAdditionalLinesHandlers,
		ratesHandlers,
		zonesHandlers,
		setBackToMainTable,
		itemsTabs,
	} = QuoteServices.useFormHandler({
		quote_id: quoteID,
		quote_data,
		quote_lines,
		quote_addons,
		quote_rates,
		quote_zones,
		quote_files,
	})

	if (
		quote_loading ||
		quote_lines_loading ||
		quote_addons_loading ||
		quote_zones_loading ||
		quote_rates_loading ||
		quote_files_loading ||
		jobs_loading
	) {
		return (
			<div className="absolute top-1/3 right-1/4 transform translate-x-2/3 -translate-y-1/2 bg-white border border-gray-300 rounded-lg p-4 shadow-lg z-50">
				<Spinner />
			</div>
		)
	}

	return (
		<>
			<div className="mx-4 mb-10" ref={formRef}>
				<div className="w-full">
					<div className="flex">
						<div className="lg:w-4/6 md:w-full sm:w-full">
							<div className="flex items-center justify-between">
								{userState?.branch === 'Head Office' && (
									<div className="flex items-center">
										<Dropdown
											label="Branch"
											id="branch"
											options={branchOptions}
											value={String(formik.values.branch)}
											onChange={formik.setFieldValue}
											onBlur={formik.setFieldTouched}
											error={formik.errors.branch}
										/>
									</div>
								)}
							</div>
							<GeneralInfo formik={formik} jobs_data={jobs_data} />
						</div>
						<div className="w-full">
							<div className="w-4/6 mx-auto">
								<div className="flex justify-start mb-4">
									<Tabs
										tabIndex={tabIndex}
										setTabIndex={setTabIndex}
										tabs={itemsTabs}
									/>
								</div>
								{tabIndex === 0 && (
									<ZonesForm
										zones={formik.values.zones}
										zonesHandlers={zonesHandlers}
										errors={formik.errors}
									/>
								)}
								{tabIndex === 1 && (
									<RatesForm
										rates={formik.values.rates}
										ratesHandlers={ratesHandlers}
									/>
								)}
								{tabIndex === 2 && (
									<RatesAdmin
										rates={formik.values.rates}
										ratesHandlers={ratesHandlers}
									/>
								)}
							</div>
						</div>
					</div>

					{formik.values.estimatedWay && (
						<>
							<QuoteLinesForm
								zones={formik.values.zones}
								rates={formik.values.rates}
								quoteLines={formik.values.quote_lines}
								quoteLinesHandlers={quoteLinesHandlers}
								estimatedWayOption={formik.values.estimatedWay}
								formik={formik}
							/>

							<QuoteAdditionalLines
								rates={formik.values.rates}
								additionalLines={formik.values.quote_additional_lines}
								additionalLinesHandlers={quoteAdditionalLinesHandlers}
							/>

							<SiteFilesMulti
								setFieldValue={formik.setFieldValue}
								values={formik.values}
							/>

							<div className="flex w-full">
								<div className="w-1/2 flex-initial">
									<TextArea
										title="Additional Conditions"
										id="terms"
										placeholder="Additional Conditions"
										handleBlur={formik.handleBlur}
										handleChange={formik.handleChange}
										type="text"
										value={formik.values.terms}
										error={formik.errors.terms}
										rows={10}
									/>
								</div>
								<div className="w-1/2  flex-initial">
									<h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
										Additional Conditions
									</h2>
									<div className="columns-2">
										{standardAdditionalConditions.map((condition, index) => (
											<div
												key={index}
												className="flex-initial mt-2 mb-2 basis-1/2 group">
												<button
													key={index}
													className="pl-1 font-semibold leading-5 text-sm text-gray-600 flex items-center "
													onClick={() => {
														if (formik.values.terms === '') {
															formik.setFieldValue(
																'terms',
																formik.values.terms +
																	' • ' +
																	condition.name +
																	': ' +
																	condition.text
															)
														} else {
															formik.setFieldValue(
																'terms',
																formik.values.terms +
																	'\n\n • ' +
																	condition.name +
																	': ' +
																	condition.text
															)
														}
													}}>
													<PlusCircleIcon className="w-6 h-6 ml-1 mr-2 text-indigo-500 group-hover:text-indigo-600" />
													<span className="h-full text-left group-hover:text-indigo-600">
														{condition.name}
													</span>
												</button>
											</div>
										))}
									</div>
								</div>
							</div>

							<Totals
								erectDismantleTotal={formik.values.erectDismantleTotal}
								additionalTotal={formik.values.additionalTotal}
								weekTotal={formik.values.weekTotal}
								total={formik.values.total}
							/>

							{quoteID && (
								<div className="pl-2 mt-6 flex space-x-4">
									<Button
										onClick={() => {
											setBackToMainTable(true)
											formik.handleSubmit()
										}}
										type="submit"
										form="quoteForm"
										//isLoading={quoteCreating}
										size="sm"
										variant={'primary'}>
										Save & Exit
									</Button>
									<Button
										onClick={() => formik.handleSubmit()}
										type="submit"
										form="quoteForm"
										size="sm"
										variant={'primary'}>
										Save & View
									</Button>
								</div>
							)}
							{!quoteID && (
								<div className="pl-2 mt-6">
									<Button
										onClick={() => {
											formik.handleSubmit()
											if (
												formik.errors &&
												Object.keys(formik.errors).length > 0 &&
												formRef.current
											) {
												if ('zones' in formik.errors) {
													delete formik.errors['zones']
													formik.errors.zones = 'Zones are required'
													setTabIndex(0)
												}
												const messages: string[] = ([] as string[]).concat(
													...(Object.values(formik.errors)
														.map((error) =>
															Array.isArray(error) ? error : [error]
														)
														.flat() as string[])
												)
												formRef.current.scrollIntoView({
													behavior: 'smooth',
													block: 'start',
												})

												showMultipleErrors(messages)
											}
										}}
										type="submit"
										form="quoteForm"
										size="sm"
										variant={'primary'}>
										Create Quote
									</Button>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</>
	)
}
