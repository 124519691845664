import {
	DateSelect,
	Dropdown,
	Input,
	SideModal,
	Spinner,
	TextArea,
} from 'common'
import { useFormik } from 'formik'
import { active_inactive_options } from 'models'
import moment from 'moment'
import { StaffServices, VehicleServices } from 'services'
import {
	OptionsForDropdown,
	OptionsForDropdownFilter,
	OptionsForDropdownString,
} from 'utilities'
import * as Yup from 'yup'

const OperationalStatusOptions = [
	{
		value: 'Operational',
		label: 'Operational',
	},
	{
		value: 'Issue',
		label: 'Issue',
	},
]

const branchOptions = [
	{
		value: 'Primary',
		label: 'Primary',
	},
]

interface IProps {
	vehicle_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IInitialValues {
	Rego: string
	Make: string
	Model: string
	CodeName: string
	RegoDue: string
	FuelCardDate: string
	ServiceDueDate: string
	ServiceDueKm: string
	Odometer: string
	Hubometer: string
	RUC: string
	OperationalStatus: string
	Status: string
	Date: string
	Comment: string
	branch: string
	assigned_to: string
}

export const VehiclesForm = ({
	vehicle_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { data: vehicleData, isLoading: vehicleLoading } =
		VehicleServices.useVehicleById(vehicle_id)
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()

	const { createVehicle } = VehicleServices.useCreateVehicle()
	const { updateVehicle } = VehicleServices.useEditVehicle()

	const initialValues: IInitialValues = {
		Rego: vehicleData?.Rego || '',
		Make: vehicleData?.Make || '',
		Model: vehicleData?.Model || '',
		CodeName: vehicleData?.CodeName || '',
		RegoDue: vehicleData?.RegoDue || '',
		FuelCardDate: vehicleData?.FuelCardDate || '',
		ServiceDueDate: vehicleData?.ServiceDueDate || '',
		ServiceDueKm: vehicleData?.ServiceDueKm || '',
		Odometer: vehicleData?.Odometer || '',
		Hubometer: vehicleData?.Hubometer || '',
		RUC: vehicleData?.RUC || '',
		OperationalStatus: vehicleData?.OperationalStatus || 'Operational',
		Status: vehicleData?.Status || 'Active',
		Date: vehicleData?.Date || '',
		Comment: vehicleData?.Comment || '',
		branch: vehicleData?.branch || 'Primary',
		assigned_to: vehicleData?.assigned_to || null,
	}

	const validationSchema = Yup.object({
		Rego: Yup.string().required('Rego is required'),
		branch: Yup.string().required('Branch is required'),
		// Date: Yup.string().required('Date is required'),
		// ServiceDueDate: Yup.string().required('Service Due Date is required'),
		// FuelCardDate: Yup.string().required('Fuel Card Date is required'),
		// RegoDue: Yup.string().required('Rego Due is required'),
	})

	const formik = useFormik({
		initialValues,
		isInitialValid: !staffLoading && !vehicleLoading && vehicle_id != undefined,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create') {
				createVehicle(values)
			}
			if (formType === 'update' && vehicle_id) {
				updateVehicle(vehicle_id, values)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (vehicle_id && vehicleLoading && staffLoading) {
		return (
			<div className="absolute top-1/3 right-1/4 transform translate-x-2/3 -translate-y-1/2 bg-white border border-gray-300 rounded-lg p-4 shadow-lg z-50">
				<Spinner />
			</div>
		)
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							id="Rego"
							title="Rego"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Rego"
							type="text"
							autoComplete="off"
							value={formik.values.Rego}
							error={formik.errors.Rego}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="Make"
							title="Make"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Make"
							type="text"
							autoComplete="off"
							value={formik.values.Make}
							error={formik.errors.Make}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							id="Model"
							title="Model"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Model"
							type="text"
							autoComplete="off"
							value={formik.values.Model}
							error={formik.errors.Model}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="CodeName"
							title="Code Name"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Code Name"
							type="text"
							autoComplete="off"
							value={formik.values.CodeName}
							error={formik.errors.CodeName}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<DateSelect
							id="RegoDue"
							onChange={formik.setFieldValue}
							title="Rego Due"
							value={formik.values.RegoDue}
							error={formik.errors.RegoDue}
						/>
					</div>
					<div className="w-1/2">
						<DateSelect
							id="FuelCardDate"
							onChange={formik.setFieldValue}
							title="Fuel Card Date"
							value={formik.values.FuelCardDate}
							error={formik.errors.FuelCardDate}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<DateSelect
							id="ServiceDueDate"
							onChange={formik.setFieldValue}
							title="Service Due Date"
							value={formik.values.ServiceDueDate}
							error={formik.errors.ServiceDueDate}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="ServiceDueKm"
							title="Service Due Km"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Service Due Km"
							type="text"
							autoComplete="off"
							value={formik.values.ServiceDueKm}
							error={formik.errors.ServiceDueKm}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							id="Odometer"
							title="Odometer"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Odometer"
							type="text"
							autoComplete="off"
							value={formik.values.Odometer}
							error={formik.errors.Odometer}
						/>
					</div>
					{/* <div className="w-1/2">
						<Input
							id="Hubometer"
							title="Hubometer"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Hubometer"
							type="text"
							autoComplete="off"
							value={formik.values.Hubometer}
							error={formik.errors.Hubometer}
						/>
					</div> */}
				</div>
				<div className="flex items-center justify-between px-2">
					{/* <div className="w-1/2">
						<Input
							id="RUC"
							title="RUC"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="RUC"
							type="text"
							autoComplete="off"
							value={formik.values.RUC}
							error={formik.errors.RUC}
						/>
					</div> */}
					<div className="w-1/2">
						<Dropdown
							id="OperationalStatus"
							label="Operational Status"
							onChange={formik.setFieldValue}
							options={OperationalStatusOptions}
							value={formik.values.OperationalStatus}
							error={formik.errors.OperationalStatus}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							id="assigned_to"
							label="Assigned to"
							options={OptionsForDropdownString(staffData, 'id', 'staff_name')}
							value={formik.values.assigned_to}
							onChange={formik.setFieldValue}
							error={formik.errors.assigned_to}
						/>
					</div>
					<div className="w-1/2">
						<Dropdown
							id="branch"
							label="Branch"
							onChange={formik.setFieldValue}
							options={branchOptions}
							value={formik.values.branch}
							error={formik.errors.branch}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							id="Status"
							label="Status"
							onChange={formik.setFieldValue}
							options={active_inactive_options}
							value={formik.values.Status}
							error={formik.errors.Status}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between">
					<div className="w-full">
						<TextArea
							id="Comment"
							placeholder="Comments"
							rows={4}
							title="Comments"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.Comment}
							error={formik.errors.Comment}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
