import { PageHeading } from 'common'
import Legend from 'common/Scheduler/Legend'
import { StaffScheduler } from 'components'

export const StaffSchedulerMain = () => {
	return (
		<div className="px-6 py-6">
			<PageHeading title="Staff Scheduler" isEditable={false}>
				<Legend all />
			</PageHeading>
			<StaffScheduler.StaffScheduler />
		</div>
	)
}
