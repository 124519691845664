import { useQueryClient } from '@tanstack/react-query'
import { MakeBlobInvoice } from 'components/PDFOutput/Invoices/MakeBlob'
import { MakeBlobQuote } from 'components/PDFOutput/Quotes/MakeBlob'
import { AppRoutes } from 'config'
import { NotificationsContext } from 'context/notifications/toastContext'
import { useContext } from 'react'
import {
	InvoiceDocumentsServices,
	InvoiceServices,
	QuoteServices,
} from 'services'
import { useFileUpload } from 'services/api'
import useApi from 'services/api/fetchData'
export const useUploadInvoiceDocument = () => {
	const { fetchInvoiceDocumentByID } =
		InvoiceDocumentsServices.useInvoiceDocumentByID()
	const { fetchWeeklyHireInvoicesByJobId } =
		InvoiceServices.useWeeklyHireInvoicesByJobId()
	const { fetchEDInvoicesByJobId } = InvoiceServices.useEdInvoicesByJobId()
	const { uploadFile } = useFileUpload()

	const uploadInvoiceDocument = async (
		invoice_document_id: number,
		job_id: number
	) => {
		if (!invoice_document_id || !job_id) {
			return
		}

		const invoiceDocumentData = await fetchInvoiceDocumentByID(
			invoice_document_id
		)

		const job_weekly_hire_invoices = await fetchWeeklyHireInvoicesByJobId(
			job_id
		)

		const job_ed_invoices = await fetchEDInvoicesByJobId(job_id)

		const documentWeeklyHireInvoices = job_weekly_hire_invoices?.filter(
			(invoice: { id: any }) =>
				invoiceDocumentData.weekly_hire_items.includes(invoice.id)
		)

		const documentEdInvoices = job_ed_invoices?.filter((invoice: { id: any }) =>
			invoiceDocumentData.ed_items.includes(invoice.id)
		)

		const fullInvoiceData = {
			...invoiceDocumentData,
			weekly_hire_invoices: documentWeeklyHireInvoices,
			ed_invoices: documentEdInvoices,
		}
		const blob = await MakeBlobInvoice({ invoice_data: fullInvoiceData })

		const file = new File([blob], 'invoice.pdf', { type: 'application/pdf' })
		const resultUpload = await uploadFile(file, 'invoice.pdf')

		return resultUpload?.data?.url
	}

	return { uploadInvoiceDocument }
}

export const useSendInvoiceDocumentEmail = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const sendInvoiceDocumentEmail = async (invoice_document_id: number) => {
		try {
			const response = await postRequest(
				AppRoutes.serverInvoiceRoutes.sendInvoiceDocumentEmail,
				{},
				invoice_document_id
			)
			if (response?.data === true) {
				showSuccess('Invoice document sent successfully')
				queryClient.refetchQueries(['invoice_documents'])
				queryClient.refetchQueries(['invoice_documents', invoice_document_id])
			} else {
				showError('Something went wrong sending invoice document')
				console.error('Invoice Document Email Error: ', response)
			}
			return response
		} catch (error) {
			console.log('Invoice Send Email Error: ', error)
			showError('Something went wrong emailing invoice document')
			// throw new Error('Something went wrong Marking as Pending the Quote')
		}
	}

	return {
		sendInvoiceDocumentEmail,
	}
}
