import {
	DateSelect,
	Dropdown,
	Input,
	SideModal,
	Spinner,
	TextArea,
	CreateFile,
	MultiSelect,
} from 'common'
import { useFormik } from 'formik'
import { active_inactive_options } from 'models'
import moment from 'moment'
import { SWMSTasksServices } from 'services'
import {
	OptionsForDropdown,
	OptionsForDropdownFilter,
	OptionsForDropdownString,
} from 'utilities'
import * as Yup from 'yup'

interface IProps {
	task_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IInitialValues {
	description: string
	hazards: string
	risk_score: number
	safety_controls: string
	responsible_parties: string[]
	residual_risk_score: number
	swms_step: string
}

export const SWMSTaskForm = ({
	task_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { data: taskData, isLoading: taskLoading } =
		SWMSTasksServices.useSWMSTaskById(task_id)

	const { createSWMSTask } = SWMSTasksServices.useCreateSWMSTasks()
	const { updateSWMSTask } = SWMSTasksServices.useUpdateSWMSTasks()

	const initialValues: IInitialValues = {
		description: taskData?.description || '',
		hazards: taskData?.hazards || '',
		risk_score: taskData?.risk_score || 0,
		safety_controls: taskData?.safety_controls || '',
		responsible_parties: taskData?.responsible_parties || [],
		residual_risk_score: taskData?.residual_risk_score || 0,
		swms_step: taskData?.swms_step || '',
	}

	const validationSchema = Yup.object({})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create') {
				createSWMSTask(values)
			}
			if (formType === 'update' && task_id) {
				updateSWMSTask(task_id, values)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (task_id && taskLoading) {
		return (
			<div className="absolute top-1/3 right-1/4 transform translate-x-2/3 -translate-y-1/2 bg-white border border-gray-300 rounded-lg p-4 shadow-lg z-50">
				<Spinner />
			</div>
		)
	}

	console.log(taskData)

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between px-2">
					<Input
						id="description"
						title="Description"
						type="text"
						placeholder="Enter a description"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.description}
						error={formik.errors.description}
					/>
				</div>
				<div className="flex items-center justify-between px-2">
					<TextArea
						id="hazards"
						title="Hazards"
						placeholder="Enter hazards"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.hazards}
						error={formik.errors.hazards}
					/>
				</div>
				<div className="flex items-center justify-between px-2">
					<TextArea
						id="safety_controls"
						title="Safety Controls"
						placeholder="Enter safety controls"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.safety_controls}
						error={formik.errors.safety_controls}
					/>
				</div>
				<div className="flex items-center justify-between px-2">
					<Dropdown
						id="swms_step"
						options={[
							{
								label: 'Site Access',
								value: 'Site Access',
							},
							{
								label: 'Prestart',
								value: 'Prestart',
							},
							{
								label: 'Performing Job',
								value: 'Performing Job',
							},
							{
								label: 'Site Clean Up',
								value: 'Site Clean Up',
							},
						]}
						value={formik.values.swms_step}
						onChange={formik.setFieldValue}
						error={formik.errors.swms_step}
						label={'SWMS Step'}
					/>
				</div>
				<div className="flex items-center justify-between px-2">
					<MultiSelect
						id="responsible_parties"
						label="Responsible Parties"
						options={[
							{
								label: 'Project Manager',
								value: 'Project Manager',
							},

							{
								label: 'Site Manager',
								value: 'Site Manager',
							},
							{
								label: 'Site Supervisor',
								value: 'Site Supervisor',
							},
							{
								label: 'Leading Hand',
								value: 'Leading Hand',
							},
							{
								label: 'Worker',
								value: 'Worker',
							},
						]}
						value={formik.values.responsible_parties}
						onChange={formik.setFieldValue}
						error={formik.errors.responsible_parties}
					/>
				</div>
				<div className="flex items-center justify-between px-2">
					<Dropdown
						id="risk_score"
						options={[
							{
								label: '1',
								value: 1,
							},
							{
								label: '2',
								value: 2,
							},
							{
								label: '3',
								value: 3,
							},
							{
								label: '4',
								value: 4,
							},
							{
								label: '5',
								value: 5,
							},
							{
								label: '6',
								value: 6,
							},
						]}
						value={formik.values.risk_score}
						onChange={formik.setFieldValue}
						error={formik.errors.risk_score}
						label={'Risk Score'}
					/>
					<Dropdown
						id="residual_risk_score"
						options={[
							{
								label: '1',
								value: 1,
							},
							{
								label: '2',
								value: 2,
							},
							{
								label: '3',
								value: 3,
							},
							{
								label: '4',
								value: 4,
							},
							{
								label: '5',
								value: 5,
							},
							{
								label: '6',
								value: 6,
							},
						]}
						value={formik.values.residual_risk_score}
						onChange={formik.setFieldValue}
						error={formik.errors.residual_risk_score}
						label={'Residual Risk Score'}
					/>
				</div>
			</SideModal>
		</>
	)
}
