import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useUpdateInvoiceDocument = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const updateInvoiceDocument = async (
		id: string | number,
		document: unknown
	) => {
		try {
			const response = await postRequest(
				AppRoutes.serverInvoiceRoutes.updateDocument,
				document,
				id
			)
			queryClient.refetchQueries(['invoice_documents'])
			queryClient.refetchQueries(['invoice_document', id])
			showSuccess('Invoice Document Updated successfully')
			return response
		} catch (error) {
			showError('Something went wrong updating the Invoice Document')
			throw new Error((error as Error).message)
		}
	}

	return { updateInvoiceDocument }
}
