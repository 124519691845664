import { ScaffoldInspectionTable } from './Tables/ScaffoldInspection'
import { VehiclesTable } from './Tables/Vehicles'

interface FileListProps {
	type: 'vehicles' | 'scaffoldInspection'
	id: string | number
	disableButtons?: boolean
}

export const FileList = ({ type, id, disableButtons }: FileListProps) => {
	if (type === 'vehicles') {
		return <VehiclesTable id={id} />
	}
	if (type === 'scaffoldInspection') {
		return (
			<ScaffoldInspectionTable
				disableButtons={disableButtons || false}
				id={id}
			/>
		)
	}
	return <></>
}
