import {
	DateSelect,
	Dropdown,
	Input,
	SideModal,
	Spinner,
	TextArea,
	CreateFile,
	MultiSelect,
} from 'common'
import { useFormik } from 'formik'
import { active_inactive_options } from 'models'
import moment from 'moment'
import { SWMSDocumentsServices, SWMSTasksServices } from 'services'
import { OptionsForDropdown, OptionsForDropdownFilter } from 'utilities'
import * as Yup from 'yup'

interface IProps {
	document_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IInitialValues {
	description: string
	related_tasks: number[]
}

export const SWMSDocumentForm = ({
	document_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { data: tasksData, isLoading: tasksLoading } =
		SWMSTasksServices.useSWMSTasks()
	const { data: documentData, isLoading: documentLoading } =
		SWMSDocumentsServices.useSWMSDocumentById(document_id)

	const { createSWMSDocument } = SWMSDocumentsServices.useCreateSWMSDocuments()
	const { updateSWMSDocument } = SWMSDocumentsServices.useUpdateSWMSDocuments()

	const initialValues: IInitialValues = {
		description: documentData?.description || '',
		related_tasks: documentData?.related_tasks || [],
	}

	const validationSchema = Yup.object({})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create') {
				createSWMSDocument(values)
			}
			if (formType === 'update' && document_id) {
				updateSWMSDocument(document_id, values)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (document_id && (documentLoading || tasksLoading)) {
		return (
			<div className="absolute top-1/3 right-1/4 transform translate-x-2/3 -translate-y-1/2 bg-white border border-gray-300 rounded-lg p-4 shadow-lg z-50">
				<Spinner />
			</div>
		)
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between px-2">
					<Input
						id="description"
						title="Description"
						type="text"
						placeholder="Enter a description"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.description}
						error={formik.errors.description}
					/>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<MultiSelect
							id="related_tasks"
							label="Related Tasks"
							options={OptionsForDropdown(tasksData, 'id', 'description')}
							value={formik.values.related_tasks}
							onChange={formik.setFieldValue}
							error={formik.errors.related_tasks}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
