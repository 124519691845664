import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useCreateWeeklyHireInvoice = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { putRequest } = useApi()
	const queryClient = useQueryClient()

	const createWeeklyHireInvoice = async (
		job_id: string | number,
		invoice: any
	) => {
		try {
			const response = await putRequest(
				AppRoutes.serverInvoiceRoutes.createWeeklyInvoice,
				invoice,
				job_id
			)
			queryClient.refetchQueries(['invoices'])
			queryClient.refetchQueries(['weeklyHire'])
			queryClient.refetchQueries(['job_weekly_hire'])
			showSuccess('Weekly Hire Invoice Created successfully')
			return response
		} catch (error) {
			showError('Something went wrong creating the edInvoice')
			throw new Error((error as Error).message)
		}
	}

	return { createWeeklyHireInvoice }
}
