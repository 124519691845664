import {
	ChatBubbleLeftEllipsisIcon,
	CheckCircleIcon,
	CheckIcon,
	DocumentCheckIcon,
	EnvelopeIcon,
	PaperAirplaneIcon,
	QuestionMarkCircleIcon,
	XCircleIcon,
} from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Input, TextArea, Button, ConfirmationDialog } from 'common'
import { QuoteData } from 'models'
import { useUploadQuote } from 'services/quotes/UploadQuote'
import { useSendQuote } from 'services/quotes/sendQuote'
import { QuoteServices } from 'services'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'
import { useUpdateQuote } from 'services/quotes'
interface Props {
	quoteId: number
	emailStatus: string
	quote: QuoteData
}

export const EmailStatus = ({ quoteId, emailStatus, quote }: Props) => {
	const [isLoading, setIsLoading] = useState(false)
	const { uploadQuote } = useUploadQuote()
	const { sendEmail, markAsPending, markAsSent } = useSendQuote()
	const { fetchQuoteFilesById } =
		QuoteServices.useQuoteFilesById()

	const clientContact = quote.client_contact ? quote.contactData.name : ''

	const [state, setState] = useState({
		subject: `Quotation #${quote.quote_num}`,
		body: `Hi ${clientContact},

Thank you for the opportunity to be involved with this project.

Site Address: ${quote.street}

Please see our quotation linked at the bottom of this email, which is based on the information provided to us and/or a site visit.
`,
	})

	const newStatus = emailStatus === 'Ready to Send' ? 'Sent' : 'Ready to Send'

	const handleInputChange = (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		const { id, value } = event.target
		setState({
			...state,
			[id]: value,
		})
	}

	return (
		<>
			<ConfirmationDialog
				isDone={isLoading}
				icon="info"
				title="Send Quote"
				body={`Are you sure you want to mark this quote as ${newStatus} ? ${
					newStatus === 'Sent'
						? 'This action will send a quote to the client contact.'
						: ''
				}`}
				triggerButton={
					<button
						type="button"
						disabled={
							quote.status === 'Approved' || quote.status === 'Declined'
						}
						id={`${quoteId}`}
						className="border-gray-300 border px-2 rounded-md ml-3 inline-flex items-center text-sm font-medium focus:outline-none hover:text-green-400">
						{emailStatus === 'Ready to Send' && (
							<PaperAirplaneIcon
								className="-ml-0.5 mr-2 h-6 w-6 text-purple-400"
								aria-hidden="true"
							/>
						)}
						{emailStatus === 'Customer Accepted' && (
							<DocumentCheckIcon
								className="-ml-0.5 mr-2 h-8 w-8 text-green-400"
								aria-hidden="true"
							/>
						)}
						{emailStatus === 'Sent' && (
							<EnvelopeIcon
								className={
									quote.status !== 'Approved' && quote.status !== 'Rejected'
										? '-ml-0.5 mr-2 h-5 w-5 text-orange-400'
										: '-ml-0.5 mr-2 h-5 w-5 text-orange-100'
								}
								aria-hidden="true"
							/>
						)}
						<p
							className={
								quote.status !== 'Approved' && quote.status !== 'Rejected'
									? 'text-gray-600'
									: 'text-gray-300'
							}>
							{emailStatus}
						</p>
					</button>
				}
				confirmButton={
					<Button
						isLoading={isLoading}
						variant="approve"
						size="sm"
						onClick={async () => {
							setIsLoading(true)
							if (!state.body || !state.subject) {
								return
							}

							if (newStatus === 'Sent') {
								const urlQuote = await uploadQuote(quoteId)

								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								let htmlText = ''
								const messageTextArray = state.body.split('\n')

								messageTextArray.forEach((line) => {
									if (line === '') {
										htmlText += '<br />'
									} else {
										htmlText += `<p>${line}</p>`
									}
								})

								htmlText += `<p>You can accept this quotation by replying to this email, or by clicking "Accept Quotation" in the linked quote below.
							<br />If you have any questions or would like to discuss further, please don't hesitate to get in touch.</p> </br>
							
							<p>We look forward to working with you on this project.</p>
							<p>Thanks again,<br>
							${quote.estimatorData.staff_name}</p><br />`

								htmlText += `<p><a href="${urlQuote}">Click here to view quotation</a></p>`
								htmlText += '<br />'
								const quote_files = await fetchQuoteFilesById(quoteId)
								for (const quoteFile of quote_files) {
									if (quoteFile.url.toLowerCase().includes('.pdf')) {
										htmlText += `<p><a href="${quoteFile.url}">View Attached PDF: ${quoteFile.description}</a></p>`
									}
								}

								const emailData = {
									subject: state.subject,
									email: quote.contactData.email,
									pre_body: ``,
									body: htmlText,
									quoteId: quoteId,
								}

								await sendEmail(emailData)
							} else {
								await markAsPending(quoteId)
							}
							setIsLoading(false)
						}}>
						Mark as {newStatus}
					</Button>
				}>
				<>
					{newStatus === 'Sent' && (
						<>
							<div className="flex">
								<div className="w-1/2">
									<Input
										title="Subject"
										id="subject"
										type="text"
										value={state.subject}
										handleChange={handleInputChange}
										error={!state.subject ? 'Subject Is Requied' : ''}
										placeholder={''}
										handleBlur={() => null}
									/>
								</div>
							</div>
							<TextArea
								title="Body Text"
								id="body"
								type="text"
								value={state.body}
								handleChange={handleInputChange}
								rows={10}
								error={!state.body ? 'Body Is Requied' : ''}
							/>
						</>
					)}
				</>
			</ConfirmationDialog>
			{emailStatus === 'Ready to Send' && (
				<ConfirmationDialog
					isDone={isLoading}
					icon="info"
					title="Mark as Sent"
					body={`Are you sure you want to mark as sent? This will not send an email to the client.`}
					triggerButton={
						<button
							type="button"
							disabled={
								quote.status === 'Approved' || quote.status === 'Declined'
							}
							id={`${quoteId}`}
							className="border-gray-300 border mt-2 px-2 rounded-md ml-3 inline-flex items-center text-sm font-medium focus:outline-none hover:text-green-400">
							{emailStatus === 'Ready to Send' && (
								<CheckBadgeIcon
									className="-ml-0.5 mr-2 h-6 w-6 text-purple-400"
									aria-hidden="true"
								/>
							)}
							<p
								className={
									quote.status !== 'Approved' && quote.status !== 'Rejected'
										? 'text-gray-600'
										: 'text-gray-300'
								}>
								Mark as Sent
							</p>
						</button>
					}
					confirmButton={
						<Button
							isLoading={isLoading}
							variant="approve"
							size="sm"
							onClick={async () => {
								setIsLoading(true)
								await markAsSent(quoteId)
								setIsLoading(false)
							}}>
							Confirm
						</Button>
					}></ConfirmationDialog>
			)}
		</>
	)
}
