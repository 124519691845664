import { Dropdown, Input, SideModal, Spinner, DateSelect } from 'common'
import { useFormik } from 'formik'
import { InvoiceServices } from 'services'
import moment from 'moment'
import * as Yup from 'yup'
import { yes_no_options } from 'models'

interface IProps {
	job_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IInitialValues {
	zone_label: string
	type: string
	weekly_hire_rate: string
	on_hire: string
	description: string
	erect_percent: number
	date_on_hire: string | null
	completed_date: string | null
}

export const WeeklyHireInvoiceCreateForm = ({
	job_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { createWeeklyHireInvoice } =
		InvoiceServices.useCreateWeeklyHireInvoice()

	const initialValues: IInitialValues = {
		zone_label: '',
		type: ' ',
		weekly_hire_rate: '',
		on_hire: 'No',
		description: '',
		erect_percent: 0,
		date_on_hire: moment().format('DD/MM/YYYY'),
		completed_date: null,
	}

	const validationSchema = Yup.object({
		erect_percent: Yup.number().max(
			100,
			'Percent Erect must be smaller than or equal to 100'
		),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (job_id) {
				await createWeeklyHireInvoice(job_id, values)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							id="zone_label"
							title="Zone"
							placeholder="Zone"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.zone_label}
							error={formik.errors.zone_label}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="type"
							title="Type"
							placeholder="Type"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.type}
							error={formik.errors.type}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							id="weekly_hire_rate"
							title="Weekly Hire Rate"
							placeholder="Weekly Hire Rate"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.weekly_hire_rate}
							error={formik.errors.weekly_hire_rate}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-full">
						<Input
							id="description"
							title="Description"
							placeholder="Description"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.description}
							error={formik.errors.description}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							id="erect_percent"
							title="Percent Erect"
							placeholder="Percent Erect"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.erect_percent}
							error={formik.errors.erect_percent}
						/>
					</div>

					<div className="w-1/2">
						<Dropdown
							id="on_hire"
							label="On Hire"
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
							options={yes_no_options}
							value={formik.values.on_hire}
							error={formik.errors.on_hire}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<DateSelect
							id="date_on_hire"
							title="Date On Hire"
							onChange={formik.setFieldValue}
							value={formik.values.date_on_hire}
							error={formik.errors.date_on_hire}
						/>
					</div>
					<div className="w-1/2">
						<DateSelect
							id="completed_date"
							title="Completed Date"
							onChange={formik.setFieldValue}
							value={formik.values.completed_date}
							error={formik.errors.completed_date}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
