import { Section, Spinner, Tabs, TwoColumnDetails } from 'common'
import { WeeklyHireTable } from 'components/Invoices'
import { EDInvoicesTable } from 'components/Invoices/EdInvoices/EdInvoicesTable'
import { JobTaskTable, JobVariationsTableTable } from 'components/Jobs'
import { JobDetailView } from 'components/Jobs/Details'
import QuoteAndActualHours from 'components/Jobs/QuoteAndActualHours'
import { Notes } from 'components/Notes'
import { ScaffoldRegisterTable } from 'components/ScaffoldRegister'
import { VisitsTable, VisitsTimesheetsTable } from 'components/Visits'
import { AppRoutes } from 'config'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { JobsServices } from 'services'

const items = [
	{ label: 'Job Details', id: 0 },
	{ label: 'Tasks', id: 1 },
	{ label: 'Visits', id: 2 },
	{ label: 'Visit Timesheets', id: 3 },
	{ label: 'Scaffold Tags', id: 4 },
	{ label: 'Weekly Hire Invoices', id: 5 },
	{ label: 'Fixed Price Invoices', id: 6 },
	{ label: 'Job Files', id: 7 },
	{ label: 'Quoted hours vs. Actual hours', id: 8 },
]

export const JobDetails = () => {
	const { id } = useParams()
	const location = useLocation()
	const jobId = parseInt(id || '') || undefined
	const [tabIndex, setTabIndex] = useState(0)
	const {
		data: job,
		isLoading,
		enableCreateUpdate,
	} = JobsServices.useJobById(jobId)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [handover, setHandover] = useState<any>({})
	const { data, isLoading: handoverLoading } =
		JobsServices.useHandoverByJobId(jobId)
	useEffect(() => {
		if (!data) return
		setHandover(data)
	}, [data])

	if (isLoading || handoverLoading) {
		return <Spinner />
	}

	return (
		<>
			<div className="w-full mx-auto mt-8 mb-28">
				<div className="px-8">
					<Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={items} />
				</div>
				{tabIndex === 0 && <JobDetailView job_id={jobId} />}
				{tabIndex === 1 && (
					<>
						<JobTaskTable job_id={jobId} />
						<JobVariationsTableTable job_id={jobId} client_id={job.client_id} />
					</>
				)}
				{tabIndex === 2 && <VisitsTable job_id={jobId} />}
				{tabIndex === 3 && <VisitsTimesheetsTable job_id={jobId} />}
				{tabIndex === 4 && <ScaffoldRegisterTable job_id={jobId} />}
				{tabIndex === 5 && <WeeklyHireTable job_id={jobId} />}
				{tabIndex === 6 && <EDInvoicesTable job_id={jobId} />}
				{tabIndex === 7 && <Notes type="job" id={id || ''} />}
				{tabIndex === 8 && <QuoteAndActualHours job_id={jobId} />}
			</div>
		</>
	)
}
