import { useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import {
	SWMSDocumentsServices,
	DataTableServices,
	SWMSTasksServices,
} from 'services'
import { Badge, Container, DataTableHeader, PageHeading } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { Link, useLocation } from 'react-router-dom'
import { PencilIcon } from '@heroicons/react/24/solid'
import { AppRoutes } from 'config'
import { AssetsForm } from 'components/Assets'
import { SWMSDocumentForm } from 'components/SWMS/DocumentsForm'

export const SWMSDocuments = () => {
	const location = useLocation()
	const { data: documentsData, isLoading: documentsLoading } =
		SWMSDocumentsServices.useSWMSDocuments()
	const { data: tasksData, isLoading: tasksLoading } =
		SWMSTasksServices.useSWMSTasks()
	const [openSWMSDocumentForm, setOpenSWMSDocumentForm] = useState(false)
	console.log(documentsData)
	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [],
		additionalGlobalFilterFields: ['id'],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	return (
		<div className="card">
			<PageHeading
				title="SWMS Documents"
				createBtn="Create SWMS Document"
				isEditable={false}
				setOpen={setOpenSWMSDocumentForm}
			/>
			<Container>
				<DataTable
					scrollable
					scrollHeight="70vh"
					ref={dataTableReference}
					value={documentsData}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={documentsLoading || tasksLoading}
					dataKey="id"
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					emptyMessage="No SWMS Documents found."
					tableStyle={{ alignItems: 'center' }}>
					<Column
						field="id"
						header="Document ID"
						body={(rowData) => (
							<Link
								to={`${AppRoutes.privateRoutes.SWMSDocumentsEdit.replace(
									':id',
									rowData.id.toString()
								)}`}>
								{rowData.id}
							</Link>
						)}
					/>
					<Column field="description" header="Description" />
					<Column
						field="related_tasks"
						header="Tasks"
						body={(rowData) => {
							if (tasksData !== undefined) {
								console.log('yay')
							}
							const tasksInRelatedTasks = tasksData?.filter((task: any) =>
								rowData.related_tasks.includes(Number(task.id))
							)
							return (
								<div className="flex flex-col">
									{tasksInRelatedTasks?.map((item: any, index: any) => (
										<Badge
											key={index}
											text={item.description}
											type={'Active'}
										/>
									))}
								</div>
							)
						}}
					/>
					<Column
						header="Edit"
						body={(rowData) => (
							<Link
								to={{
									pathname: AppRoutes.privateRoutes.SWMSDocumentsEdit.replace(
										':id',
										rowData.id
									),
								}}
								state={{ background: location, name: 'editSWMSDocuments' }}>
								<PencilIcon className="text-gray-600 h-4 w-4" />
							</Link>
						)}
					/>
				</DataTable>
			</Container>
			<SWMSDocumentForm
				formType="create"
				heading="Create SWMS Document"
				open={openSWMSDocumentForm}
				setOpen={setOpenSWMSDocumentForm}
			/>
		</div>
	)
}
