import { DateSelect, Dropdown, MultiSelect, SideModal, Spinner } from 'common'
import { DateSelectTypeDate } from 'common/DateSelect/DateSelectTypeDate'
import { branchOptions } from 'models/branch'
import { log } from 'console'
import { useFormik } from 'formik'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'

import { CrewsServices, StaffServices, VehicleServices } from 'services'
import { OptionsForDropdown, OptionsForDropdownFilter } from 'utilities'
import * as Yup from 'yup'

interface IProps {
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

const statusOptions = [
	{ label: 'Active', value: 'Active' },
	{ label: 'Inactive', value: 'Inactive' },
]

interface CreateCrewDTO {
	crew_leader_id: number | null
	staff_members: number[]
	vehicle_id: number | null
	branch: string | null
}

export const CreateCrewsForm = ({
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { createCrew } = CrewsServices.useCreateCrews()
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()
	const { data: crewsData, isLoading: crewsLoading } = CrewsServices.useCrews()
	const loggedInUser = useSelector((store: AppStore) => store.user)

	const { data: vehiclesData, isLoading: vehiclesLoading } =
		VehicleServices.useVehicles()

	const todaysDate = moment(Date.now()).format('YYYY-MM-DDT00:00:00.000\\Z')

	const initialValues: CreateCrewDTO = {
		crew_leader_id: null,
		staff_members: [],
		vehicle_id: null,
		branch: loggedInUser?.branch,
	}

	const validationSchema = Yup.object({})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			const { crew_leader_id, staff_members, vehicle_id, branch } = values

			const crewPayload = {
				crew_leader_id,
				staff_members,
				vehicle_id: vehicle_id ? Number(vehicle_id) : null,
				branch,
			}

			const resultCrew = await createCrew(crewPayload)

			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (staffLoading || vehiclesLoading) {
		if (open) {
			return (
				<div className="absolute top-1/3 right-1/4 transform translate-x-2/3 -translate-y-1/2 bg-white border border-gray-300 rounded-lg p-4 shadow-lg z-50">
					<Spinner />
				</div>
			)
		} else {
			return <></>
		}
	}

	const teamLeaders = staffData?.filter(
		(staff: any) => staff.type === 'Crew Leader'
	)

	const staffWithCrew = () => {
		if (!crewsLoading && crewsData) {
			const staffWithCrew: number[] = []
			crewsData.map((crew: any) =>
				crew?.staff_members.map((staff: any) => staffWithCrew.push(staff?.id))
			)
			return staffWithCrew
		}
		return []
	}

	const staffAlreadyCrewLeader = () => {
		if (!crewsLoading && crewsData) {
			const staffAlreadyCrewLeader: number[] = []
			crewsData.map((crew: any) =>
				staffAlreadyCrewLeader.push(crew?.crew_leader?.id)
			)
			return staffAlreadyCrewLeader
		}
		return []
	}

	const vehiclesWithCrew = () => {
		if (!crewsLoading && crewsData) {
			const vehiclesWithCrew: number[] = []
			crewsData.map((crew: any) => vehiclesWithCrew.push(crew?.vehicle?.id))
			return vehiclesWithCrew
		}
		return []
	}

	console.log(vehiclesData)

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between px-2">
					{loggedInUser?.branch === 'Head Office' && (
						<div className="flex items-center">
							<Dropdown
								label="Branch"
								id="branch"
								options={branchOptions}
								value={String(formik.values.branch)}
								onChange={formik.setFieldValue}
								onBlur={formik.setFieldTouched}
								error={formik.errors.branch}
							/>
						</div>
					)}
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Dropdown
							id="crew_leader_id"
							label="Team Leader"
							options={OptionsForDropdown(
								staffData?.filter(
									(staff: any) =>
										staffAlreadyCrewLeader().includes(staff.id) === false && staff.branch === formik.values.branch
								),
								'id',
								'staff_name'
							)}
							value={formik.values.crew_leader_id}
							onChange={formik.setFieldValue}
							error={formik.errors.crew_leader_id}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<MultiSelect
							id="staff_members"
							label="Staff"
							options={OptionsForDropdownFilter(
								staffData?.filter(
									(staff: any) =>
										staffWithCrew().includes(staff.id) === false &&
										staff.type !== 'Office' &&
										staff.branch === formik.values.branch
								),
								'id',
								['staff_name'],
								'status',
								'Active'
							)}
							value={formik.values.staff_members}
							onChange={formik.setFieldValue}
							error={formik.errors.staff_members}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Dropdown
							id="vehicle_id"
							label="Vehicle"
							options={OptionsForDropdown(
								vehiclesData.filter(
									(vehicle: any) =>
										vehiclesWithCrew().includes(vehicle.id) === false &&
										vehicle.branch === formik.values.branch
								),
								'id',
								'CodeName'
							)}
							value={formik.values.vehicle_id}
							onChange={formik.setFieldValue}
							error={formik.errors.vehicle_id}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
