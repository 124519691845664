import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'

export const useCreateClients = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { putRequest } = useApi()
	const queryClient = useQueryClient()
	const loggedInUser = useSelector((store: AppStore) => store.user)

	const createClient = async (Client: any) => {
		try {
			const clientWithBranch = {
				...Client,
				branch: loggedInUser.branch,
			}
			const response = await putRequest(
				AppRoutes.serverClientRoutes.createClient,
				clientWithBranch
			)
			queryClient.refetchQueries(['clients'])
			showSuccess('Client created successfully')
			return response.data
		} catch (error) {
			showError((error as Error).message)
			throw new Error((error as Error).message)
		}
	}
	return { createClient }
}
