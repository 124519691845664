import { Dropdown } from 'primereact/dropdown'
import { OptionsForDropdown, numberFormat } from 'utilities'
import {
	IQuoteForm,
	IQuoteLine,
	IQuoteZones,
	IRates,
	IquoteLinesHandlers,
	estimatedWay,
} from 'models'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Button } from 'primereact/button'
import { ConfirmationDialog } from 'common'
import '../customStyle.css'
import { FormikProps } from 'formik/dist/types'

interface QuoteLineProps {
	zones: IQuoteZones[]
	rates: IRates[]
	data: IQuoteLine
	quoteLinesHandlers: IquoteLinesHandlers
	index: number
	estimatedWayOption: estimatedWay
	formik?: FormikProps<IQuoteForm>
}

export const QuoteLine = ({
	zones,
	rates,
	data,
	index,
	quoteLinesHandlers,
	estimatedWayOption,
	formik,
}: QuoteLineProps) => {
	const handleProductLineChange = (
		index: number,
		field: keyof IQuoteLine,
		value: string | number
	) => {
		quoteLinesHandlers.updateQuoteLine(index, field, value)
	}

	return (
		<tr>
			<td className="px-0.5 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="zone_id"
					options={OptionsForDropdown(zones, 'zone_id', 'zone_label')}
					id={`zone_id${data.id}`}
					value={data.zone_id}
					onChange={(e) =>
						handleProductLineChange(index, 'zone_id', e.target.value)
					}
					className={`w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${
						formik?.errors?.quote_lines &&
						(formik.errors.quote_lines as any)[index]?.zone_id
							? 'ring-red-500 ring-1'
							: ''
					}`}
				/>
			</td>
			<td className="px-0.5 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="Type"
					options={OptionsForDropdown(rates, 'service', 'service')}
					id={`type${data.id}`}
					value={data.type}
					onChange={(e) =>
						handleProductLineChange(index, 'type', e.target.value)
					}
					className={`w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${
						formik?.errors?.quote_lines &&
						(formik.errors.quote_lines as any)[index]?.type
							? 'ring-red-500 ring-1'
							: ''
					}`}
				/>
			</td>
			<td className="px-2 py-1 h-8 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
				<input
					id={`description${data.id}`}
					type="text"
					className={`h-8 px-2 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center ${
						formik?.errors?.quote_lines &&
						(formik.errors.quote_lines as any)[index]?.description
							? 'ring-red-500 ring-1'
							: ''
					}`}
					value={data.description}
					onChange={(e) =>
						handleProductLineChange(index, 'description', e.target.value)
					}
				/>
			</td>
			{estimatedWayOption === estimatedWay.Hours ? (
				<>
					<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
						<input
							id={`staff_count${data.id}`}
							type="number"
							className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
							value={data.staff_count}
							onChange={(e) =>
								handleProductLineChange(
									index,
									'staff_count',
									Number(e.target.value)
								)
							}
						/>
					</td>
					<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
						<input
							id={`erect_hours${data.id}`}
							type="number"
							className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
							value={data.erect_hours}
							onChange={(e) =>
								handleProductLineChange(
									index,
									'erect_hours',
									Number(e.target.value)
								)
							}
						/>
					</td>
					<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
						<input
							id={`dismantle_hours${data.id}`}
							type="number"
							className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
							value={data.dismantle_hours}
							onChange={(e) =>
								handleProductLineChange(
									index,
									'dismantle_hours',
									Number(e.target.value)
								)
							}
						/>
					</td>
				</>
			) : null}
			{estimatedWayOption === estimatedWay.SQM ? (
				<>
					<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
						<input
							id={`length${data.id}`}
							type="number"
							className="h-8 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
							value={data.length}
							onChange={(e) =>
								handleProductLineChange(index, 'length', Number(e.target.value))
							}
						/>
					</td>
					<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
						<input
							id={`height${data.id}`}
							type="number"
							className="h-8 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
							value={data.height}
							onChange={(e) =>
								handleProductLineChange(index, 'height', Number(e.target.value))
							}
						/>
					</td>
					<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
						<input
							id={`width${data.id}`}
							type="number"
							className="h-8 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
							value={data.width}
							onChange={(e) =>
								handleProductLineChange(index, 'width', Number(e.target.value))
							}
						/>
					</td>
					<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
						<input
							id={`total_dimensions${data.id}`}
							type="number"
							className="h-8 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
							value={data.total_dimensions}
							onChange={(e) =>
								handleProductLineChange(
									index,
									'total_dimensions',
									Number(e.target.value)
								)
							}
							disabled
						/>
					</td>
				</>
			) : null}
			{estimatedWayOption !== estimatedWay.SQM ? (
				<>
					<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
						<input
							id={`quantity${data.id}`}
							type="number"
							className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
							value={data.quantity}
							disabled={
								!data.type ||
								estimatedWayOption === estimatedWay.TotalED ||
								estimatedWayOption === estimatedWay.Hours
							}
							onChange={(e) =>
								handleProductLineChange(
									index,
									'quantity',
									Number(e.target.value)
								)
							}
						/>
					</td>
					{/* <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
						<input
							id={`total_days${data.id}`}
							type="number"
							className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
							value={data.total_days}
							disabled
							onChange={(e) =>
								handleProductLineChange(index, 'total_days', e.target.value)
							}
						/>
					</td> */}
				</>
			) : null}
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`erect_and_dismantle${data.id}`}
					type="text"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
					value={Number(data.erect_and_dismantle)}
					onChange={(e) =>
						handleProductLineChange(
							index,
							'erect_and_dismantle',
							parseFloat(e.target.value.replace(/[^\d.-]/g, '')) || 0
						)
					}
					disabled={estimatedWayOption === estimatedWay.Hours}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`weekly_hire_fee${data.id}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none"
					value={Number(data.weekly_hire_fee)}
					onChange={(e) =>
						handleProductLineChange(
							index,
							'weekly_hire_fee',
							Number(e.target.value)
						)
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`sundries${data.id}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none"
					value={Number(data.sundries)}
					onChange={(e) =>
						handleProductLineChange(
							index,
							'sundries',
							Number(e.target.value)
						)
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`hire_period${data.id}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none"
					value={Number(data.hire_period)}
					onChange={(e) =>
						handleProductLineChange(
							index,
							'hire_period',
							Number(e.target.value)
						)
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`weekly_hire_after_period${data.id}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none"
					value={Number(data.weekly_hire_after_period)}
					onChange={(e) =>
						handleProductLineChange(
							index,
							'weekly_hire_after_period',
							Number(e.target.value)
						)
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`total${data.id}`}
					type="text"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-500 focus:outline-none"
					value={numberFormat.format(Number(data.total))}
					disabled
				/>
			</td>
			<td className="px-2 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap w-8">
				<ConfirmationDialog
					icon="danger"
					title="Delete Line Item"
					body="Are you sure you want to delete this item? This action is unrecoverable!"
					triggerButton={
						<button type="button">
							<XCircleIcon
								className="flex-shrink-0 h-4 w-4 text-red-500"
								aria-hidden="true"
							/>
						</button>
					}
					confirmButton={
						<Button
							className="bg-red-600 text-white"
							onClick={async () => quoteLinesHandlers.removeQuoteLine(index)}>
							Delete Line
						</Button>
					}
				/>
			</td>
		</tr>
	)
}
