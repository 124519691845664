import { ErrorComponent, Spinner } from 'common'
import { InvoiceDocumentPDFViewer } from 'components/PDFOutput/Invoices'

import { useParams } from 'react-router-dom'
import { InvoiceServices } from 'services'

export const ViewInvoicePDF = () => {
	const { invoiceType, id } = useParams()
	const invoiceID = parseInt(id || '') || undefined
	const invoiceTypeLower = invoiceType?.toLowerCase()

	// if (quote_loading || quote_lines_loading || quote_addons_loading || quote_files_loading) {
	// 	return <Spinner />
	// }

	if (invoiceID == undefined) {
		return (
			<ErrorComponent error_message="An error has occurred please return to home and try again later." />
		)
	}

	return (
		<>
			<InvoiceDocumentPDFViewer invoice_id={invoiceID} />
		</>
	)
}
