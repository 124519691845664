import React from 'react'
import { DateSelect, CreateFile, Dropdown, Checkbox } from 'common'
import { IStaffRow } from 'models'

type StaffProps = {
	values: {
		staff_file: string
		staff_date: string
		staff_expiry_date: string
		staff_uploaded_by: string | null
		staff_class: string
	}

	staff: IStaffRow[] | undefined
	setFieldValue: (
		field: string,
		value: string | number | boolean | Date | null
	) => void
	setFieldTouched: (field: string, touched?: boolean) => void
}

const StaffOptions = [
	{ value: 'Induction', label: 'Induction' },
	{
		value: 'Permission to Drive Company Vehicle',
		label: 'Permission to Drive Company Vehicle',
	},
	{ value: 'Layher Familiarisation', label: 'Layher Familiarisation' },
]

export const Staff: React.FC<StaffProps> = ({
	values,
	staff,
	setFieldValue,
	setFieldTouched,
}) => {
	const renderStaffList = (): { label: string; value: string }[] => {
		if (staff && staff?.length > 0) {
			return staff.map((item) => ({
				label: item.staff_name,
				value: item.id || '',
			}))
		}
		return []
	}
	return (
		<>
			<h3 className="px-4 py-2 text-lg font-semibold leading-5 ">Staff</h3>
			<div>
				<div className="flex items-center px-2">
					<DateSelect
						title="Date"
						id="staff_date"
						value={values.staff_date}
						onChange={setFieldValue}
					/>
					<DateSelect
						title="Expiry Date"
						id="staff_expiry_date"
						value={values.staff_expiry_date}
						onChange={setFieldValue}
					/>
				</div>
				<div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-2">File</h4>
					<CreateFile
						field="staff_file"
						setFieldValue={setFieldValue}
						value={values.staff_file}
					/>
				</div>
				<Checkbox
					title="Staff License Class"
					options={StaffOptions}
					id="staff_class"
					values={JSON.parse(values.staff_class)}
					onChange={(id: string, values: string[]) => {
						setFieldValue(id, JSON.stringify(values))
					}}
					columns={2}
				/>
				<div className="w-1/2 pl-2">
					<Dropdown
						label="Uploaded By"
						id="confined_uploaded_by"
						value={values.staff_uploaded_by || ''}
						onChangeVal="label"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						options={renderStaffList()}
					/>
				</div>
			</div>
		</>
	)
}
