export enum estimatedWay {
	Hours = 'Hours',
	TotalED = 'Total Labour',
	SQM = 'SQM',
}

export const estimatedWayOptions = Object.values(estimatedWay)
	.filter((v) => typeof v === 'string')
	.map((v) => v) as string[]

/* export const columnsQuoteLines = [
	'ZONES',
	'ZONE LABEL',
	'TYPE',
	'DESCRIPTION',
	'QUANTITY',
	'TOTAL DAYS',
	'ERECT & DISMANTLE (P/U)',
	'WEEKLY HIRE FEE (%)',
	'WEEKLY HIRE FEE (P/U)',
	'TOTAL',
] */

export interface IColumnsQuoteLines {
	title: string
	dataIndex: string
	key: string
	hidden: boolean
	enabled: boolean
	estimatedWayDisabled: [estimatedWay]
}

export interface AdditionalCondition {
	name: string
	text: string
}

export const standardAdditionalConditions: AdditionalCondition[] = [
	{
		name: 'Working on Roofs',
		text: 'All care to be taken when building scaffold on roofs but we will take no responsibility for any accidental damage that may occur - we will report any metal roofing / and or tiles that may get damaged, crack or break so replacement can occur.',
	},
	{
		name: 'Gardens',
		text: 'If scaffold needs to be founded in any garden beds all care will be taken but no responsibility will be accepted for any accidental damage that may occur',
	},
	{
		name: 'Parking',
		text: 'Parking to be available during erection and dismantle procedures in close proximity to the work area.',
	},
	{
		name: 'Clear access',
		text: 'Clear access must be available when erecting and dismantling scaffold for scaffold to be delivered by crane truck and/or manitou.',
	},
	{
		name: 'Re Tag',
		text: 'If scaffold is on site for more than 30 days, it will be required to be inspected to comply with Australian Standards and meet the obligations of the OHS Act & Regulations. If you require Oldfields to perform this task the cost for this will be charged as a variation to this price at $90.00 for metro. Charge will increase if outside Metro or if access is difficult. Any rectification of scaffold further to this inspection will be charged as a variation.',
	},
	{
		name: 'Engineering',
		text: 'Engineered Drawings that are required are in addition to this price unless stated otherwise. These are charged as a variation. Further to the report, any modifications to the scaffold deemed necessary by the Engineer will be charged as a variation.',
	},
	{
		name: 'Inductions',
		text: 'If inductions are required, they will be charged at $75/hr per man. If this time causes the job to go over a standard 8 hour day, this cost increases to $120/hr per man',
	},
	{
		name: 'Scaffold Ties/Anchors',
		text: 'If scaffold needs to be anchored to the structure for stability all patching and painting to be completed by the hirer during dismantle process. If patching slows down our team, further dismantle costs may apply.',
	},
	{
		name: 'Fencing',
		text: 'Fencing and or Safety barricades are required, this is the responsibility of the hirer.',
	},
	{
		name: 'Lay Down Area',
		text: 'A scaffold laydown area will be required for the duration the scaffold is on site for excess materials.',
	},
	{
		name: 'Exemptions',
		text: 'No shade cloth, road edge protection, temp fencing or lighting have been allowed for in this proposal unless stated otherwise.',
	},
	{
		name: 'Building In Neighbours Yard',
		text: 'Due to the narrow access and confines of the area the outriggers or buttressing for this scaffold will need to be placed in the neighbouring property. Permission will need to be gained by the hirer prior to our arrival on site.',
	},
	{
		name: 'Driveways',
		text: 'The scaffold will severely restrict the use of the driveway/carport so all vehicles will need to be removed before work commences.',
	},
	{
		name: 'Solar Panels',
		text: 'Any solar panels then may need to be removed to allow scaffold to be built is not the responsibility of Oldfields, we are always happy to discuss options to help facilitate this. ',
	},
	{
		name: 'Pets',
		text: 'Homeowner/ occupier is responsible for any management or control of pets while Oldfields workers are on site. Oldfields has the right to cease work if they feel unsafe.',
	},
	{
		name: 'Included Weekly Hire',
		text: 'The quoted hire agreement includes X weeks of hire; any additional weeks beyond this period will be subject to charges at the weekly hire rate of Y.',
	},
]

export const columnsQuoteLines: IColumnsQuoteLines[] = [
	{
		title: 'ZONE',
		dataIndex: 'zone_id',
		key: 'zone_id',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.TotalED],
	},
	{
		title: 'RATE/TYPE',
		dataIndex: 'type',
		key: 'type',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.TotalED],
	},
	{
		title: 'DESCRIPTION',
		dataIndex: 'description',
		key: 'description',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.TotalED],
	},
	{
		title: 'LENGTH',
		dataIndex: 'length',
		key: 'length',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.SQM],
	},
	{
		title: 'HEIGHT',
		dataIndex: 'height',
		key: 'height',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.SQM],
	},
	{
		title: 'WIDTH',
		dataIndex: 'width',
		key: 'width',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.SQM],
	},
	{
		title: 'TOTAL DIMENSIONS',
		dataIndex: 'total_dimensions',
		key: 'total_dimensions',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.SQM],
	},
	{
		title: 'STAFF COUNT',
		dataIndex: 'staff_count',
		key: 'staff_count',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.Hours],
	},
	{
		title: 'ERECT HOURS',
		dataIndex: 'erect_hours',
		key: 'erect_hours',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.Hours],
	},
	{
		title: 'DISMANTLE HOURS',
		dataIndex: 'dismantle_hours',
		key: 'dismantle_hours',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.Hours],
	},
	{
		title: 'QUANTITY',
		dataIndex: 'quantity',
		key: 'quantity',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.TotalED],
	},
	// {
	// 	title: 'TOTAL DAYS',
	// 	dataIndex: 'total_days',
	// 	key: 'total_days',
	// 	hidden: false,
	// 	enabled: true,
	// 	estimatedWayDisabled: [estimatedWay.TotalED],
	// },
	{
		title: 'ERECT & DISMANTLE (P/U)',
		dataIndex: 'erect_and_dismantle',
		key: 'erect_and_dismantle',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.TotalED],
	},
	{
		title: 'WEEKLY HIRE FEE (P/U)',
		dataIndex: 'weekly_hire_fee',
		key: 'weekly_hire_fee',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.TotalED],
	},
	{
		title: 'SUNDRIES',
		dataIndex: 'sundries',
		key: 'sundries',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.TotalED],
	},
	{
		title: 'HIRE PERIOD',
		dataIndex: 'hire_period',
		key: 'hire_period',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.TotalED],
	},
	{
		title: 'WEEKLY HIRE AFTER THE PERIOD',
		dataIndex: 'weekly_hire_after_period',
		key: 'weekly_hire_after_period',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.TotalED],
	},
	{
		title: 'TOTAL',
		dataIndex: 'total',
		key: 'total',
		hidden: false,
		enabled: true,
		estimatedWayDisabled: [estimatedWay.TotalED],
	},
]

export const columnsQuoteAdditionalLines = [
	'Type',
	'Description',
	'Duration/Quantity',
	'Fixed Charge',
	'Total Cost',
]

export const columnsSQM = ['length', 'height', 'width', 'total_dimensions']
export const columnsHours = ['erect_hours', 'dismantle_hours', 'staff_count']
export const columnsIgnoreSQMOnly = ['total_days', 'quantity']
export interface IQuoteForm {
	quote_type: string
	job_type: string
	variation_job_id: string | null
	PO_Number: string | null
	max_zones: number
	client: number | null
	client_contact: number | null
	quote_num?: string
	scope_of_work: string
	notes: string
	estimator: number | null
	branch: string
	fullAddress: string

	street: string
	country: string
	street2: string
	city: string
	postal: string

	estimatedWay: estimatedWay | null

	quote_lines: IQuoteLine[]

	quote_additional_lines: IQuoteAdditionalLines[]

	terms: string

	erectDismantleTotal: number | string
	additionalTotal: number | string
	weekTotal: number | string
	total: number | string

	rates: IRates[]
	zones: IQuoteZones[]

	quote_output_type: string
	files: any
	max_files: number
}

export interface QuoteData {
	job_type: string
	id: number
	quote_num: string
	street: string
	street2: string
	city: string
	country: string
	status: string
	created_at: string
	updated_at: string
	max_zones: number
	scope_of_work: string
	notes: string
	postal: string
	weekTotal: number
	total: number
	terms: string
	client_contact: null | number
	estimator: number
	approved_by: null | number
	variation_job_id: null | string
	quote_type: string
	clientType: null | string
	PO_Number: null | string
	estimatedWay: string
	additionalTotal: number
	erectDismantleTotal: number
	emailStatus: string
	longitude: number
	latitude: number
	fullAddress: string
	client: number
	branch: string
	createdAt: string
	updatedAt: string
	clientData: {
		id: number
		client_name: string
		phone: string
		email: string
		customer_code: string
		finance_type: string
	}
	contactData: {
		id: number
		name: string
		phone: string
		email: string
	}
	estimatorData: {
		id: number
		staff_name: string
		email: string
		mobile: string
	}
	files: any
	max_files: number
	quote_output_type: string
	quote_token: string
}

export interface QuoteDataToUpdate {
	job_type: string
	street: string
	street2: string
	city: string
	max_zones: number
	scope_of_work: string
	notes: string
	postal: string
	country: string
	weekTotal: number
	total: number
	terms: string
	client_contact: null | number
	estimator: number
	variation_job_id: null | number
	quote_type: string
	PO_Number: null | string
	estimatedWay: string
	additionalTotal: number
	erectDismantleTotal: number
	fullAddress: string
	client: number
	max_files: number
	quote_output_type: string
	emailStatus: string
	branch: string
}

export interface IquoteLinesHandlers {
	addNewQuoteLine: () => void
	removeQuoteLine: (index: number) => void
	updateQuoteLine: (
		index: number,
		field: string,
		value: string | number
	) => void
	validateQuoteLinesColumns: (
		columnsQuoteLines: IColumnsQuoteLines[],
		estimatedWay: estimatedWay
	) => IColumnsQuoteLines[]
}

export interface IquoteAdditionalLinesHandlers {
	addNewQuoteAdditionalLine: () => void
	removeQuoteAdditionalLine: (index: number) => void
	updateQuoteAdditionalLine: (
		index: number,
		field: string,
		value: string | number
	) => void
}

export interface IQuoteAdditionalLines {
	id: string | number | null
	type: string
	description: string
	duration_quantity: number
	fixed_charge: number
	total_cost: number
}

export interface IQuoteLine {
	id: number | null
	zone_id: number | null
	zone_label: string
	type: string
	description: string
	staff_count: number
	erect_hours: number
	dismantle_hours: number
	quantity: number
	total_days: number
	erect_and_dismantle: number
	percentage_weekly_hire_fee: number
	weekly_hire_fee: number
	hire_period: number
	weekly_hire_after_period: number
	total: number
	length: number
	height: number
	width: number
	total_dimensions: number
	sundries: number
}

export interface IRates {
	id: number | null
	type: string
	service: string
	fee: number
	branch: string
}

export interface IRatesHandlers {
	addNewRate: () => void
	removeRate: (index: number) => void
	updateRate: (index: number, field: string, value: string | number) => void
}

export interface IQuoteZones {
	id: string | number | null
	zone_id: string | number
	zone_label: string
	quote_id?: string
}

export interface IQuoteFiles {
	id?: number
	index?: number
	url: string
	file_name: string
	quote_id?: number
	description: string
	show_in_quote: boolean
}

export interface IZonesHandlers {
	addNewZone: (zone_id: number) => void
	removeZone: (index: number) => void
	updateZone: (index: number, field: string, value: string | number) => void
}
