import { PDFViewer } from '@react-pdf/renderer'
import { InvoiceDocumentPDF } from './EDInvoiceOutput'
import { InvoiceDocumentsServices, InvoiceServices } from 'services'
import { FullPageSpinner, Spinner } from 'common'

interface PDFViewerProps {
	invoice_id: number
}

export const InvoiceDocumentPDFViewer = ({ invoice_id }: PDFViewerProps) => {
	const { data: invoiceDocumentData, isLoading: invoiceDocumentLoading } =
		InvoiceDocumentsServices.useInvoiceDocumentByID(invoice_id)

	const {
		data: job_weekly_hire_invoices,
		isLoading: job_weekly_hire_invoices_loading,
	} = InvoiceServices.useWeeklyHireInvoicesByJobId(
		invoiceDocumentData?.job_id || -1
	)

	const { data: job_ed_invoices, isLoading: job_ed_invoices_loading } =
		InvoiceServices.useEdInvoicesByJobId(invoiceDocumentData?.job_id || -1)

	const documentWeeklyHireInvoices = job_weekly_hire_invoices?.filter(
		(invoice: { id: any }) =>
			invoiceDocumentData.weekly_hire_items.includes(invoice.id)
	)

	const documentEdInvoices = job_ed_invoices?.filter((invoice: { id: any }) =>
		invoiceDocumentData.ed_items.includes(invoice.id)
	)

	const fullInvoiceData = {
		...invoiceDocumentData,
		weekly_hire_invoices: documentWeeklyHireInvoices,
		ed_invoices: documentEdInvoices,
	}

	if (
		invoiceDocumentLoading ||
		job_ed_invoices_loading ||
		job_weekly_hire_invoices_loading
	) {
		return <FullPageSpinner />
	}

	console.log(fullInvoiceData)

	return (
		<PDFViewer key="pdfviewer" width="100%" height="1000">
			<InvoiceDocumentPDF key="document" invoice_data={fullInvoiceData} />
		</PDFViewer>
	)
}
