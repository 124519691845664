import { useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { SWMSTasksServices, DataTableServices } from 'services'
import { Badge, Container, DataTableHeader, PageHeading } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { Link, useLocation } from 'react-router-dom'
import { PencilIcon } from '@heroicons/react/24/solid'
import { AppRoutes } from 'config'
import { SWMSTaskForm } from 'components/SWMS/TasksForm'

export const SWMSTasks = () => {
	const location = useLocation()
	const { data, isLoading } = SWMSTasksServices.useSWMSTasks()
	const [openSWMSTaskForm, setOpenSWMSTaskForm] = useState(false)
	console.log(data)
	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [],
		additionalGlobalFilterFields: ['id'],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	// SWMS TASK:
	/*
		id: number
	description: string
	hazards: string[]
	risk_score: number
	safety_controls: string[]
	responsible_parties: string[]
	residual_risk_score: number
	*/

	console.log(filters)

	return (
		<div className="card">
			<PageHeading
				title="SWMS Tasks"
				createBtn="Create SWMS Task"
				isEditable={false}
				setOpen={setOpenSWMSTaskForm}
			/>
			<Container>
				<DataTable
					scrollable
					scrollHeight="70vh"
					ref={dataTableReference}
					value={data}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading}
					dataKey="id"
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					emptyMessage="No SWMS Tasks found."
					tableStyle={{ alignItems: 'center' }}>
					<Column
						field="id"
						header="Task ID"
						body={(rowData) => (
							<Link
								to={`${AppRoutes.privateRoutes.SWMSTasksEdit.replace(
									':id',
									rowData.id.toString()
								)}`}>
								{rowData.id}
							</Link>
						)}
					/>
					<Column field="description" header="Description" />
					<Column
						field="hazards"
						header="Hazards"
						// body={(rowData) => (
						// 	<>
						// 		{rowData.hazards.map((item: any, index: any) => (
						// 			<Badge key={index} text={item} type={'Active'} />
						// 		))}
						// 	</>
						// )}
					/>
					<Column field="risk_score" header="Risk Score" />
					<Column
						field="safety_controls"
						header="Safety Controls"
						// body={(rowData) => (
						// 	<>
						// 		{rowData.safety_controls.map((item: any, index: any) => (
						// 			<Badge key={index} text={item} type={'Active'} />
						// 		))}
						// 	</>
						// )}
					/>
					<Column
						field="responsible_parties"
						header="Responsible Parties"
						body={(rowData) => (
							<>
								{rowData.responsible_parties.map((item: any, index: any) => (
									<Badge key={index} text={item} type={'Active'} />
								))}
							</>
						)}
					/>
					<Column field="residual_risk_score" header="Residual Risk Score" />
					<Column field="swms_step" header="SWMS Type" />
					<Column
						field="id"
						header="Edit"
						body={(rowData) => (
							<Link
								to={`${AppRoutes.privateRoutes.SWMSTasksEdit.replace(
									':id',
									rowData.id.toString()
								)}`}
								state={{ background: location, name: 'editSWMSTasks' }}>
								<PencilIcon className="w-5 h-5" />
							</Link>
						)}
					/>
				</DataTable>
			</Container>
			<SWMSTaskForm
				formType="create"
				heading="Create SWMS Task"
				open={openSWMSTaskForm}
				setOpen={setOpenSWMSTaskForm}
			/>
		</div>
	)
}
