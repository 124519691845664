import { useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { DataTableServices, JobsServices } from 'services'
import { Container, DataTableHeader, PageHeading, Badge, Spinner } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { Link, useLocation } from 'react-router-dom'
import { PencilIcon, FolderIcon } from '@heroicons/react/24/solid'
import { AppRoutes } from 'config'
import { JobsComponents } from 'components'
import moment from 'moment'
import { cn } from '@fullcalendar/core/internal-common'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

export const JobsMainTable = () => {
	const location = useLocation()
	const { data, isLoading, enableCreateUpdate } = JobsServices.useJobs()
	const [openJobForm, setOpenJobForm] = useState(false)

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		FilterColumn,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'job_status',
				filterInitialValue: 'Signed Off',
				filterInitialMatchMode: FilterMatchMode.NOT_EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'Pending Handover',
					'In Progress',
					'Completed',
					'Signed Off',
				],
			},
			{
				filterName: 'status',
				filterInitialValue: 'Active',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Active', 'Inactive'],
			},
			{
				filterName: 'job_type',
				filterInitialValue: [
					'Residential Construction',
					'Civil Construction',
					'Commercial Building',
					'Tier 1',
					'Facilities Management',
					'Maintenance',
					'Mining',
					'Mobile Scaffold',
				],
				filterInitialMatchMode: FilterMatchMode.IN,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'Residential Construction',
					'Civil Construction',
					'Commercial Building',
					'Tier 1',
					'Facilities Management',
					'Maintenance',
					'Mining',
					'Void Protection',
					'Mobile Scaffold',
				],
			},
			{
				filterName: 'finance_status',
				filterInitialValue: [],
				filterInitialMatchMode: FilterMatchMode.IN,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'ACC',
					'Paid',
					'Unpaid',
				]
			},
		],
		additionalGlobalFilterFields: [
			'job_num',
			'site',
			'clientData.client_name',
			'job_type',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
		customFilters: [
			{
				field: 'job_type',
				label: 'Standard Jobs',
				value: [
					'Residential Construction',
					'Civil Construction',
					'Commercial Building',
					'Tier 1',
					'Facilities Management',
					'Maintenance',
					'Mining',
					'Mobile Scaffold',
				],
				color: 'success',
			},
			{
				field: 'job_type',
				label: 'Void Protection',
				value: 'Void Protection',
				color: 'warning',
			},
		],
	})

	const currentDate = moment()

	if (isLoading) {
		return <Spinner />
	}

	const generatedData = data.map((job: any) => {
		let finance_status
		if (job.clientData.finance_type == 'Credit') {
			if (
				job.clientData.finance_status === 'Y' ||
				job.clientData.finance_status === 'N'
			) {
				finance_status = 'ACC'
			}
		} else if (job.invoice_paid === 'Y') {
			finance_status = 'Paid'
		} else if (job.invoice_paid === 'N') {
			finance_status = 'Unpaid'
		} else {
			finance_status = job.clientData.finance_status
		}

		return {
			...job,
			finance_status,
		}
	})

	return (
		<div className="card">
			<PageHeading
				title="Jobs"
				createBtn="Create Job"
				isEditable={false}
				setOpen={setOpenJobForm}
			/>
			<Container>
				<DataTable
					scrollable
					scrollHeight="70vh"
					ref={dataTableReference}
					value={generatedData}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading}
					dataKey="id"
					sortField="id"
					sortOrder={-1}
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					emptyMessage="No jobs found.">
					<Column
						field="job_num"
						header="Job #"
						style={{ width: '7rem' }}
						body={(rowData) => (
							<Link
								to={AppRoutes.privateRoutes.JobsDetail.replace(
									':id',
									rowData.id
								)}
								className="flex items-center">
								<FolderIcon className="h-4 w-4 mx-2" />
								<># {rowData.job_num}</>
							</Link>
						)}
					/>
					<Column field="clientData.client_name" header="Client" />
					<Column field="site" header="Site Address" />
					<Column
						field="start_date"
						header="Start Date"
						body={(rowData) => (
							<>{moment(new Date(rowData.start_date)).format('DD/MM/YYYY')}</>
						)}
					/>
					<Column
						field="end_date"
						header="Finish Date"
						sortable
						className="text-right" // Right-align text
						body={(rowData) => {
							const endDate = moment(new Date(rowData.end_date), 'DD/MM/YYYY')
							const isBeforeToday = endDate.isBefore(currentDate, 'day')
							const isSameDay = endDate.isSame(currentDate, 'day')

							let hoverMessage = ''

							if (isBeforeToday) {
								hoverMessage = 'Job should have ended'
							} else if (isSameDay) {
								hoverMessage = 'Job should end today'
							}

							return (
								<div className="flex items-center space-x-2">
									<span title={hoverMessage}>
										{endDate.format('DD/MM/YYYY')}
									</span>
									{isBeforeToday && (
										<ExclamationCircleIcon
											className="h-6 w-6 text-fluorescent-red"
											title={hoverMessage}
										/>
									)}
									{isSameDay && (
										<ExclamationCircleIcon
											className="h-6 w-6 text-fluorescent-orange"
											title={hoverMessage}
										/>
									)}
								</div>
							)
						}}
					/>
					<Column
						field="job_status"
						header="Job Status"
						{...FilterColumn.job_status}
					/>
					<Column field="on_hire" header="On Hire" {...FilterColumn.on_hire} />
					<Column field="notes" header="Notes" />
					<Column field="job_type" header="Job Type" />
					<Column
						field="status"
						header="Status"
						bodyStyle={{ textAlign: 'center' }}
						body={(rowData) => (
							<Badge text={rowData.status} type={rowData.status} />
						)}
						{...FilterColumn.status}
					/>
					<Column
						field="finance_status"
						header="Finance Status"
						bodyStyle={{ textAlign: 'center' }}
						body={(rowData) => {
							if (rowData.clientData.finance_type == 'Credit') {
								if (rowData.clientData.finance_status === 'Y') {
									return <Badge text="ACC" type="Green" />
								}
								if (rowData.clientData.finance_status === 'N') {
									return <Badge text="ACC" type="Yellow" />
								}
							}
							if (rowData.invoice_paid === 'Y') {
								return <Badge text="Paid" type="Green" />
							}
							if (rowData.invoice_paid === 'N') {
								return <Badge text="Unpaid" type="Red" />
							}
							return (
								<Badge text={rowData.clientData.finance_status} type="Yellow" />
							)
						}}
						{...FilterColumn.finance_status}
					/>
					{enableCreateUpdate && (
						<Column
							header="Edit"
							body={(rowData) => (
								<Link
									to={{
										pathname: AppRoutes.privateRoutes.JobsEdit.replace(
											':id',
											rowData.id
										),
									}}
									state={{ background: location, name: 'editJob' }}>
									<PencilIcon className="text-gray-600 h-4 w-4" />
								</Link>
							)}
						/>
					)}
				</DataTable>
			</Container>
			<JobsComponents.JobForm
				formType="create"
				heading="Create Job"
				open={openJobForm}
				setOpen={setOpenJobForm}
			/>
		</div>
	)
}
