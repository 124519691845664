import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { DateSelect, Button, ConfirmationDialog, Spinner, Checkbox } from 'common'
import { InvoiceDocumentsServices, InvoiceServices, JobsServices } from 'services'
import { NotificationsContext } from 'context/notifications/toastContext'

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	invoicesSelected: any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setInvoicesSelected: any
	allInvoices: any
	endOfMonth?: boolean
}

export const ApproveInvoices = ({
	invoicesSelected,
	setInvoicesSelected,
	endOfMonth,
	allInvoices,
}: IProps) => {
	const [isLoading, setIsLoading] = useState(false)
	const [completed, setCompleted] = useState(false)
	const [markedAsSignedOff, setMarkedAsSignedOff] = useState(false)
	// Start at last date of current month
	const [closeOutDate, setCloseOutDate] = useState(
		moment().endOf('month').format('DD/MM/YYYY')
	)
	const [invoiceAgainstDate, setInvoiceAgainstDate] = useState(
		moment().format('DD/MM/YYYY')
	)

	const { approveInvoices } = InvoiceServices.useApproveInvoices()
	const { createInvoiceDocument } =
		InvoiceDocumentsServices.useCreateInvoiceDocument()
	const { uploadInvoiceDocument } =
		InvoiceDocumentsServices.useUploadInvoiceDocument()
	const { updateInvoiceDocument } =
		InvoiceDocumentsServices.useUpdateInvoiceDocument()
	const { sendInvoiceDocumentEmail } =
		InvoiceDocumentsServices.useSendInvoiceDocumentEmail()
	const { update } = JobsServices.useUpdateJob()
	const { showSuccess, showError } = useContext(NotificationsContext)

	// Check all invoices with invoiceType 'Weekly Hire' and see if their date_on_hire is after selected closeOutDate
	const checkInvoices = (shoWError = false) => {
		if (
			allInvoices === null ||
			allInvoices === undefined ||
			invoicesSelected === null ||
			invoicesSelected === undefined ||
			invoicesSelected.length === 0
		)
			return false
		const invoices = invoicesSelected.filter((invoice: any) => {
			if (invoice.invoiceType !== 'Weekly Hire') return false
			const invoiceDate = moment(invoice.date_on_hire, 'DD/MM/YYYY')
			const closeOutDateMoment = moment(closeOutDate, 'DD/MM/YYYY')
			return invoiceDate.isAfter(closeOutDateMoment)
		})
		if (invoices.length > 0) {
			if (shoWError) {
				showError(
					'Some weekly hire invoices have a start date that is after your selected close out date. Please close and check your selected invoices..'
				)
			}
			return false
		}
		return true
	}

	const weeklyHireInvoicesValid = checkInvoices()

	const approveInvoicesHandler = async () => {
		if (invoicesSelected && checkInvoices(true)) {
			setIsLoading(true)

			const invoicesGroupedByJobID = invoicesSelected.reduce(
				(acc: any, invoice: any) => {
					const key = invoice.job_id
					if (!acc[key]) {
						acc[key] = []
					}
					acc[key].push(invoice)
					return acc
				},
				{}
			)
			const invoicePromises: Promise<void>[] = []
			for (const jobID in invoicesGroupedByJobID) {
				invoicePromises.push(
					new Promise(async () => {
						console.log('====================')
						console.log('JobID: ', jobID)
						const invoices = invoicesGroupedByJobID[jobID]
						console.log('Invoices: ', invoices)
						const edInvoices = invoices.filter(
							(invoice: any) => invoice.invoiceType === 'Fixed Price Invoice'
						)
						const weekly_hire_invoices = invoices.filter(
							(invoice: any) => invoice.invoiceType === 'Weekly Hire'
						)

						const weeklyHireTotals = weekly_hire_invoices.reduce(
							(acc: number, item: any) => {
								const startMoment = moment(item.completed_date, 'DD/MM/YYYY')
								const endMoment = moment(closeOutDate, 'DD/MM/YYYY')
								const days = startMoment.diff(endMoment, 'days') + 1
								return acc + (item.weekly_hire_rate / 7) * days
							},
							0
						)

						const edInvoiceTotals = edInvoices.reduce(
							(acc: number, item: any) => acc + item.invoice_amount,
							0
						)

						console.log(weeklyHireTotals)
						console.log(edInvoiceTotals)

						const invoiceSubTotal = weeklyHireTotals + edInvoiceTotals

						const invoiceTotal = invoiceSubTotal + invoiceSubTotal * 0.1
						console.log(invoiceTotal)

						const weekly_hire_invoice_ids = weekly_hire_invoices.map(
							(invoice: any) => invoice.id
						)
						const ed_invoice_ids = edInvoices.map((invoice: any) => invoice.id)
						const newInvoiceDocument = {
							job_id: jobID,
							client_id: invoices[0]?.jobData?.client_id,
							weekly_hire_items: weekly_hire_invoice_ids,
							ed_items: ed_invoice_ids,
							document_url: '',
							total_invoiced: invoiceTotal,
							invoice_date: moment(invoiceAgainstDate, 'DD/MM/YYYY').format(
								'YYYY-MM-DD'
							),
						}
						await approveInvoices(invoices, closeOutDate, endOfMonth)
						console.log('Invoice Document: ', newInvoiceDocument)
						const invoiceDocument = await createInvoiceDocument(
							newInvoiceDocument
						)
						if (markedAsSignedOff) {
							await update(Number(jobID), { job_status: 'Signed Off' })
						}
						console.log('Invoice Document Response: ', invoiceDocument)
						await new Promise((resolve) => setTimeout(resolve, 500))
						const url = await uploadInvoiceDocument(
							invoiceDocument.data.id,
							Number(jobID)
						)
						console.log('URL: ', url)
						const updatedInvoiceDocument = await updateInvoiceDocument(
							invoiceDocument.data.id,
							{
								document_url: url,
							}
						)
						const documentSent = await sendInvoiceDocumentEmail(
							invoiceDocument.data.id
						)
					})
				)
			}

			Promise.all(invoicePromises)
				.then(() => {
					showSuccess('All Invoices submitted successfully!')
				})
				.catch((error) => {
					showError('Something went wrong submitting the Invoices')
				})

			setInvoicesSelected(null)
			setIsLoading(false)
			setCompleted(true)
			setMarkedAsSignedOff(false)
		}
	}

	return (
		<ConfirmationDialog
			isDone={completed}
			icon="info"
			title="Submit Invoices"
			body="This will create invoice documents per job for the selected invoices. Select the invoice dates below."
			triggerButton={
				<Button
					size="sm"
					variant="primary"
					onClick={() => {
						null
					}}>
					{endOfMonth ? 'Submit Initial/Ongoing Invoice' : 'Submit Final Invoice'}
				</Button>
			}
			confirmButton={
				<Button
					size="sm"
					isLoading={isLoading}
					variant="approve"
					disabled={!weeklyHireInvoicesValid}
					onClick={async () => {
						setIsLoading(true)
						await approveInvoicesHandler()
						setIsLoading(false)
						setCompleted(true)
					}}>
					{isLoading ? (
						<div className="flex">
							Uploading...
							<div className="ml-2 w-5 h-5">
								<Spinner />
							</div>
						</div>
					) : (
						'Submit Invoices'
					)}
				</Button>
			}>
			<div className="flex flex-col">
				<div className="flex flex-row justify-evenly">
					<div className="flex flex-col">
						<DateSelect
							title="Hire End Date"
							id="date"
							value={closeOutDate}
							onChange={(e, val) => {
								setCloseOutDate(val)
							}}
						/>
						<DateSelect
							title="Invoice Date"
							id="date"
							value={invoiceAgainstDate}
							onChange={(e, val) => {
								setInvoiceAgainstDate(val)
							}}
						/>
					</div>
					<div className="flex items-center">
						<input
							id="marked_as_signed_off"
							aria-describedby="marked_as_signed_off"
							name="marked_as_signed_off"
							type="checkbox"
							checked={markedAsSignedOff}
							onChange={(e) => {
								setMarkedAsSignedOff(e.target.checked)
							}}
							className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
						/>
						<label htmlFor="marked_as_signed_off" className="ml-2 text-sm text-gray-700">
							Mark as Signed Off
						</label>
					</div>
				</div>
				{weeklyHireInvoicesValid === false && (
						<div>
							<p className="text-red-500">
								Some weekly hire invoices have a start date that is after your
								selected close out date.
								<br />
								Please close and check your selected invoices.
							</p>
						</div>
					)}
					<br />
			</div>
		</ConfirmationDialog>
	)
}
