import { ConfirmationDialog, Spinner } from 'common'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { QuoteServices } from 'services'

export const QuoteAcceptancePage = () => {
	const { token } = useParams()
	const [isAccepted, setAccepted] = useState(false)
	const { data: quote, isLoading: quote_loading } =
		QuoteServices.useQuoteByToken(token || '')
	const { acceptQuote } = QuoteServices.useUpdateQuoteEmailStatus()
	const [comment, setComment] = useState('')

	if (quote_loading) {
		return <Spinner />
	}
	if (!quote)
		return (
			<div className="quote-acceptance flex flex-col min-h-screen justify-between items-center p-4 sm:w-full py-48">
				<div className="quote-details">
					<h1 className="text-2xl font-bold">Invalid Quote Link</h1>
					<p>The link you have clicked is invalid</p>
				</div>
			</div>
		)
	// console.log(quote)
	const handleAcceptance = async () => {
		if (!token) return
		if (!isAccepted) return
		// Update quote
		await acceptQuote(token, comment)
	}

	return (
		<div className="quote-acceptance flex flex-col min-h-screen justify-between items-center p-4 sm:w-full py-48">
			<div className="quote-details">
				<h1 className="text-2xl font-bold">Quote Acceptance</h1>
				<p>
					You are about to accept quote{' '}
					<span className="font-bold italic">#{quote.quote_num}</span>
				</p>
			</div>

			{quote.emailStatus != 'Customer Accepted' && (
				<div className="accept-section mt-6 mb-5">
					<div className="w-full">
						<textarea
							placeholder="Enter your comment"
							rows={4}
							value={comment}
							onChange={(e) => setComment(e.target.value)}
							className="w-full border border-gray-300 rounded px-2 py-1 focus:outline-2 focus:outline-black"
						/>
					</div>
					<div className="flex items-center">
						<input
							type="checkbox"
							checked={isAccepted}
							onChange={() => setAccepted(!isAccepted)}
							className="mr-2  focus:outline-2 focus:outline-black"
						/>
						<label>
							I{' '}
							<span className="font-bold italic">{quote.contactData.name}</span>{' '}
							hereby agree to the terms of above quote as described on{' '}
							<span className="font-bold italic">
								{new Date().toLocaleString('en-NZ', {
									timeZone: 'Pacific/Auckland',
									dateStyle: 'long',
									timeStyle: 'short',
								})}
							</span>
						</label>
					</div>
					<div className="flex space-x-2">
						<a
							href="https://oldfields.com.au/wp-content/uploads/2017/07/Oldfields-Scaffolding-Sales-Terms-and-Conditions-20th-of-July-2022.pdf"
							target="_blank"
							rel="noopener noreferrer"
							className="text-blue-500">
							View Terms and Conditions (PDF)
						</a>
					</div>
					<div className="flex space-x-2">
						<ConfirmationDialog
							icon="info"
							title="Confirmation - Accept"
							body={`Accepting quote "${quote.quote_num}"`}
							triggerButton={
								<button
									disabled={!isAccepted}
									className="w-full py-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:bg-gray-300 disabled:transition-all disabled:text-black disabled:italic focus:outline-2 focus:outline-black">
									Accept Quote
								</button>
							}
							confirmButton={
								<Button onClick={async () => handleAcceptance()}>
									Confirm
								</Button>
							}
						/>
					</div>
				</div>
			)}
			{quote.emailStatus == 'Customer Accepted' && (
				<div className="accept-section mt-6 mb-5">
					<div className="flex items-center">
						<p>
							Quote has already been accepted by{' '}
							<span className="font-bold italic">{quote.contactData.name}</span>{' '}
						</p>
					</div>
				</div>
			)}
		</div>
	)
}

export default QuoteAcceptancePage
