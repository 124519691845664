import React from 'react'
import { DateSelect, CreateFile, Dropdown } from 'common'
import { IStaffRow } from 'models'

type PoliceCheckProps = {
	values: {
		police_check_file: string
		police_check_date: string
		police_check_expiry_date: string
		police_check_uploaded_by: number | null
	}

	staff: IStaffRow[] | undefined
	setFieldValue: (
		field: string,
		value: string | number | boolean | Date | null
	) => void
	setFieldTouched: (field: string, touched?: boolean) => void
}

export const PoliceCheck: React.FC<PoliceCheckProps> = ({
	values,
	staff,
	setFieldValue,
	setFieldTouched,
}) => {
	const renderStaffList = (): { label: string; value: string }[] => {
		if (staff && staff?.length > 0) {
			return staff.map((item) => ({
				label: item.staff_name,
				value: item.id || '',
			}))
		}
		return []
	}
	return (
		<>
			<h3 className="px-4 py-2 text-lg font-semibold leading-5 ">
				Police Check
			</h3>
			<div>
				<div className="flex items-center px-2">
					<DateSelect
						title="Date"
						id="police_check_date"
						value={values.police_check_date}
						onChange={setFieldValue}
					/>
					<DateSelect
						title="Expiry Date"
						id="police_check_expiry_date"
						value={values.police_check_expiry_date}
						onChange={setFieldValue}
					/>
				</div>
				<div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-2">File</h4>
					<CreateFile
						field="police_check_file"
						setFieldValue={setFieldValue}
						value={values.police_check_file}
					/>
				</div>
				<div className="w-1/2 pl-2">
					<Dropdown
						label="Uploaded By"
						id="police_check_uploaded_by"
						value={values.police_check_uploaded_by}
						onChangeVal="label"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						options={renderStaffList()}
					/>
				</div>
			</div>
		</>
	)
}
