import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'

export const useSWMSDocuments = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchAllSWMSDocuments = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverSWMSDocumentsRoutes.getAll
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting SWMSDocuments')
			throw new Error('Something went wrong getting SWMSDocuments')
		}
	}

	const { data, isLoading, error } = useQuery(
		['swms_documents'],
		fetchAllSWMSDocuments
	)

	return { data, isLoading, error }
}
