import { Font, StyleSheet } from '@react-pdf/renderer'
import RosarioLight from '../../../assets/Fonts/Rosario/Rosario-Light.ttf'
import RosarioLightItalic from '../../../assets/Fonts/Rosario/Rosario-LightItalic.ttf'
import RosarioRegular from '../../../assets/Fonts/Rosario/Rosario-Regular.ttf'
import RosarioItalic from '../../../assets/Fonts/Rosario/Rosario-Italic.ttf'
import RosarioSemiBold from '../../../assets/Fonts/Rosario/Rosario-SemiBold.ttf'
import RosarioSemiBoldItalic from '../../../assets/Fonts/Rosario/Rosario-SemiBoldItalic.ttf'
import RosarioBold from '../../../assets/Fonts/Rosario/Rosario-Bold.ttf'
import RosarioBoldItalic from '../../../assets/Fonts/Rosario/Rosario-BoldItalic.ttf'

Font.register({
	family: 'Rosario',
	fonts: [
		{ src: RosarioLight, fontWeight: 300, fontStyle: 'normal' },
		{ src: RosarioLightItalic, fontWeight: 300, fontStyle: 'italic' },
		{ src: RosarioRegular, fontWeight: 400, fontStyle: 'normal' },
		{ src: RosarioItalic, fontWeight: 400, fontStyle: 'italic' },
		{ src: RosarioSemiBold, fontWeight: 600, fontStyle: 'normal' },
		{ src: RosarioSemiBoldItalic, fontWeight: 600, fontStyle: 'italic' },
		{ src: RosarioBold, fontWeight: 700, fontStyle: 'normal' },
		{ src: RosarioBoldItalic, fontWeight: 700, fontStyle: 'italic' },
	],
})

export const styles = StyleSheet.create({
	page: {
		fontFamily: 'Helvetica',
		fontSize: 11,
		// paddingTop: 10,
		paddingBottom: 40,
		paddingLeft: 20,
		paddingRight: 20,
		lineHeight: 1.5,
		flexDirection: 'column',
	},
	heading: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		width: 150,
		alignItems: 'flex-start',
		fontFamily: 'Open Sans',
		fontWeight: 'semibold',
		fontSize: 9,
		color: '#1A8140',
		marginTop: 8,
	},
	headingContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	headingContact: {
		textAlign: 'right',
		marginTop: 20,
	},
	headingDisplay: {
		display: 'flex',
		flexDirection: 'row',
	},
	headingText: {
		fontFamily: 'Open Sans',
		fontWeight: 'semibold',
		fontSize: 7,
	},
	quoteText: {
		fontFamily: 'Open Sans',
		fontWeight: 'bold',
		fontSize: 18,
	},

	subText: {
		fontFamily: 'Open Sans',
		fontSize: 7,
		textAlign: 'justify',
		// fontWeight: "semibold",
	},
	subTextMargin: {
		fontFamily: 'Open Sans',
		fontSize: 7,
		marginTop: 15,
		// fontWeight: "semibold",
	},
	subTextBold: {
		fontFamily: 'Open Sans',
		marginLeft: 4,
		marginTop: 15,
		fontSize: 7,
		fontWeight: 'bold',
	},
	subTextBoldRed: {
		fontFamily: 'Open Sans',
		color: 'red',
		marginLeft: 4,
		marginTop: 15,
		fontSize: 7,
		fontWeight: 'bold',
		textAlign: 'right',
	},
	logo: {
		objectFit: 'contain',
		width: '20%',
		height: 'auto',
		paddingTop: 20,
	},
	footer: {
		flexDirection: 'row',
		position: 'absolute',
		bottom: 7,
		right: 0,
	},
	pageTermsOfTrade: {
		objectFit: 'contain',
		width: '105%',
		height: 'auto',
		marginLeft: '-20',
	},
	footerLogo: {
		objectFit: 'contain',
		width: '110%',
		// height: "auto",
		marginRight: 0,
	},

	footerText: {
		fontSize: 6,
		paddingLeft: 10,
	},

	pageNumber: {
		// position: "absolute",
		marginTop: 10,
		marginRight: 17,
		// paddingLeft: 70,
		fontSize: 8,
		// bottom: 30,
		// left: 0,
		// right: 0,
		textAlign: 'center',
		color: 'grey',
	},
	imageRow: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: 10,
		marginTop: 50,
	},
	image: {
		flexBasis: '48%', // Adjusted width to 48% to account for gap
		flexGrow: 1,
		height: 300,
	},
	imageText: {
		fontFamily: 'Open Sans',
		fontSize: 10,
		maxWidth: '100%',
	},

	photosPage: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		marginTop: 10,
	},
	imageContainer: {
		width: '48%', // 2% less to account for margins
		marginBottom: 10,
	},
	photoContainer: {
		width: '48%', // 2% less to account for margins
		marginBottom: 20,
		textAlign: 'center',
		position: 'relative',
		backgroundColor: '#f0f0f0', // Add a background color for better visibility
	},
	photosImage: {
		width: '100%',
		height: 'auto',
	},
	imageDescription: {
		marginTop: 5,
		fontSize: 10,
	},
})
