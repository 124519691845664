import {
	Address,
	DateSelect,
	Dropdown,
	Input,
	SideModal,
	Spinner,
} from 'common'
import { useFormik } from 'formik'
import { IStaffRow, StaffStatus, StaffType } from 'models/staff.model'
import { StaffServices } from 'services'
import * as Yup from 'yup'
import {
	BuildingPassport,
	ConfinedSpace,
	ConstructionWhiteCard,
	DriverLicense,
	FirstAid,
	HealthSafety,
	HighRiskWorks,
	SafeWorkingAtHeights,
	IRATA,
	Staff,
	MasterClimber,
	PoliceCheck,
	DCSIChildren,
	DCSIAgedCare,
} from './FormComponents'
import { useDispatch, useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { updateUser } from 'redux/states/user'
import { branchOptions } from 'models/branch'

interface IProps {
	staff_id?: string | number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

const statusOptions = [
	{ label: 'Active', value: 'Active' },
	{ label: 'Inactive', value: 'Inactive' },
]

const yesNoOptions = [
	{ label: 'Yes', value: 'Yes' },
	{ label: 'No', value: 'No' },
]

const trueFalseOptions = [
	{ label: 'Yes', value: 'true' },
	{ label: 'No', value: 'false' },
]

const userTypeOptions = [
	{ label: 'Standard', value: 'Standard' },
	{ label: 'Admin', value: 'Admin' },
]

const typeOptions = [
	{ value: 'Employee', label: 'Employee' },
	{ value: 'Crew Leader', label: 'Crew Leader' },
	{ value: 'Office', label: 'Office' },
	{ value: 'Scaffolder', label: 'Scaffolder' },
	{ value: 'Truck Driver', label: 'Truck Driver' },
	{ value: 'Application', label: 'Application' },
	{ value: 'Contractor', label: 'Contractor' },
	{ value: 'Yard Person', label: 'Yard Person' },
]

export const StaffForm = ({
	staff_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { data, isLoading } = StaffServices.useStaffById(staff_id)
	const staffListData = StaffServices.useStaff()
	const { createStaff } = StaffServices.useCreateStaff()
	const { updateStaff } = StaffServices.useUpdateStaff()
	const loggedInUser = useSelector((store: AppStore) => store.user)
	const dispatch = useDispatch()

	const initialValues: IStaffRow = {
		// user type
		userType: data?.userType || 'Standard',

		// access to portal
		accessToPortal: data?.accessToPortal || 'No',

		// access to mobile
		accessToMobile: data?.accessToMobile || 'No',

		// Main Staff Details
		staff_name: data?.staff_name || '',
		type: data?.type || StaffType['N/A'],
		mobile: data?.mobile || '',
		email: data?.email || '',
		position: data?.position || '',
		street: data?.street || '',
		street_2: data?.street_2 || '',
		city: data?.city || '',
		post_code: data?.post_code || '',
		pin: data?.pin || '',
		start_date: data?.start_date || '',
		dob: data?.dob || '',

		// Drivers License Section
		driver_license: data?.driver_license || '',
		license_type: data?.license_type || '',
		license_class2: data?.license_class2 || '[]',
		endorsement: data?.endorsement || '',
		endorsement_complete_date: data?.endorsement_complete_date || '',
		endorsement_expiry: data?.endorsement_expiry || '',
		photo_front: data?.photo_front || '',
		photo_back: data?.photo_back || '',
		license_assessed_by: data?.license_assessed_by || '',

		// Police Check Section
		police_check_file: data?.police_check_file || '',
		police_check_date: data?.police_check_date || '',
		police_check_expiry_date: data?.police_check_expiry_date || '',
		police_check_uploaded_by: data?.police_check_uploaded_by || null,

		// Health & Safety Section
		induction_date: data?.induction_date || '',
		expiry_date: data?.expiry_date || '',
		photo: data?.photo || '',
		hs_assessed_by: data?.hs_assessed_by || '',

		// Building construction section
		passport_num: data?.passport_num || '',
		passport_type: data?.passport_type || '',
		passport_issue: data?.passport_issue || '',
		passport_expiry: data?.passport_expiry || '',
		passport_photo: data?.passport_photo || '',
		site_safe_assessed_by: data?.site_safe_assessed_by || '',

		// First aid section
		first_aid_issue: data?.first_aid_issue || '',
		first_aid_expiry: data?.first_aid_expiry || '',
		first_aid_photo: data?.first_aid_photo || '',
		firstaid_assessed_by: data?.firstaid_assessed_by || '',

		// Safe Cert section
		cert_num: data?.cert_num || '',
		cert_issue_date: data?.cert_issue_date || '',
		cert_expiry_date: data?.cert_expiry_date || '',
		cert_photo: data?.cert_photo || '',
		scaff_cert_assessed_by: data?.scaff_cert_assessed_by || '',

		// Safe Op section
		sop_train: data?.sop_train || '',

		// Other section
		height_training: data?.height_training || '',
		height_training_issue: data?.height_training_issue || '',
		height_training_expiry: data?.height_training_expiry || '',
		height_training_assessed_by: data?.height_training_assessed_by || '',
		other_photo: data?.other_photo || '',
		ird_num: data?.ird_num || '',
		last_drug_test: data?.last_drug_test || '',
		kiwisaver: data?.kiwisaver || '',
		employment_contract: data?.employment_contract || '',

		// Confined Space
		confined_uploaded_by: data?.confined_uploaded_by || null,
		confined_file: data?.confined_file || '',
		confined_date: data?.confined_date || '',
		confined_expiry_date: data?.confined_expiry_date || '',

		// High Risk Works
		high_risk_works_file: data?.high_risk_works_file || '',
		high_risk_works_date: data?.high_risk_works_date || '',
		high_risk_works_expiry_date: data?.high_risk_works_expiry_date || '',
		high_risk_works_uploaded_by: data?.high_risk_works_uploaded_by || null,
		high_risk_works_class: data?.high_risk_works_class || '[]',

		// Working at Heights
		working_at_heights_file: data?.working_at_heights_file || '',
		working_at_heights_date: data?.working_at_heights_date || '',
		working_at_heights_expiry_date: data?.working_at_heights_expiry_date || '',
		working_at_heights_uploaded_by:
			data?.working_at_heights_uploaded_by || null,

		// White Card
		white_card_file: data?.white_card_file || '',
		white_card_date: data?.white_card_date || '',
		white_card_expiry_date: data?.white_card_expiry_date || '',
		white_card_uploaded_by: data?.white_card_uploaded_by || null,

		// IRATA
		irata_file: data?.irata_file || '',
		irata_date: data?.irata_date || '',
		irata_expiry_date: data?.irata_expiry_date || '',
		irata_uploaded_by: data?.irata_uploaded_by || null,
		irata_class: data?.irata_class || '[]',

		// Mast Climber
		master_climber_file: data?.master_climber_file || '',
		master_climber_date: data?.master_climber_date || '',
		master_climber_expiry_date: data?.master_climber_expiry_date || '',
		master_climber_uploaded_by: data?.master_climber_uploaded_by || null,
		master_climber_class: data?.master_climber_class || '[]',

		// STAFF
		staff_file: data?.staff_file || '',
		staff_date: data?.staff_date || '',
		staff_expiry_date: data?.staff_expiry_date || '',
		staff_uploaded_by: data?.staff_uploaded_by || null,
		staff_class: data?.staff_class || '[]',

		is_health_and_safety_officer: data?.is_health_and_safety_officer || false,
		status: data?.status || StaffStatus.Active,

		// DCSI Children
		dcsi_children_file: data?.dcsi_children_file || '',
		dcsi_children_date: data?.dcsi_children_date || '',
		dcsi_children_expiry_date: data?.dcsi_children_expiry_date || '',
		dcsi_children_uploaded_by: data?.dcsi_children_uploaded_by || null,

		// DCSI Aged Care
		dcsi_aged_care_file: data?.dcsi_aged_care_file || '',
		dcsi_aged_care_date: data?.dcsi_aged_care_date || '',
		dcsi_aged_care_expiry_date: data?.dcsi_aged_care_expiry_date || '',
		dcsi_aged_care_uploaded_by: data?.dcsi_aged_care_uploaded_by || null,

		branch: data?.branch || loggedInUser.branch,
	}

	const validationSchema = Yup.object({
		staff_name: Yup.string().required('Staff name is required'),
		mobile: Yup.string().required('Contact # is required'),
		type: Yup.string().required('Type is required'),
		email: Yup.string()
			.email('Invalid email address')
			.required('Email is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			const StaffData: IStaffRow = { ...values }
			if (
				StaffData.is_health_and_safety_officer === 'true' ||
				StaffData.is_health_and_safety_officer === true
			) {
				StaffData.is_health_and_safety_officer = true
			} else {
				StaffData.is_health_and_safety_officer = false
			}
			if (formType === 'create') {
				await createStaff(StaffData)
			}
			if (formType === 'update' && staff_id) {
				await updateStaff(StaffData, staff_id)
				if (StaffData.email === loggedInUser.email) {
					dispatch(
						updateUser({
							email: StaffData.email,
							name: StaffData.staff_name,
							branch: StaffData.branch,
							userType: StaffData.userType,
						})
					)
				}
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (staff_id && isLoading) {
		return (
			<div className="absolute top-1/3 right-1/4 transform translate-x-2/3 -translate-y-1/2 bg-white border border-gray-300 rounded-lg p-4 shadow-lg z-50">
				<Spinner />
			</div>
		)
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center px-2">
					<Input
						title="Staff Name"
						id="staff_name"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.staff_name}
						placeholder=""
						error={formik.errors.staff_name}
					/>
					<Dropdown
						label="Type"
						id="type"
						options={typeOptions}
						value={String(formik.values.type)}
						onChange={formik.setFieldValue}
						onBlur={formik.setFieldTouched}
						error={formik.errors.type}
					/>
				</div>
				<div className="flex items-center px-2">
					<Input
						title="Contact #"
						id="mobile"
						type="text"
						icon="phone"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.mobile}
						error={formik.errors.mobile}
						placeholder=""
					/>
					<Input
						title="Contact Email"
						id="email"
						type="email"
						icon="email"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.email}
						placeholder=""
						error={formik.errors.email}
					/>
				</div>
				<div className="flex items-center px-2">
					<Dropdown
						label="Access To Portal"
						id="accessToPortal"
						options={yesNoOptions}
						value={formik.values.accessToPortal}
						onChange={formik.setFieldValue}
						onBlur={formik.handleBlur}
					/>
				</div>
				{formik.values.accessToPortal === 'Yes' && (
					<div className="flex items-center px-2">
						<Dropdown
							label="User Type"
							id="userType"
							options={userTypeOptions}
							value={formik.values.userType}
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
						/>
					</div>
				)}
				{loggedInUser.branch == 'Head Office' && (
					<div className="flex items-center px-2">
						<Dropdown
							label="Branch"
							id="branch"
							options={branchOptions}
							value={String(formik.values.branch)}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							error={formik.errors.branch}
						/>
					</div>
				)}
				<div className="flex items-center px-2">
					<Input
						title="Position"
						id="position"
						type="text"
						placeholder=""
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.position}
					/>
					<Input
						title="PIN"
						id="pin"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.pin}
						placeholder="Pin"
						error={formik.errors.pin}
					/>
				</div>
				<Address
					streetId="street"
					streetId2="street_2"
					cityId="city"
					postalId="post_code"
					streetVal={formik.values.street}
					street_2Val={formik.values.street_2}
					cityVal={formik.values.city}
					postalVal={formik.values.post_code}
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
				/>
				<div className="flex items-center px-2">
					<DateSelect
						title="Start Date"
						id="start_date"
						value={formik.values.start_date}
						onChange={formik.setFieldValue}
					/>
					<DateSelect
						title="Date of Birth"
						id="dob"
						value={formik.values.dob}
						onChange={formik.setFieldValue}
					/>
				</div>

				<SafeWorkingAtHeights
					values={formik.values}
					staff={staffListData.data}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/>

				<ConfinedSpace
					values={formik.values}
					staff={staffListData.data}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/>

				<ConstructionWhiteCard
					values={formik.values}
					staff={staffListData.data}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/>

				<HighRiskWorks
					values={formik.values}
					staff={staffListData.data}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/>
				{/* <IRATA
					values={formik.values}
					staff={staffListData.data}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/> */}
				{/* <MasterClimber
					values={formik.values}
					staff={staffListData.data}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/> */}
				<FirstAid
					values={formik.values}
					staff={staffListData.data}
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/>
				<DriverLicense
					values={formik.values}
					staff={staffListData.data}
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/>
				<PoliceCheck
					values={formik.values}
					staff={staffListData.data}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/>
				<DCSIChildren
					values={formik.values}
					staff={staffListData.data}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/>
				<DCSIAgedCare
					values={formik.values}
					staff={staffListData.data}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/>
				{/* <Staff
					values={formik.values}
					staff={staffListData.data}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/> */}
				{/* <HealthSafety
					values={formik.values}
					staff={staffListData.data}
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/> */}
				{/* <BuildingPassport
					values={formik.values}
					staff={staffListData.data}
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
					setFieldValue={formik.setFieldValue}
					setFieldTouched={formik.setFieldTouched}
				/> */}

				<div className="w-1/2 pl-2">
					<Dropdown
						label="Status"
						id="status"
						options={statusOptions}
						value={String(formik.values.status)}
						onChange={formik.setFieldValue}
						onBlur={formik.handleBlur}
					/>
				</div>

				{/* <div className="w-1/2 pl-2">
					<Dropdown
						label="Is Health and Safety?"
						id="is_health_and_safety_officer"
						options={trueFalseOptions}
						value={String(formik.values.is_health_and_safety_officer)}
						onChange={formik.setFieldValue}
						onBlur={formik.handleBlur}
					/>
				</div> */}
			</SideModal>
		</>
	)
}
