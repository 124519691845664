import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'

export const useInvoiceDocumentByID = (invoice_document_id?: number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchInvoiceDocumentByID = async (id: number) => {
		try {
			const response = await getRequest(
				AppRoutes.serverInvoiceRoutes.getDocument,
				id
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting Invoice Document')
			throw new Error('Something went wrong getting Invoice Document')
		}
	}

	if (!invoice_document_id) {
		return {
			data: null,
			isLoading: null,
			error: null,
			fetchInvoiceDocumentByID,
		}
	}

	const { data, isLoading, error } = useQuery(
		['invoice_document', invoice_document_id],
		() => fetchInvoiceDocumentByID(invoice_document_id)
	)

	return { data, isLoading, error, fetchInvoiceDocumentByID }
}

export const useAllInvoiceDocuments = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()
	const loggedInUser = useSelector((store: AppStore) => store.user)


	const fetchAllInvoiceDocuments = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverInvoiceRoutes.getAllDocuments
			)
			const data = response.data
			return data?.filter(
				(invoice_document: any) => invoice_document.jobData.branch === loggedInUser?.branch || loggedInUser.branch === 'Head Office'
			)
		} catch (error) {
			showError('Something went wrong getting Invoice Documents')
			throw new Error('Something went wrong getting Invoice Documents')
		}
	}

	const { data, isLoading, error } = useQuery(
		['invoice_documents'],
		fetchAllInvoiceDocuments
	)

	return { data, isLoading, error, fetchAllInvoiceDocuments }
}

// TODO: Implement in backend controller first
// export const useAllInvoiceDocumentsByJobId = (job_id?: number) => {
// 	const { showError } = useContext(NotificationsContext)
// 	const { getRequest } = useApi()

// 	const fetchInvoiceDocumentByID = async () => {
// 		try {
// 			const response = await getRequest(
// 				AppRoutes.serverInvoiceRoutes,
// 			)
// 			return response.data
// 		} catch (error) {
// 			showError('Something went wrong getting Invoice Documents')
// 			throw new Error('Something went wrong getting Invoice Documents')
// 		}
// 	}

// 	const { data, isLoading, error } = useQuery(
// 		['job_invoice_documents', job_id],
// 		fetchInvoiceDocumentByID
// 	)

// 	return { data, isLoading, error }
// }
