import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'
import { AppStore } from 'redux/store'
import { Roles } from 'models'

export const useGetTagByTag = (tag?: string) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchTagsByTag = async (tag_no: string) => {
		try {
			const response = await getRequest(
				AppRoutes.serverScaffoldRegisterRoutes.getScaffoldRegisterByTag,
				tag_no
			)
			return response.data
		} catch (error) {
			showError('Error loading tag data...')
			throw new Error('Error loading tag data...')
		}
	}

	const runQuery = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverScaffoldRegisterRoutes.getScaffoldRegisterByTag,
				tag
			)
			return response.data || null
		} catch (error) {
			console.log(error)
			showError('Something went wrong getting the scaffold register')
			throw new Error('Something went wrong getting the scaffold register')
		}
	}

	if (!tag) {
		return {
			data: undefined,
			isLoading: false,
			error: undefined,
			fetchTagsByTag,
		}
	}

	const { data, isLoading, error } = useQuery(['tag', tag || ''], runQuery)

	return { data, isLoading, error, fetchTagsByTag }
}
