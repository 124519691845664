import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'

export const useSWMSTasks = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchAllSWMSTasks = async () => {
		try {
			const response = await getRequest(AppRoutes.serverSWMSTasksRoutes.getAll)
			return response.data
		} catch (error) {
			showError('Something went wrong getting SWMSTasks')
			throw new Error('Something went wrong getting SWMSTasks')
		}
	}

	const { data, isLoading, error } = useQuery(['swms_tasks'], fetchAllSWMSTasks)

	return { data, isLoading, error }
}
