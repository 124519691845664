import { useState } from 'react'
import { DateSelect, Dropdown, Input, TextArea } from 'common'
import { ContactsForm } from 'components/Contacts'
import { FormikProps } from 'formik'
import { useEffect } from 'react'
import { OptionsForDropdown, isVoidOrMobileJob } from 'utilities'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

interface IProps {
	formik: FormikProps<{
		client_id: string
		job_type: string
		branding: string
		site: string
		start_date: string
		end_date: string
		job_status: string
		notes: string
		status: string
		PO_Number: string
		Requester: string
		type: string
		description: string
		total_hours: number
		percentage_erect: number
		percentage_dismantle: number
		percentage_complete: number
		LastEditDate: string
		hire_rate: number | null
		task_value: number | null
		hire_period: number | null
		visit_options: string[]
		tower_size: string
		deck_level: string
		invoice_paid: string
		branch: string | null
	}>
	formType: 'create' | 'update'
}

export const optionsTowerSize = [
	{
		label: 'Single Width Frame 0.7mtr x 1.9mtr Deck Length',
		value: 'Single Width Frame 0.7mtr x 1.9mtr Deck Length',
	},
	{
		label: 'Single Width Frame 0.7mtr x 2.4mtr Deck Length',
		value: 'Single Width Frame 0.7mtr x 2.4mtr Deck Length',
	},
	{
		label: 'Single Width Frame 0.7mtr x 3.0mtr Deck Length',
		value: 'Single Width Frame 0.7mtr x 3.0mtr Deck Length',
	},
	{
		label: 'Double Width Frame 1.2mtr x 1.9mtr Deck Length',
		value: 'Double Width Frame 1.2mtr x 1.9mtr Deck Length',
	},
	{
		label: 'Double Width Frame 1.2mtr x 2.4mtr Deck Length',
		value: 'Double Width Frame 1.2mtr x 2.4mtr Deck Length',
	},
	{
		label: 'Double Width Frame 1.2mtr x 3.0mtr Deck Length',
		value: 'Double Width Frame 1.2mtr x 3.0mtr Deck Length',
	},
]

export const optionsDeckLevel = [
	{ label: '1.5', value: '1.5' },
	{ label: '1.9', value: '1.9' },
	{ label: '2.4', value: '2.4' },
	{ label: '2.8', value: '2.8' },
	{ label: '3.2', value: '3.2' },
	{ label: '3.6', value: '3.6' },
	{ label: '4.0', value: '4.0' },
	{ label: '4.4', value: '4.4' },
	{ label: '4.8', value: '4.8' },
	{ label: '5.2', value: '5.2' },
	{ label: '5.7', value: '5.7' },
	{ label: '6.1', value: '6.1' },
]
export const MobileScaffoldingForm = ({ formik, formType }: IProps) => {
	return (
		<>
			{formType === 'create' && (
				<div className="flex items-center px-2">
					<Dropdown
						id="tower_size"
						label="Tower Size"
						options={optionsTowerSize}
						value={formik.values.tower_size}
						onChange={formik.setFieldValue}
						onBlur={formik.handleBlur}
						error={formik.errors.tower_size}
					/>
					<Dropdown
						id="deck_level"
						label="Deck Level"
						options={optionsDeckLevel}
						value={formik.values.deck_level}
						onChange={formik.setFieldValue}
						onBlur={formik.handleBlur}
						error={formik.errors.deck_level}
					/>
				</div>
			)}
		</>
	)
}
