import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SWMSTaskForm } from 'components/SWMS/TasksForm'

export const SWMSTasksEditForm = () => {
	const [openSWMSTaskForm, setOpenSWMSTaskForm] = useState(true)
	const navigate = useNavigate()

	const { id } = useParams()

	useEffect(() => {
		if (!openSWMSTaskForm) {
			navigate(-1)
		}
	}, [openSWMSTaskForm])

	return (
		<SWMSTaskForm
			formType="update"
			heading="Edit SWMS Task"
			open={openSWMSTaskForm}
			setOpen={setOpenSWMSTaskForm}
			task_id={Number(id)}
		/>
	)
}
