// Visit type options should now be:
/*
Install
Raise
Lower
Dismantle
Modify
Strip/Move
Travel
*/

export const visitTypeOptions = [
	{
	  value: 'Install',
	  label: 'Install',
	},
	{
	  value: 'Raise',
	  label: 'Raise',
	},
	{
	  value: 'Lower',
	  label: 'Lower',
	},
	{
		value: 'Modify',
		label: 'Modify',
	},
	{
		value: 'Strip/Move',
		label: 'Strip/Move',
	},
	{
	  value: 'Dismantle',
	  label: 'Dismantle',
	},
	{
	  value: 'Travel',
	  label: 'Travel',
	},
	{
	  value: 'Adjustment',
	  label: 'Adjustment',
	},
	{
	  value: 'Remedial',
	  label: 'Remedial',
	},
	{
	  value: 'Drop',
	  label: 'Drop',
	},
	{
	  value: 'Reinstall',
	  label: 'Reinstall',
	},
	{
	  value: 'Removal',
	  label: 'Removal',
	},
  ];
  

export const visitsStatusOptions = [
	{ label: 'Pending Prestart', value: 'Pending Prestart' },
	{ label: 'Pending Close of Visit', value: 'Pending Close of Visit' },
	{ label: 'Completed', value: 'Completed' },
]
