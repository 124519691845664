import { Dropdown, Input, SideModal, Spinner } from 'common'
import { useFormik } from 'formik'
import { InvoiceServices, QuoteServices } from 'services'
import { OptionsForDropdown } from 'utilities'
import * as Yup from 'yup'

interface IProps {
	job_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IInitialValues {
	zone: string
	zone_label: string
	type: string
	description: string
	total: number
}

export const EdInvoiceCreateForm = ({
	job_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { data: rates, isLoading } = QuoteServices.useServiceRates()

	const { createEdInvoice } = InvoiceServices.useCreateEDInvoice()

	const initialValues: IInitialValues = {
		zone: '',
		zone_label: '',
		type: '',
		description: '',
		total: 0,
	}

	const validationSchema = Yup.object({})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create') {
				createEdInvoice(Number(job_id), {
					zone: values.zone,
					zone_label: values.zone_label,
					type: values.type,
					description: values.description,
					total_invoiceable: Number(values.total),
					invoice_amount: Number(values.total),
				})
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (isLoading) {
		return (
			<div className="absolute top-1/3 right-1/4 transform translate-x-2/3 -translate-y-1/2 bg-white border border-gray-300 rounded-lg p-4 shadow-lg z-50">
				<Spinner />
			</div>
		)
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							title="Zone"
							id="zone"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Zone"
							value={formik.values.zone}
							error={formik.errors.zone}
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Zone Label"
							id="zone_label"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Zone Label"
							value={formik.values.zone_label}
							error={formik.errors.zone_label}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							id="type"
							label="Type"
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
							value={formik.values.type}
							error={formik.errors.type}
							options={OptionsForDropdown(rates, 'service', 'service')}
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Description"
							id="description"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Description"
							value={formik.values.description}
							error={formik.errors.description}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							title="Total"
							id="total"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Total"
							value={formik.values.total}
							error={formik.errors.total}
						/>
					</div>
				</div>
				{/* <div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							id="po_number"
							title="PO Number"
							placeholder=""
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.po_number}
							error={formik.errors.po_number}
						/>
					</div>
				</div> */}
			</SideModal>
		</>
	)
}
