import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { IRates } from 'models'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'

export const useServiceRates = () => {
	const queryClient = useQueryClient()
	const { showError, showSuccess } = useContext(NotificationsContext)
	const { getRequest, postRequest } = useApi()
	const loggedInUser = useSelector((store: AppStore) => store.user)

	const fetchServiceRates = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.getServiceRates
			)
			const data = response.data
			if (loggedInUser.branch === 'Head Office') {
				return data
			}
			const filteredData = data.filter(
				(rate: IRates) => rate.branch === loggedInUser.branch
			)
			return filteredData
		} catch (error) {
			showError('Something went wrong getting service rates')
			throw new Error('Something went wrong getting service rates')
		}
	}

	const upsertRates = async (data: IRates[]) => {
		try {
			const response = await postRequest(
				AppRoutes.serverQuoteRoutes.getServiceRates,
				{ rates: data }
			)
			showSuccess('Service rate created successfully')
			queryClient.refetchQueries(['serviceRates'])
			return response
		} catch (error) {
			console.log(error)
			showError('Something went wrong creating service rate')
			throw new Error('Something went wrong creating service rate')
		}
	}

	const { data, isLoading, error } = useQuery(
		['serviceRates'],
		fetchServiceRates
	)

	return { data, isLoading, error, upsertRates }
}

export const useDeleteServiceRate = () => {
	const { showError, showSuccess } = useContext(NotificationsContext)
	const { deleteRequest } = useApi()
	const queryClient = useQueryClient()

	const deleteRate = async (id: number | string) => {
		try {
			const response = await deleteRequest(
				AppRoutes.serverQuoteRoutes.deleteServiceRates,
				id
			)
			const data = response.data
			if(data.deleted) {
				showSuccess('Service rate deleted successfully')
			}
			else {
				showError('Something went wrong deleting service rate')
			}
			queryClient.refetchQueries(['serviceRates'])
		} catch (error) {
			showError('Something went wrong getting service rates')
			throw new Error('Something went wrong getting service rates')
		}
	}

	return { deleteRate }
}
