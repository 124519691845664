import { Table } from 'common'
import { columns } from './Columns'
import { useAppFilesTags } from 'services/appfiles'

interface ScaffoldTableProps {
	id: string | number
	disableButtons?: boolean
}

export const ScaffoldInspectionTable = ({
	id,
	disableButtons,
}: ScaffoldTableProps) => {
	const { data, isLoading } = useAppFilesTags(id)
	return (
		<div>
			<Table
				columns={columns}
				data={data}
				isLoading={isLoading}
				title="Scaffold Inspection Files"
				disableButtons={disableButtons || false}
			/>
		</div>
	)
}
