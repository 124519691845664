import * as logo from 'assets/logo.png'

interface ErrorProps {
	error_message?: string
}

export function ErrorComponent({ error_message }: ErrorProps) {
	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-50 py-3 px-4 sm:px-6 lg:px-8">
			<div className="max-w-md w-full space-y-8">
				<div>
					<img
						className="mx-auto h-24 w-auto"
						src={logo.default}
						alt="Workflow"
					/>
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
						Something went wrong
					</h2>
					{error_message && (
						<p className="mt-2 text-center text-sm text-gray-600">
							{error_message}
						</p>
					)}
				</div>
			</div>
		</div>
	)
}
