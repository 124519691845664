import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline'
import { Input, SideModal, Spinner, TextArea } from 'common'
import { useFormik } from 'formik'
import { useState } from 'react'
import { InvoiceServices, QuoteServices } from 'services'
import * as Yup from 'yup'

interface IProps {
	invoice_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IInitialValues {
	amount_to_invoice: number
	total: number
	invoice_amount: number
	previously_invoiced: number
	total_invoiceable: number
	po_number: string
	description: string
}

export const EdInvoiceEditForm = ({
	invoice_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { data: invoiceData, isLoading: invoiceLoading } =
		InvoiceServices.useEdInvoiceById(invoice_id)
	const { data: quoteData } = QuoteServices.useQuoteById(
		invoiceData?.jobData?.quote_id || -1
	)
	const { data: quoteLinesData } = QuoteServices.useQuoteLinesById(
		invoiceData?.jobData?.quote_id || -1
	)
	console.log('invoiceData', invoiceData)
	console.log('quoteData', quoteData)
	console.log('quoteLinesData', quoteLinesData)
	const { updateEdInvoice } = InvoiceServices.useUpdateEDInvoice()
	const { createEdInvoice } = InvoiceServices.useCreateEDInvoice()
	const [invoiceAmountEditable, setInvoiceAmountEditable] = useState(false)
	const [totalInvoiceAmountEditable, setTotalInvoiceAmountEditable] =
		useState(false)

	const initialValues: IInitialValues = {
		amount_to_invoice: 0,
		total: invoiceData?.total || 0,
		invoice_amount: invoiceData?.invoice_amount || 0,
		previously_invoiced: invoiceData?.previously_invoiced || 0,
		total_invoiceable: invoiceData?.total_invoiceable || 0,
		po_number: invoiceData?.po_number || '',
		description: invoiceData?.description || '',
	}

	const validationSchema = Yup.object({
		complete_percent: Yup.number().max(
			100,
			'Complete Percent must be smaller than or equal to 100'
		),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'update' && invoice_id) {
				if (
					values.amount_to_invoice < values.invoice_amount &&
					values.amount_to_invoice > 0
				) {
					const clonedInvoice = {
						description: invoiceData.description,
						invoice_amount:
							invoiceData.invoice_amount - values.amount_to_invoice,
						job_id: invoiceData.job_id,
						last_invoice: invoiceData.last_invoice,
						last_time_updated: invoiceData.last_time_updated,
						po_number: invoiceData.po_number,
						previously_invoiced:
							invoiceData.previously_invoiced + values.amount_to_invoice,
						status: invoiceData.status,
						task_id: invoiceData.task_id,
						total_invoiceable: invoiceData.total_invoiceable,
						type: invoiceData.type,
						zone: invoiceData.zone,
						zone_label: invoiceData.zone_label,
						complete_percent: invoiceData.complete_percent,
						total: invoiceData.total,
					}

					await createEdInvoice(invoiceData?.job_id, clonedInvoice)
					await updateEdInvoice(invoice_id, {
						...invoiceData,
						invoice_amount: values.amount_to_invoice,
						po_number: values.po_number,
						description: values.description,
						total_invoiceable: values.total_invoiceable,
					})
				} else {
					await updateEdInvoice(invoice_id, {
						...invoiceData,
						invoice_amount: values.invoice_amount,
						po_number: values.po_number,
						description: values.description,
						total_invoiceable: values.total_invoiceable,
					})
				}
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (invoice_id && invoiceLoading) {
		return (
			<div className="absolute top-1/3 right-1/4 transform translate-x-2/3 -translate-y-1/2 bg-white border border-gray-300 rounded-lg p-4 shadow-lg z-50">
				<Spinner />
			</div>
		)
	}
	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							id="amount_to_invoice"
							title="Amount to Invoice"
							placeholder="e.g 1000"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.amount_to_invoice}
							error={formik.errors.amount_to_invoice}
						/>
					</div>
					<div className="w-1/2 flex items-end">
						<Input
							id="invoice_amount"
							title="Invoice Amount"
							placeholder="invoice_amount"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.invoice_amount}
							error={formik.errors.invoice_amount}
							disabled={!invoiceAmountEditable}
						/>
						<div>
							<button
								type="button"
								onClick={() => setInvoiceAmountEditable(!invoiceAmountEditable)}
								className="ml-2 p-2 mb-2 border rounded-full hover:bg-gray-200 flex items-center justify-center">
								{invoiceAmountEditable ? (
									<LockOpenIcon className="h-5 w-5 text-gray-700" />
								) : (
									<LockClosedIcon className="h-5 w-5 text-gray-700" />
								)}
							</button>
						</div>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							id="previously_invoiced"
							title="Previously Invoiced"
							placeholder=""
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.previously_invoiced}
							error={formik.errors.previously_invoiced}
							disabled
						/>
					</div>
					<div className="w-1/2 flex items-end">
						<Input
							id="total_invoiceable"
							title="Total Invoiceable"
							placeholder="E.g. 1000"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.total_invoiceable}
							error={formik.errors.total_invoiceable}
							disabled={!totalInvoiceAmountEditable}
						/>
						<div>
							<button
								type="button"
								onClick={() =>
									setTotalInvoiceAmountEditable(!totalInvoiceAmountEditable)
								}
								className="ml-2 p-2 mb-2 border rounded-full hover:bg-gray-200 flex items-center justify-center">
								{totalInvoiceAmountEditable ? (
									<LockOpenIcon className="h-5 w-5 text-gray-700" />
								) : (
									<LockClosedIcon className="h-5 w-5 text-gray-700" />
								)}
							</button>
						</div>
					</div>
				</div>
				<div className="flex items-center px-2">
					<TextArea
						title="Description"
						handleBlur={formik.handleBlur}
						handleChange={formik.handleChange}
						placeholder="Fill out the Description..."
						id="description"
						value={formik.values.description}
						error={formik.errors.description}
						rows={5}
					/>
				</div>
				{invoiceData?.jobData?.quote_id && quoteData && quoteLinesData && (
					<div className="flex flex-col columns-1 items-start px-4">
						<label className="block text-sm font-medium text-gray-700 mt-4">
							Quote Data
						</label>
						<table className="w-full divide-y divide-gray-200 mt-4">
							<thead className="bg-gray-100">
								<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
									Description
								</th>
								<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
									Erect Hours
								</th>
								<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
									Dismantle Hours
								</th>
								<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
									Total Hours
								</th>
								<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
									Erect and Dismantle Cost
								</th>
								<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
									Sundries
								</th>
								<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
									Weekly Hire Fee
								</th>
								<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
									Included Hire Duration
								</th>
								<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
									Total Inc Hire Fee
								</th>
								<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
									Total Cost
								</th>
							</thead>
							<tbody>
								{quoteLinesData.map((quoteLine: any, index: number) => {
									const totalHours =
										Number(quoteLine.erect_hours) +
										Number(quoteLine.dismantle_hours)
									const erectHoursPercentage =
										(Number(quoteLine.erect_hours) / totalHours) * 100
									const dismantleHoursPercentage =
										(Number(quoteLine.dismantle_hours) / totalHours) * 100

									const erectHoursPercentTextHandle = isNaN(
										erectHoursPercentage
									)
										? ''
										: ` (${erectHoursPercentage.toFixed(2)}%)`
									const dismantleHoursPercentTextHandle = isNaN(
										dismantleHoursPercentage
									)
										? ''
										: ` (${dismantleHoursPercentage.toFixed(2)}%)`
									const includedWeeklyHireTotal = 
										Number(quoteLine.weekly_hire_fee) * Number(quoteLine.hire_period)
									return (
										<tr key={quoteLine.id}>
											<td className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-black uppercase tracking-wider text-2xs">
												{quoteLine.description}
											</td>
											<td className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-black uppercase tracking-wider text-2xs">
												{quoteLine.erect_hours}{erectHoursPercentTextHandle}
											</td>
											<td className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-black uppercase tracking-wider text-2xs">
												{quoteLine.dismantle_hours}{dismantleHoursPercentTextHandle}
											</td>
											<td className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-black uppercase tracking-wider text-2xs">
												{totalHours}
											</td>
											<td className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-black uppercase tracking-wider text-2xs">
												{quoteLine.erect_and_dismantle}
											</td>

											<td className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-black uppercase tracking-wider text-2xs">
												{quoteLine.sundries}
											</td>
											<td className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-black uppercase tracking-wider text-2xs">
												{quoteLine.weekly_hire_fee}
											</td>
											<td className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-black uppercase tracking-wider text-2xs">
												{quoteLine.hire_period}
											</td>
											<td className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-black uppercase tracking-wider text-2xs">
												{includedWeeklyHireTotal}
											</td>
											<td className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-black uppercase tracking-wider text-2xs">
												{quoteLine.total}
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				)}
				{/* <div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							id="po_number"
							title="PO Number"
							placeholder=""
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.po_number}
							error={formik.errors.po_number}
						/>
					</div>
				</div> */}
			</SideModal>
		</>
	)
}
