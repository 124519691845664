import { SWMSDocumentForm } from 'components/SWMS/DocumentsForm'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const SWMSDocumentsEditForm = () => {
	const [openSWMSDocumentForm, setOpenSWMSDocumentForm] = useState(true)
	const navigate = useNavigate()

	const { id } = useParams()

	useEffect(() => {
		if (!openSWMSDocumentForm) {
			navigate(-1)
		}
	}, [openSWMSDocumentForm])

	return (
		<SWMSDocumentForm
			formType="update"
			heading="Edit SWMS Document"
			open={openSWMSDocumentForm}
			setOpen={setOpenSWMSDocumentForm}
			document_id={Number(id)}
		/>
	)
}
