import { PDFViewer } from '@react-pdf/renderer'
import { SummaryQuotePDFDocument } from './SummaryOutput'
import {
	QuoteData,
	IQuoteLine,
	IQuoteAdditionalLines,
	IQuoteFiles,
} from 'models'
import { DetailedQuotePDFDocument } from './DetailedOutput'

interface QuotePDFViewerProps {
	quote_data: QuoteData
	quote_lines: IQuoteLine[]
	quote_addons: IQuoteAdditionalLines[]
	quote_files: IQuoteFiles[]
}

export const QuotePDFViewer = ({
	quote_data,
	quote_lines,
	quote_addons,
	quote_files,
}: QuotePDFViewerProps) => {
	console.log(quote_data, 'quotedata')
	return (
		<PDFViewer key="pdfviewer" width="100%" height={window.innerHeight - 66}>
			{quote_data?.quote_output_type === 'Summary' ? (
				<SummaryQuotePDFDocument
					key="document"
					quote_data={quote_data}
					quote_lines={quote_lines}
					quote_addons={quote_addons}
					quote_files={quote_files}
				/>
			) : (
				<DetailedQuotePDFDocument
					key="document"
					quote_data={quote_data}
					quote_lines={quote_lines}
					quote_addons={quote_addons}
					quote_files={quote_files}
				/>
			)}
		</PDFViewer>
	)
}
