import { useNavigate } from 'react-router-dom'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import * as logo from 'assets/logo.png'
import { ScaffoldRegisterServices } from 'services'
import { AppRoutes } from 'config'
import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

export function ScaffoldTagViewer() {
	const navigator = useNavigate()
	const { fetchTagsByTag } = ScaffoldRegisterServices.useGetTagByTag()
	const { showError, showSuccess, showInfo } = useContext(NotificationsContext)

	const validationSchema = Yup.object({
		tag: Yup.string().required('Please enter a tag '),
	})

	const formik = useFormik({
		initialValues: {
			tag: '',
		},
		validationSchema,
		onSubmit: async ({ tag }) => {
			showInfo('Searching for tag...')
			const tagRecord = await fetchTagsByTag(tag)
			if (tagRecord) {
				showSuccess('Tag found')
				navigator(
					`${AppRoutes.publicRoutes.ViewScaffTagDetail.replace(':id', tag)}`
				)
			} else {
				showError('Tag not found')
			}
		},
	})

	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-50 py-3 px-4 sm:px-6 lg:px-8">
			<div className="max-w-md w-full space-y-8">
				<div>
					<img
						className="mx-auto h-24 w-auto"
						src={logo.default}
						alt="Workflow"
					/>
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
						Scaffold Tag Search
					</h2>
				</div>
				<form className="mt-8 space-y-6" onSubmit={formik.handleSubmit}>
					<input type="hidden" name="remember" value="true" />
					<div className="rounded-md shadow-sm -space-y-px">
						<div>
							<label htmlFor="tag-search" className="sr-only">
								Scaffold Tag Barcode Number
							</label>
							<div className="flex items-center justify-center">
								<input
									id="tag-search"
									name="tag"
									type="text"
									autoComplete="off"
									required
									className="appearance-none rounded-none relative block w-2/3 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-l-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
									placeholder="Enter tag number from barcode..."
									onChange={formik.handleChange}
									value={formik.values.tag}
								/>
								<button
									type="submit"
									className="group relative w-1/3 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									<span className="absolute left-0 inset-y-0 flex items-center pl-3">
										<MagnifyingGlassIcon
											className="h-5 w-5 text-indigo-100 group-hover:text-indigo-400"
											aria-hidden="true"
										/>
									</span>
									Search
								</button>
							</div>
						</div>
						{formik.errors.tag ? (
							<span className="text-sm text-red-500">{formik.errors.tag}</span>
						) : null}
					</div>
					<div className="flex items-center justify-center"></div>
				</form>
			</div>
		</div>
	)
}

export default ScaffoldTagViewer
