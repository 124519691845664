import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'

export const useCrews = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()
	const loggedInUser = useSelector((store: AppStore) => store.user)


	const fetchAllCrews = async () => {
		try {
			const response = await getRequest(AppRoutes.serverCrewRoutes.getAllCrews)
			const data = response.data
			return data?.filter(
				(crew: any) => crew.branch === loggedInUser?.branch || loggedInUser.branch === 'Head Office'
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting Crews')
			throw new Error('Something went wrong getting Crews')
		}
	}

	const { data, isLoading, error } = useQuery(['crews'], fetchAllCrews)

	return { data, isLoading, error }
}
