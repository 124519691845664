import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'
import { AppStore } from 'redux/store'
import { useSelector } from 'react-redux'

export const useClients = (hide_inactive = false) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()
	const loggedInUser = useSelector((store: AppStore) => store.user)

	const fetchAllClients = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverClientRoutes.getAllClients
			)
			if (loggedInUser.branch === 'Head Office') return response?.data as any[]
			const filteredClients = response.data.filter(
				(client: any) => client.branch === loggedInUser.branch
			)
			return filteredClients
		} catch (error) {
			showError('Something went wrong getting Clients')
			throw new Error('Something went wrong getting Clients')
		}
	}

	const { data, isLoading, error } = useQuery(['clients'], fetchAllClients)

	if(hide_inactive) {
		return {
			data: data?.filter((client: any) => client.status === 'Active'),
			isLoading,
			error
		}
	}

	return { data, isLoading, error }
}
