import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { JobSchedulerUtils } from 'common/Scheduler'
import { Spinner } from 'common'
import { StyledWrapper } from '../../common/Scheduler/styledComponents'

export function JobScheduler() {
	const {
		visitsData,
		visitsLoading,
		headerToolbar,
		eventContent,
		eventDrop,
		formatEvents,
	} = JobSchedulerUtils()

	if (visitsLoading) {
		return <Spinner />
	}

	const CalenderRenderer = () => (
		<FullCalendar
			headerToolbar={{
				...headerToolbar,
				center: 'Day,Week,Month',
			}}
			views={{
				Month: {
					type: 'dayGridMonth',
					slotLabelInterval: { days: 1 },
					buttonText: 'Month',
					weekends: true,
					eventContent,
				},
				Week: {
					type: 'dayGridWeek',
					slotLabelInterval: { days: 1 },
					buttonText: 'Week',
					weekends: true,
					eventContent,
				},
				Day: {
					type: 'dayGridDay',
					slotLabelInterval: { days: 1 },
					buttonText: 'Day',
					weekends: true,
					eventContent,
				},
			}}
			eventContent={eventContent}
			plugins={[
				dayGridPlugin,
				timeGridPlugin,
				interactionPlugin,
				resourceTimelinePlugin,
			]}
			initialView="Month"
			height="auto"
			editable
			selectable
			dayMaxEvents
			firstDay={1}
			events={formatEvents()}
			eventDrop={eventDrop}
			schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
		/>
	)

	return (
		<StyledWrapper>
			<CalenderRenderer />
		</StyledWrapper>
	)
}
