import { FilterMatchMode } from 'primereact/api'
import {
	DataTable,
	DataTableDataSelectableEvent,
	DataTableSelectionChangeEvent,
} from 'primereact/datatable'
import { ApproveInvoices, DeleteInvoice } from 'components/Invoices'
import { InvoiceServices, DataTableServices } from 'services'
import { Container, DataTableHeader, PageHeading } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { numberFormat } from 'utilities'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import { AppRoutes } from 'config'
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline'

export const MainInvoicesTable = () => {
	const location = useLocation()
	const { data, isLoading, enableCreateUpdate } =
		InvoiceServices.useInvoices('Pending')
	const [invoicesSelected, setInvoicesSelected] = useState<any[] | null>(null)

	const deselectRow = (id: string) => {
		if (invoicesSelected) {
			const newInvoicesSelected = invoicesSelected.filter(
				(invoice) => invoice.id !== id
			)
			setInvoicesSelected(newInvoicesSelected)
		}
	}

	useEffect(() => {
		// Check each invoice in invoicesSelected, and check if it's id is in data and has status Pending
		// remove any others from invoicesSelected
		if (invoicesSelected !== undefined && invoicesSelected !== null) {
			const newInvoicesSelected = invoicesSelected.filter((invoice) => {
				const dataInvoice = data?.find(
					(dataInvoice) => dataInvoice.id === invoice.id
				)
				return dataInvoice ? dataInvoice.status === 'Pending' : false
			})
			console.warn('Removed deleted invoices from selection')
			console.warn('Old: ', invoicesSelected)
			console.warn('New: ', newInvoicesSelected)
			setInvoicesSelected(newInvoicesSelected)
		}
	}, [data])

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'invoiceType',
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterInitialValue: '',
				filterOptions: [],
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
			},
			{
				filterName: 'jobData.job_type',
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterInitialValue: '',
				filterOptions: [],
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
			},
		],
		additionalGlobalFilterFields: [
			'invoiceType',
			'jobData.job_type',
			'jobData.clientData.client_name',
			'jobData.last_invoice_date',
			'jobData.site',
			'description',
			'jobData.po_number',
			'date_on_hire',
			'completed_date',
			'zone_label',
			'type',
			'jobData.job_num',
			'jobData.scaffold_type',
			'on_hire',
			'date_on_hire',
			'completed_date',
			'days_on_hire',
			'weekly_hire_rate',
			'invoice_amount',
			'previously_invoiced',
			'total_invoiceable',
			'updatedAt',
		],
	})

	const headerMapping = {
		'jobData.job_num': 'Job Number',
		'jobData.clientData.client_name': 'Client Name',
		'jobData.site': 'Site',
		'jobData.scaffold_type': 'Hire Code',
		invoiceType: 'Invoice Type',
		zone_label: 'Zone Label',
		type: 'Type',
		description: 'Description',
		'jobData.po_number': 'PO Number',
		on_hire: 'On Hire',
		date_on_hire: 'Date On Hire',
		completed_date: 'Date Completed',
		days_on_hire: 'Days on Hire',
		weekly_hire_rate: 'Weekly Hire Rate',
		invoice_amount: 'Invoice Amount',
		previously_invoiced: 'Previously Invoiced',
		total_invoiceable: 'Total Invoiceable',
		updatedAt: 'Last Time Updated',
	}

	const convertToCSV = (
		data: any[],
		headerMapping: Record<string, string>
	): string => {
		const headers = [
			'jobData.job_num',
			'jobData.clientData.client_name',
			'jobData.site',
			'jobData.scaffold_type',
			'invoiceType',
			'zone_label',
			'type',
			'description',
			'jobData.po_number',
			'on_hire',
			'date_on_hire',
			'completed_date',
			'days_on_hire',
			'weekly_hire_rate',
			'invoice_amount',
			'previously_invoiced',
			'total_invoiceable',
			'updatedAt',
		]
		const renamedHeaders = headers.map(
			(header) => headerMapping[header] || header
		)

		const rows = data.map((row) => {
			return headers
				.map((header) => {
					const value = header
						.split('.')
						.reduce((acc: any, key: string) => acc[key], row)
					return value ? `"${value}"` : ''
				})
				.join(',')
		})

		return [renamedHeaders.join(','), ...rows].join('\n')
	}

	const downloadCSV = (csv: string, filename: string) => {
		const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
		const link = document.createElement('a')
		if (link.download !== undefined) {
			const url = URL.createObjectURL(blob)
			link.setAttribute('href', url)
			link.setAttribute('download', filename)
			link.style.visibility = 'hidden'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}

	const exportCSV = () => {
		if (dataTableReference.current && filteredData) {
			const sortedData = filteredData.sort(
				(a: { id: string }, b: { id: string }) => Number(b.id) - Number(a.id)
			)
			const csv = convertToCSV(sortedData, headerMapping)
			downloadCSV(csv, 'invoices.csv')
		}
	}

	const header = DataTableHeader({
		customExportCSV: exportCSV,
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
		customFilters: [
			{
				field: 'jobData.job_type',
				value: '',
				label: 'All',
				color: 'success',
			},
			{
				field: 'jobData.job_type',
				exclude: 'Void Protection',
				value: '',
				label: 'Standard',
				color: 'success',
			},
			{
				field: 'jobData.job_type',
				value: 'Void Protection',
				label: 'Void Protection',
				color: 'warning',
			},
		],
	})
	// Hacky workaround to force a new invoices window when new selections are made.
	const selectedInvoicesString = invoicesSelected?.reduce((acc, invoice) => {
		const invoiceString = `${invoice.id}-${invoice.invoice_type}`
		return acc ? `${acc}, ${invoiceString}` : invoiceString
	}, '')

	const dataSelectable = (event: DataTableDataSelectableEvent<any>) => {
		const invoice: any = event.data
		return invoice ? rowSelectable(invoice) : false
	}

	const rowSelectable = (invoice: any) => {
		// console.log(invoice)
		if (
			invoice.invoiceType === 'Weekly Hire' &&
			(!invoice.date_on_hire || invoice.date_on_hire === '')
		) {
			// console.log('Invoice with no on_hire date found. Not selectable.')
			return false
		}
		return true // TODO: Properly managed selection of quotes
	}

	// console.log(data)
	const preFilteredData = data
		?.filter((item) => item.jobData?.status === 'Active')
		?.map((item) => ({
			...item,
			updatedAt: new Date(item.updatedAt).toLocaleDateString('en-NZ'),
		}))

	// Invoices should be sorted such that if they have a blank or null invoice.jobData.last_invoice_date they should be at the top, then the oldest last_invoice_date finally, the most recent last_invoice_dates should be at the end. The format is DD/MM/YYYY for the field.
	const filteredData = preFilteredData?.sort((a, b) => {
		if (
			a.jobData?.last_invoice_date === '' ||
			a.jobData?.last_invoice_date === null
		) {
			return -1
		}
		if (
			b.jobData?.last_invoice_date === '' ||
			b.jobData?.last_invoice_date === null
		) {
			return 1
		}
		const aDate = a.jobData?.last_invoice_date.split('/')
		const bDate = b.jobData?.last_invoice_date.split('/')
		const aDateObj = new Date(`${aDate[1]}/${aDate[0]}/${aDate[2]}`)
		const bDateObj = new Date(`${bDate[1]}/${bDate[0]}/${bDate[2]}`)
		return aDateObj.getTime() - bDateObj.getTime()
	})

	return (
		<div className="card">
			<PageHeading title="Invoices" />
			<div
				key={selectedInvoicesString}
				className="px-8 flex justify-start space-x-4">
				<ApproveInvoices
					invoicesSelected={invoicesSelected}
					setInvoicesSelected={setInvoicesSelected}
					allInvoices={data}
				/>
				<ApproveInvoices
					invoicesSelected={invoicesSelected}
					setInvoicesSelected={setInvoicesSelected}
					allInvoices={data}
					endOfMonth
				/>
			</div>
			<br />
			<div className="px-8"></div>
			<Container>
				<DataTable
					scrollable
					scrollHeight="70vh"
					ref={dataTableReference}
					value={filteredData}
					paginator
					showGridlines
					rows={50}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading}
					dataKey="invoice_internal_id"
					groupRowsBy="job_id"
					sortField="job_id"
					sortOrder={-1}
					rowGroupMode="subheader"
					rowGroupHeaderTemplate={headerRow}
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					selectionMode='checkbox'
					selection={invoicesSelected}
					onSelectionChange={(e: DataTableSelectionChangeEvent<any>) => {
						setInvoicesSelected(e.value as any[])
					}}
					isDataSelectable={dataSelectable}
					// rowClassName={(data) => {
					// 	return rowSelectable(data) ? '' : 'disabled'
					// }}
					emptyMessage="No Invoices found.">
					<Column
						selectionMode="multiple"
						headerStyle={{ width: '3em' }}
						bodyClassName={(rowData) => {
							return rowSelectable(rowData) ? '' : 'disabled bg-red-100'
						}}
					/>
					<Column field="invoiceType" header="Invoice Type" />
					<Column field="zone_label" header="Zone Label" />
					<Column field="type" header="Type" />
					<Column field="description" header="Description" />
					<Column field="jobData.po_number" header="PO Number" />
					<Column field="on_hire" header="On Hire" />
					<Column field="date_on_hire" header="Date On Hire" />
					<Column field="completed_date" header="Date Completed" />
					<Column field="days_on_hire" header="Days on Hire" />
					<Column
						field="weekly_hire_rate"
						header="Weekly Hire Rate"
						body={(row: { weekly_hire_rate: number }) => {
							return numberFormat.format(row.weekly_hire_rate || 0) || ''
						}}
					/>
					<Column
						field="invoice_amount"
						header="Invoice Amount"
						body={(row: { invoice_amount: number }) => {
							return numberFormat.format(row.invoice_amount || 0) || ''
						}}
					/>
					<Column
						field="previously_invoiced"
						header="Previously Invoiced"
						body={(row: { previously_invoiced: number }) => {
							return numberFormat.format(row.previously_invoiced || 0) || ''
						}}
					/>
					<Column
						field="total_invoiceable"
						header="Total Invoiceable"
						body={(row: { total_invoiceable: number }) => {
							return numberFormat.format(row.total_invoiceable || 0) || ''
						}}
					/>
					<Column
						field="updatedAt"
						header="Last Time updated"
						sortField="updatedAt"
						sortable
					/>
					<Column
						header="Edit"
						body={(rowData) => {
							return (
								<Link
								className='z-50'
									to={{
										pathname: AppRoutes.privateRoutes.editInvoice
											.replace(':id', rowData.id)
											.replace(
												':invoiceType',
												rowData.invoiceType === 'Weekly Hire'
													? 'weeklyHire'
													: 'edInvoice'
											),
									}}
									state={{ background: location, name: 'editInvoice' }}>
									<PencilSquareIcon className="h-4 w-4 text-gray-500" />
								</Link>
							)
						}}
					/>
					{enableCreateUpdate && (
						<Column
							header="Delete"
							body={(row) => <DeleteInvoice invoice={row} deselectRow={deselectRow} />}
						/>
					)}
				</DataTable>
			</Container>
		</div>
	)
}

function invoicedThisMonth(date: string) {
	if (!date) {
		return false
	}
	const dateParts = date.split('/')
	const dateObj = new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`)
	const now = new Date()
	return (
		dateObj.getMonth() === now.getMonth() &&
		dateObj.getFullYear() === now.getFullYear()
	)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const headerRow = (data: any) => (
	<span
		className={`text-gray-900 font-bold rounded-md px-2 ${
			invoicedThisMonth(data?.jobData?.last_invoice_date)
				? 'bg-green-200 border border-gray-500'
				: ''
		}`}>{`${data?.jobData?.job_num} - ${
		data?.jobData?.clientData?.client_name
	} - Site: ${data?.jobData?.site} - Hire Code: ${
		data?.jobData?.scaffold_type
	} - Last Invoiced: ${data?.jobData?.last_invoice_date || ''}`}</span>
)
