import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useUpdateSWMSTasks = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const updateSWMSTask = async (swms_task_id: number, swms_task: unknown) => {
		try {
			const response = await postRequest(
				AppRoutes.serverSWMSTasksRoutes.update,
				swms_task,
				swms_task_id
			)
			queryClient.refetchQueries(['swms_tasks'])
			queryClient.refetchQueries(['swms_task', swms_task_id])
			showSuccess('SWMSTask updated successfully')
			return response
		} catch (error) {
			showError('Something went wrong updating SWMSTask')
			throw new Error((error as Error).message)
		}
	}
	return { updateSWMSTask }
}
