import { StyleSheet } from '@react-pdf/renderer'

export const tableStyles = StyleSheet.create({
	section: {
		fontFamily: 'Rosario',
		margin: 0,
		padding: 0,
		flexGrow: 1,
	},
	tableHeader: {
		width: '100%',
		flexDirection: 'row',
	},
	tableRow: {
		flexDirection: 'row',
		borderBottomColor: '#ebebeb',
	},
	tableHeaderColStart: {
		backgroundColor: '#f0f0f0',
		fontWeight: 'bold',
		padding: 5,
		borderLeft: 1,
		borderTop: 1,
		borderRight: 1,
		borderBottom: 1,
		borderBottomColor: 'black',
		flex: 8,
	},
	tableHeaderCol: {
		backgroundColor: '#f0f0f0',
		fontWeight: 'bold',
		padding: 5,
		borderTop: 1,
		borderRight: 1,
		borderBottom: 1,
		borderBottomColor: 'black',
		flex: 2,
	},
	tableHeaderColEnd: {
		backgroundColor: '#f0f0f0',
		fontWeight: 'bold',
		padding: 5,
		borderTop: 1,
		borderRight: 1,
		borderBottom: 1,
		borderBottomColor: 'black',
		textAlign: 'right',
		flex: 2,
	},
	tableColStart: {
		flex: 8,
		padding: 5,
		borderLeft: 1,
		borderRight: 1,
		// borderBottom: 1,
		borderBottomColor: 'grey',
	},
	tableCol: {
		flex: 2,
		padding: 5,
		borderRight: 1,
		// borderBottom: 1,
		borderBottomColor: 'grey',
	},
	tableColEnd: {
		flex: 2,
		padding: 5,
		// borderBottom: 1,
		borderRight: 1,
		borderBottomColor: 'grey',
		textAlign: 'right',
	},
	tableCell: {
		fontSize: 10,
	},
	tableSummaryStart: {
		flex: 8,
		padding: 5,
	},
	tableSummaryEnd: {
		flex: 2,
		padding: 5,
		borderLeft: 1,
		borderBottom: 1,
		borderRight: 1,
	},
})

export const styles = StyleSheet.create({
	page: {
		fontFamily: 'Helvetica',
		fontSize: 11,
		paddingTop: 20,
		paddingBottom: 40,
		paddingLeft: 50,
		paddingRight: 50,
		lineHeight: 1.5,
		flexDirection: 'column',
	},
	heading: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		width: 150,
		alignItems: 'flex-start',
		fontFamily: 'Open Sans',
		fontWeight: 'semibold',
		fontSize: 9,
		color: '#1A8140',
		marginTop: 8,
	},
	headingContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	headingContact: {
		textAlign: 'right',
		marginTop: 20,
	},
	headingDisplay: {
		display: 'flex',
		flexDirection: 'row',
	},
	headingText: {
		fontFamily: 'Open Sans',
		fontWeight: 'semibold',
		fontSize: 7,
	},
	quoteText: {
		fontFamily: 'Open Sans',
		fontWeight: 'bold',
		fontSize: 18,
	},

	subText: {
		fontFamily: 'Open Sans',
		fontSize: 7,
		textAlign: 'justify',
		// fontWeight: "semibold",
	},
	subTextMargin: {
		fontFamily: 'Open Sans',
		fontSize: 9,
		marginTop: 15,
		// fontWeight: "semibold",
	},
	subTextBold: {
		fontFamily: 'Open Sans',
		marginLeft: 4,
		marginTop: 15,
		fontSize: 7,
		fontWeight: 'bold',
	},
	subTextBoldRed: {
		fontFamily: 'Open Sans',
		color: 'red',
		marginLeft: 4,
		marginTop: 15,
		fontSize: 7,
		fontWeight: 'bold',
		textAlign: 'right',
	},
	logo: {
		objectFit: 'contain',
		width: '20%',
		height: 'auto',
		paddingTop: 20,
	},
	footer: {
		flexDirection: 'row',
		position: 'absolute',
		bottom: 7,
		right: 0,
	},
	pageTermsOfTrade: {
		objectFit: 'contain',
		width: '105%',
		height: 'auto',
		marginLeft: '-20',
	},
	footerLogo: {
		objectFit: 'contain',
		width: '110%',
		// height: "auto",
		marginRight: 0,
	},

	footerText: {
		fontSize: 6,
		paddingLeft: 10,
	},

	pageNumber: {
		// position: "absolute",
		marginTop: 10,
		marginRight: 17,
		// paddingLeft: 70,
		fontSize: 10,
		// bottom: 30,
		// left: 0,
		// right: 0,
		textAlign: 'center',
		color: 'grey',
	},
	imageRow: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: 10,
		marginTop: 50,
	},
	image: {
		height: '100%',
	},
	imageText: {
		fontFamily: 'Open Sans',
		fontSize: 10,
		maxWidth: '100%',
	},

	photosPage: {
		alignContent: 'center',
		justifyContent: 'center',
		marginTop: 10,
	},
	imageContainer: {
		margin: 10,
	},
	photoContainer: {
		margin: 10,
		textAlign: 'center',
		alignContent: 'center',
		justifyContent: 'center',
		position: 'relative',
		backgroundColor: '#f0f0f0', // Add a background color for better visibility
	},
	photosImage: {
		width: '100%',
		height: 'auto',
	},
	imageDescription: {
		marginTop: 5,
		fontSize: 10,
	},
})
