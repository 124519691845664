export const publicRoutes = {
	Login: '/login',
	SetPasswordReset: '/changePassword/:token',
	SetPassword: '/setPassword',
	ForgotPassword: '/forgot-password',
	AcceptQuote: '/accept-quote/:token',
	ViewScaffTag: '/tag-viewer',
	ViewScaffTagDetail: '/tag-viewer/:id',
	PageNotFound: '*',
}

export const privateRoutes = {
	Dashboard: '/dashboard',
	AppFiles: '/appFiles',
	Crews: '/crews',
	CrewsEdit: '/crews/:id/edit',
	Staff: '/staff',
	StaffDetail: '/staff/:id/details',
	StaffEdit: '/staff/:id/edit',
	StaffCompetencies: '/staffCompetencies',
	Clients: '/clients',
	ClientsEdit: '/clients/:id/edit',
	ClientsDetail: '/clients/:id/details',
	ClientContactEdit: '/contact/:id/edit',
	Quotes: '/quotes',
	QuotesCreate: '/quotes/create',
	QuotesEdit: '/quotes/:id/edit',
	QuotesDetail: '/quotes/:id/details',
	QuotesPDF: '/quotes/:id/output',
	Vehicles: '/vehicles',
	VehiclesEdit: '/vehicles/:id/edit',
	VehiclesDetail: '/vehicles/:id/details',
	Assets: '/assets',
	AssetsEdit: '/assets/:id/edit',
	AssetsDetail: '/assets/:id/details',
	SWMSTasks: '/swms_tasks',
	SWMSTasksEdit: '/swms_tasks/:id/edit',
	SWMSTasksDetail: '/swms_tasks/:id/details',
	SWMSDocuments: '/swms_documents',
	SWMSDocumentsEdit: '/swms_documents/:id/edit',
	SWMSDocumentsDetail: '/swms_documents/:id/details',
	Jobs: '/jobs',
	JobsEdit: '/jobs/:id/edit',
	JobsDetail: '/jobs/:id/details',
	JobScheduler: '/jobScheduler',
	JobStaffScheduler: '/staffScheduler',
	JobVoidScheduler: '/voidScheduler',
	tasksEdit: '/jobs/tasks/:id/edit',
	variationTasksEdit: '/jobs/variationTasks/:id/edit',
	Invoices: '/invoices',
	InvoiceDocuments: '/invoice_documents',
	editInvoice: '/invoices/:invoiceType/:id/edit',
	InvoicePDFOutput: '/invoice_documents/:id/output',
	visits: '/visits',
	visitsEdit: '/visits/:id/edit',
	timesheets: '/timesheets',
	timesheetsApproved: '/ApprovedTimesheets',
	timesheetsEdit: '/timesheets/:id/edit',
	scaffoldRegister: '/scaffold-register',
	scaffoldRegisterEdit: '/scaffold-register/:id/edit',
	scaffoldRegisterDetail: '/scaffold-register/:id/details',
	leave: '/leave',
	leaveEdit: '/leave/:id/edit',
	LeaveApproved: '/ApprovedLeave',
	InvestigationReports: '/investigationReports',
	Support: '/support',
	SupportTicketEdit: '/support/:id/edit',
}

export const serverAppFilesRoutes = {
	getAllAppFiles: 'app-files',
	getAppFile: 'app-files/:id',
	createAppFile: 'app-files',
	updateAppFile: 'app-files/:id',
	getAllAppFilesVehicles: 'app-files/vehicle/:id',
	getAppFilesJobs: 'app-files/job/:id',
	getAppFilesStaff: 'app-files/staff/:id',
	getAppFilesScaffoldRegister: 'app-files/scaffold-register/:id',
	getAppFilesClients: 'app-files/clients/:id',
}

export const serverNotesAndFilesRoutes = {
	getAllNotesAndFiles: 'notes',
	getNotesAndFiles: 'notes/:id',
	createNotesAndFiles: 'notes',
	updateNotesAndFiles: 'notes/:id',
	getAllNotesAndFilesByVehicleId: 'notes/vehicle/:id',
	getAllNotesAndFilesByJobId: 'notes/job/:id',
	getAllNotesAndFilesByStaffId: 'notes/staff/:id',
	getAllNotesAndFilesByAssetsId: 'notes/assets/:id',
	getAllNotesAndFilesByClientsId: 'notes/client/:id',
	getAllNotesAndFilesByScaffoldRegisterId: 'notes/scaffold-register/:id',
}

export const serverAuthRoutes = {
	checkEnableConfig: 'enableConfig',
	setUpServer: 'setupServer',
	login: 'auth/login',
	logout: 'auth/logout',
	resetPasswordVerifyToken: 'auth/check-token-reset-password',
	resetPassword: 'auth/reset-password',
	requestResetPassword: 'auth/request-password-reset',
}

export const serverIncidentReportsRoutes = {
	getAllIncidentReports: 'investigation-report',
	getIncidentReports: 'investigation-report/:id',
	createIncidentReports: 'investigation-report',
	updateIncidentReports: 'investigation-report/:id',
}

export const serverStaffRoutes = {
	getAllStaff: 'staff',
	getStaff: 'staff/:id',
	createStaff: 'staff',
	updateStaff: 'staff/:id',
}

export const serverJobsRoutes = {
	getAllJobs: 'jobs',
	getJob: 'jobs/:id',
	createJob: 'jobs',
	updateJob: 'jobs/:id',
	getJobTasks: 'jobs/:id/tasks',
	getTask: 'jobs/job/tasks/:id',
	getAllTask: 'jobs/all/tasks/all',
	createVariationTask: 'jobs/:id/tasks/variation',
	deleteTask: 'jobs/job/tasks/:id',
	updateTask: 'jobs/job/tasks/:id',
	getHandoverByJobId: 'jobs/:id/handover',
	updateHandover: 'jobs/job/handover/:id',
	sendHandoverEmail: 'jobs/job/handover/:id/sendEmail',
}

export const serverScaffoldRegisterRoutes = {
	getAllScaffoldRegister: 'scaffold-register',
	getAllScaffoldRegisterByJobId: 'scaffold-register/job/:id',
	getScaffoldRegister: 'scaffold-register/:id',
	getScaffoldRegisterByTag: 'scaffold-register/tag/:id',
	createScaffoldRegister: 'scaffold-register',
	updateScaffoldRegister: 'scaffold-register/:id',
}

export const serverClientRoutes = {
	getAllClients: 'clients',
	getClient: 'clients/:id',
	createClient: 'clients',
	updateClient: 'clients/:id',
}

export const serverCrewRoutes = {
	getAllCrews: 'crews',
	getCrew: 'crews/:id',
	createCrew: 'crews',
	updateCrew: 'crews/:id',
}

export const serverContactRoutes = {
	getAllContacts: 'contacts',
	getContactsByClientID: 'clients/:id/contacts',
	getContact: 'contacts/:id',
	createContact: 'contacts',
	updateContact: 'contacts/:id',
}

export const serverQuoteRoutes = {
	getAllQuotes: 'quotes',
	getQuote: 'quotes/:id',
	getQuoteByToken: 'quote_tokens/:id',
	createQuote: 'quotes',
	updateQuote: 'quotes/:id',
	getServiceRates: 'service_rates',
	deleteServiceRates: 'service_rates/:id',
	quote_lines: 'quotes/:id/lines',
	quote_addons: 'quotes/:id/addons',
	quote_zones: 'quotes/:id/zones',
	quote_rates: 'quotes/:id/rates',
	sendQuoteEmail: 'quotes/:id/sendQuoteEmail',
	markAsPendingEmail: 'quotes/:id/markPendingEmail',
	markAsSentEmail: 'quotes/:id/markSentEmail',
	declineQuote: 'quotes/:id/decline',
	approveQuote: 'quotes/:id/approve',
	acceptQuoteByToken: 'quote_tokens/:id/approve',
	getQuoteFilesById: 'quotes/id/:id/files',
	deleteQuoteFileByQuoteFileId: 'quotes/files/:id',
}

export const serverInvoiceRoutes = {
	getAllInvoices: 'invoices',
	getAllInvoicesByBranch: 'invoices/by-branch',
	getEDInvoicesByJobId: 'invoices/edinvoice/job/:id',
	getWeeklyInvoicesByJobId: 'invoices/weeklyHire/job/:id',
	getEdinvoice: 'invoices/edinvoice/:id',
	getWeeklyInvoice: 'invoices/weeklyHire/:id',
	createEdinvoice: 'invoices/edinvoice/job/:id',
	createWeeklyInvoice: 'invoices/weeklyHire/job/:id',
	updateEdinvoice: 'invoices/edinvoice/:id',
	updateWeeklyInvoice: 'invoices/weeklyHire/:id',
	deleteEdinvoice: 'invoices/edinvoice/:id',
	deleteWeeklyInvoice: 'invoices/weeklyHire/:id',
	approveInvoices: 'invoices/approve',
	getAllDocuments: 'invoice_documents',
	getDocument: 'invoice_documents/:id',
	createDocument: 'invoice_documents',
	updateDocument: 'invoice_documents/:id',
	sendInvoiceDocumentEmail: 'invoice_documents/:id/sendInvoiceDocumentEmail',
}

export const serverVisitRoutes = {
	getAllVisits: 'visits',
	getVisit: 'visits/:id',
	createVisit: 'visits',
	updateVisit: 'visits/:id',
	getVisitByJobId: 'visits/job/:id',
}

export const serverTimesheetRoutes = {
	getAllTimesheets: 'timesheets/all',
	getAllTimesheetsByStaffId: 'timesheets/staff/:id',
	getAllTimesheetsByJobId: 'timesheets/job/:id',
	getAllTimesheetsByStatus: 'timesheets/status/:id',
	getTimesheet: 'timesheets/:id',
	createTimesheet: 'timesheets',
	updateTimesheet: 'timesheets/:id',
}

export const serverVisitTimesheetRoutes = {
	getAllVisitTimesheetsByJobId: 'visit_timesheets/job/:id',
}

export const serverLeavesRoutes = {
	getAllLeaves: 'leaves',
	getAllApprovedLeaves: 'leaves/approved',
	getLeave: 'leaves/:id',
	createLeave: 'leaves',
	updateLeave: 'leaves/:id',
	approveLeave: 'leaves/:id/approve',
	declineLeave: 'leaves/:id/decline',
	getLeaveByStaffId: 'leaves/staff/:id',
	getAllLeavesByStaffId: 'leaves/staff/:id',
}

export const serverVehicleRoutes = {
	getAllVehicles: 'vehicles',
	getVehicle: 'vehicles/:id',
	createVehicle: 'vehicles',
	updateVehicle: 'vehicles/:id',
}

export const serverAssetsRoutes = {
	getAllAssets: 'assets',
	getAsset: 'assets/:id',
	createAsset: 'assets',
	updateAsset: 'assets/:id',
	getAllAssetCategories: 'asset_categories',
	getAssetTypes: 'asset_types/:id',
}

export const serverSWMSTasksRoutes = {
	getAll: 'swms_tasks',
	get: 'swms_tasks/:id',
	create: 'swms_tasks',
	update: 'swms_tasks/:id',
}

export const serverSWMSDocumentsRoutes = {
	getAll: 'swms_documents',
	get: 'swms_documents/:id',
	create: 'swms_documents',
	update: 'swms_documents/:id',
}

export const serverFilesRoutes = {
	getAllFiles: 'files',
	getFile: 'files/:id',
	createFile: 'files',
	updateFile: 'files/:id',
}

export const serverSetUpRoute = 'setUpServer'

export const serverSupportTicketRoutes = {
	getAllSupportTickets: 'support/',
	getAllSupportTicketsByStatus: 'timesheets/status/:id',
	getSupportTicket: 'support/:id',
	createSupportTicket: 'support/',
	updateSupportTicket: 'support/:id',
}
