import { PencilIcon } from '@heroicons/react/24/solid'
import { Spinner, Table } from 'common'
import { AppRoutes } from 'config'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { InvoiceServices } from 'services'
import { numberFormat } from 'utilities'
import { EdInvoiceCreateForm } from './CreateEdInvoice'

interface WeeklyHireTableProps {
	job_id?: number
}

export const EDInvoicesTable = ({ job_id }: WeeklyHireTableProps) => {
	const location = useLocation()
	const [openCreate, setOpenCreate] = useState(false)
	const { data, isLoading } = InvoiceServices.useEdInvoicesByJobId(job_id)

	if (isLoading) {
		return <Spinner />
	}

	console.log(data)

	const columns = [
		{ field: 'zone_label', header: 'Zone' },
		{ field: 'type', header: 'Type' },
		{
			field: 'description',
			header: 'Description',
		},
		{
			field: 'invoice_amount',
			header: 'Invoice Amount',
			body: (row: { invoice_amount: number }) => {
				return numberFormat.format(row.invoice_amount || 0)
			},
		},
		{
			field: 'previously_invoiced',
			header: 'Previously Invoiced',
			body: (row: { previously_invoiced: number }) => {
				return numberFormat.format(row.previously_invoiced || 0)
			},
		},
		{
			field: 'total_invoiceable',
			header: 'Total Invoiceable',
			body: (row: { total_invoiceable: number }) => {
				return numberFormat.format(row.total_invoiceable || 0)
			},
		},
		{
			field: 'complete_percent',
			header: '% Complete',
		},
		{
			field: 'jobData.po_number',
			header: 'PO Number',
			body: (row: { jobData: { po_number: string } }) => {
				return row.jobData.po_number
			},
		},
		{
			field: 'quote',
			header: 'Quote Number',
		},

		{
			field: 'Edit',
			header: 'Edit',
			body: (row: { id: number; status: string }) => {
				if (row.status === 'Pending') {
					return (
						<Link
							to={{
								pathname: AppRoutes.privateRoutes.editInvoice
									.replace(':id', row.id.toString())
									.replace(':invoiceType', 'edInvoice'),
							}}
							state={{ background: location, name: 'editInvoice' }}>
							<PencilIcon className="h-4 w-4 text-gray-500" />
						</Link>
					)
				}
				return <></>
			},
		},
	]

	return (
		<>
			<Table
				columns={columns}
				data={data}
				isLoading={isLoading ?? false}
				title="Fixed Price Invoices"
				ActionName="Create Fixed Price Invoice"
				setOpen={setOpenCreate}
				disableButtons
			/>
			<EdInvoiceCreateForm
				formType="create"
				heading="Create Fixed Price Invoice"
				open={openCreate}
				setOpen={setOpenCreate}
				job_id={job_id}
			/>
		</>
	)
}
