import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'
import { AppStore } from 'redux/store'
import { Roles } from 'models'

export const useJobs = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()
	const loggedInUser = useSelector((store: AppStore) => store.user)
	const branch = loggedInUser.branch

	const enableCreateUpdate = loggedInUser.userType === Roles.admin

	const fetchAllJobs = async () => {
		try {
			const url =
				branch !== 'Head Office'
					? `${AppRoutes.serverJobsRoutes.getAllJobs}?branch=${branch}`
					: AppRoutes.serverJobsRoutes.getAllJobs
			const response = await getRequest(url)
			return response.data
		} catch (error) {
			showError('Something went wrong getting Jobs')
			throw new Error('Something went wrong getting Jobs')
		}
	}

	const { data, isLoading, error } = useQuery(['jobs'], fetchAllJobs)

	return { data, isLoading, error, enableCreateUpdate }
}
