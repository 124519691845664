import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useUpdateSWMSDocuments = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const updateSWMSDocument = async (
		swms_task_id: number,
		swms_task: unknown
	) => {
		try {
			const response = await postRequest(
				AppRoutes.serverSWMSDocumentsRoutes.update,
				swms_task,
				swms_task_id
			)
			queryClient.refetchQueries(['swms_documents'])
			queryClient.refetchQueries(['swms_document', swms_task_id])
			showSuccess('SWMSDocument updated successfully')
			return response
		} catch (error) {
			showError('Something went wrong updating SWMSDocument')
			throw new Error((error as Error).message)
		}
	}
	return { updateSWMSDocument }
}
