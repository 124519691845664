import { ClientServices, VisitServices } from 'services'
import EventView from './EventContent'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { isVoidOnly } from 'utilities'

export function JobSchedulerUtils() {
	const location = useLocation()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventContent = (view: any) => (
		<EventView event={view.event} location={location} />
	)
	const { data: clientData, isLoading: clientsLoading } =
		ClientServices.useClients()
	const { data: visitsData, isLoading: visitsLoading } =
		VisitServices.useVisits()
	const { updateVisit } = VisitServices.useUpdateVisit()
	const headerToolbar = {
		left: 'title',
		right: 'prev,today,next',
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventDrop = async ({ event }: any) => {
		const visitId = Number(event?.id)
		const newDate = moment(event?.start).format('DD/MM/YYYY')
		if (visitId && newDate) {
			const payload = {
				date: newDate,
			}
			await updateVisit(visitId, payload)
		}
	}

	const formatEvents = () => {
		if (!visitsLoading && visitsData) {
			const nonVoidVisits = visitsData?.filter(
				(visit: any) => !isVoidOnly(visit?.jobData?.job_type) && visit.status === 'Active' && visit?.jobData?.status === 'Active'
			)
			return nonVoidVisits.map(
				(visit: {
					time_on: string
					id: any
					staff_ids: any[]
					jobData: { job_num: any; site: any; client_id: any }
					visit_status: any
					teamLeaderData: { staff_name: any; id: any }
					vehicle_ids: any
					type: any
					internal_notes: string
					date: moment.MomentInput
				}) => ({
					id: visit.id,
					resourceIds: [
						...visit?.staff_ids.map((staff: any) => staff),
						visit?.teamLeaderData?.id,
					],
					job_id: visit?.jobData?.job_num,
					title: visit.visit_status,
					teamLeader: visit.teamLeaderData?.staff_name || '',
					vehicles: visit?.vehicle_ids || [],
					type: visit?.type,
					internal_notes: visit?.internal_notes,
					jobData: visit.jobData,
					clientData: clientData?.find(
						(client: { id: any }) =>
							String(client.id) === String(visit.jobData?.client_id)
					),
					site: visit.jobData?.site,
					timeOn: visit?.time_on,
					start: moment(visit?.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
				})
			)
		}
		return []
	}

	return {
		formatEvents,
		visitsData,
		visitsLoading: visitsLoading || clientsLoading,
		headerToolbar,
		eventDrop,
		eventContent,
	}
}
