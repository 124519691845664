import { PageHeading } from 'common'
import Legend from 'common/Scheduler/Legend'
import { Scheduler } from 'components'

export const SchedulerMain = () => {
	return (
		<div className="px-6 py-6">
			<PageHeading title="Scaffold Scheduler" isEditable={false}>
				<Legend />
			</PageHeading>
			<Scheduler.JobScheduler />
		</div>
	)
}
