import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { DataTableServices, InvoiceDocumentsServices } from 'services'
import {
	Button,
	ConfirmationDialog,
	Container,
	DataTableHeader,
	PageHeading,
} from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { numberFormat } from 'utilities'
import { Link } from 'react-router-dom'
import {
	CheckIcon,
	DocumentMagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import { AppRoutes } from 'config'
import { useState } from 'react'

export const InvoiceDocumentsTable = () => {
	const [submitting, setSubmitting] = useState(false)
	const { data, isLoading } = InvoiceDocumentsServices.useAllInvoiceDocuments()
	const { sendInvoiceDocumentEmail } =
		InvoiceDocumentsServices.useSendInvoiceDocumentEmail()

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [],
		additionalGlobalFilterFields: [
			'id',
			'jobData.job_num',
			'clientData.client_name',
			'jobData.site',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})
	return (
		<div className="card">
			<PageHeading title="Invoice Documents" />
			<div className="px-8"></div>
			<Container>
				<DataTable
					scrollable
					scrollHeight="70vh"
					ref={dataTableReference}
					value={data}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading}
					dataKey="id"
					// groupRowsBy="job_id"
					// rowGroupMode="subheader"
					// rowGroupHeaderTemplate={headerRow}
					filters={filters}
					sortField="id"
					sortOrder={-1}
					globalFilterFields={globalFilterFields}
					header={header}
					emptyMessage="No Invoices found.">
					<Column
						field="id"
						header="Invoice Number"
						body={(row: { id: string }) => {
							return <span>#{String(Number(row.id))}</span>
						}}
					/>
					<Column field="jobData.job_num" header="Job Number" />
					<Column field="clientData.client_name" header="Client" />
					<Column field="jobData.site" header="Site" />
					{/* <Column field="total_invoiced" header="Total $ Invoiced" body={(rowData) => {
						return <span>{numberFormat.format(rowData.total_invoiced)}</span>
					 }} /> */}
					{/* <Column field="view_invoice_details" header="View Invoice Details" /> */}
					<Column
						header="View PDF"
						body={(rowData) => {
							return (
								<Link
									to={{
										pathname: AppRoutes.privateRoutes.InvoicePDFOutput.replace(
											':id',
											rowData.id
										),
									}}>
									<DocumentMagnifyingGlassIcon className="h-4 w-4 text-gray-500" />
								</Link>
							)
						}}
					/>
					<Column
						field="updatedAt"
						header="Last Time updated"
						body={(row: { updatedAt: string }) => {
							return new Date(row.updatedAt).toLocaleDateString('en-NZ')
						}}
					/>
					<Column field="integration_id" header="Pronto Xi Reference" />
					<Column
						header="Resend Email"
						body={(rowData) => {
							return (
								<ConfirmationDialog
									icon="info"
									title="Resend Invoice Email"
									body="This will resend the invoice in its current state"
									triggerButton={
										<button
											type="button"
											id={`${rowData.id}`}
											className="inline-flex items-center text-sm font-medium focus:outline-none hover:text-green-400">
											<CheckIcon
												className="-ml-0.5 mr-2 h-4 w-4 text-green-400"
												aria-hidden="true"
											/>
											Resend
										</button>
									}
									confirmButton={
										<Button
											isLoading={submitting}
											size="sm"
											variant="approve"
											onClick={async () => {
												setSubmitting(true)
												await sendInvoiceDocumentEmail(rowData.id)
												setSubmitting(false)
											}}>
											Resend Invoice
										</Button>
									}></ConfirmationDialog>
							)
						}}
					/>
				</DataTable>
			</Container>
		</div>
	)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const headerRow = (data: any) => (
	<span className="text-gray-900 font-bold">{`${data?.jobData?.job_num} - ${data?.jobData?.site}`}</span>
)
