import React, { useEffect, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { AppConfig } from 'config'

interface AddressProps {
	streetId2: string
	streetId: string
	countryId: string
	cityId: string
	postalId: string
	street2Val: string
	cityVal: string
	postalVal: string
	countryVal: string
	handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
	setFieldValue: (field: string, value: string) => void
}

export function SearchAddress({
	streetId2,
	streetId,
	cityId,
	postalId,
	countryId,
	street2Val,
	cityVal,
	postalVal,
	countryVal,
	handleBlur,
	setFieldValue,
}: AddressProps) {
	const googleApiKey = AppConfig.GoogleMapsApiKey
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handlePlaceSelect = (place: any) => {
		const addressComponents = place.address_components
		let street2 = ''
		let city = ''
		let postalCode = ''
		let country = ''
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		addressComponents.forEach((component: any) => {
			const { types } = component

			if (types.includes('street_number')) {
				if (street2 === '') {
					street2 = component.long_name
				} else {
					street2 += ` ${component.long_name}`
				}
			}

			if (types.includes('route')) {
				if (street2 === '') {
					street2 = component.long_name
				} else {
					street2 += ` ${component.long_name}`
				}
			}

			if (types.includes('sublocality')) {
				if (street2 === '') {
					street2 = component.long_name
				} else {
					street2 += `, ${component.long_name}`
				}
			}

			if (types.includes('locality')) {
				if (city === '') {
					city = component.long_name
				} else {
					city += `, ${component.long_name}`
				}
				cityVal = city
			}
			if (types.includes('country')) {
				if (country === '') {
					country = component.long_name
				} else {
					country += `, ${component.long_name}`
				}
				countryVal = country
			}

			// if (types.includes('administrative_area_level_1')) {
			// 	if (city === '') {
			// 		city = component.long_name
			// 	} else {
			// 		city += `, ${component.long_name}`
			// 	}
			// }

			if (types.includes('postal_code')) {
				postalCode = component.long_name
			}
		})

		setFieldValue('street2', street2)
		setFieldValue('street', place.formatted_address)
		setFieldValue('postal', postalCode)
		setFieldValue('city', city)
		setFieldValue('country', country)
	}

	return (
		<div className="px-2 py-4 mt-10 sm:mt-0">
			<div className="md:grid md:grid-cols-6 md:gap-6">
				<div className="col-span-6">
					<Autocomplete
						apiKey={googleApiKey}
						placeholder="Enter an address to search"
						style={{ width: '100%' }}
						className="autocomplete border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onPlaceSelected={(place) => {
							handlePlaceSelect(place)
						}}
						options={{
							types: ['address'],
							componentRestrictions: { country: 'au' },
						}}
					/>
				</div>

				<div className="col-span-3">
					<label
						htmlFor={streetId2}
						className="block text-sm font-medium text-gray-700">
						Address
					</label>
					<input
						type="text"
						name={streetId2}
						id={streetId2}
						autoComplete="street-address"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={(e) => {
							setFieldValue(streetId2, e.target.value)
							setFieldValue(
								streetId,
								`${e.target.value}, ${cityVal}, ${postalVal}`
							)
						}}
						onBlur={handleBlur}
						value={street2Val}
					/>
				</div>

				<div className="col-span-6 sm:col-span-6 lg:col-span-4">
					<label
						htmlFor="city"
						className="block text-sm font-medium text-gray-700">
						City
					</label>
					<input
						type="text"
						name={cityId}
						id={cityId}
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={(e) => {
							setFieldValue(cityId, e.target.value)
							setFieldValue(
								streetId,
								`${street2Val}, ${e.target.value}, ${postalVal}`
							)
						}}
						onBlur={handleBlur}
						value={cityVal}
					/>
				</div>
				<div className="col-span-6 sm:col-span-2 lg:col-span-2">
					<label
						htmlFor="postal-code"
						className="block text-sm font-medium text-gray-700">
						Postal Code
					</label>
					<input
						type="text"
						name={postalId}
						id={postalId}
						autoComplete="postal-code"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={(e) => {
							setFieldValue(postalId, e.target.value)
							setFieldValue(
								streetId,
								`${street2Val}, ${cityVal}, ${e.target.value}`
							)
						}}
						onBlur={handleBlur}
						value={postalVal}
					/>
				</div>
			</div>
		</div>
	)
}
