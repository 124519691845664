import { pdf } from '@react-pdf/renderer'
import {
	QuoteData,
	IQuoteLine,
	IQuoteAdditionalLines,
	IQuoteFiles,
} from 'models'
import { SummaryQuotePDFDocument } from './SummaryOutput'
import { DetailedQuotePDFDocument } from './DetailedOutput'

interface QuotePDFBlobProps {
	quote_data: QuoteData
	quote_lines: IQuoteLine[]
	quote_addons: IQuoteAdditionalLines[]
	quote_files: IQuoteFiles[]
}

export const MakeBlobQuote = async ({
	quote_data,
	quote_lines,
	quote_addons,
	quote_files,
}: QuotePDFBlobProps) => {
	if (quote_data?.quote_output_type === 'Summary') {
		const newBlob = await pdf(
			SummaryQuotePDFDocument({
				quote_data,
				quote_lines,
				quote_addons,
				quote_files,
			})
		).toBlob()

		return newBlob
	}
	const newBlob = await pdf(
		DetailedQuotePDFDocument({
			quote_data,
			quote_lines,
			quote_addons,
			quote_files,
		})
	).toBlob()

	return newBlob
}
