import { useParams } from 'react-router-dom'

import { TwoColumnDetails, Section } from 'common/Details'
import { Spinner, ErrorComponent } from 'common'
import { ScaffoldRegisterServices } from 'services'
import { dateFormat } from 'utilities'
import { AppFilesComponents } from 'components'

export const DetailsPage = () => {
	const { id } = useParams()

	const { data, error, isLoading } = ScaffoldRegisterServices.useGetTagById(
		Number(id)
	)

	if (isLoading) {
		return (
			<div className="w-full h-48 flex justify-center items-center">
				<Spinner />
			</div>
		)
	}

	if (error) {
		return <ErrorComponent />
	}

	console.log(data)

	return (
		<div className="w-full mx-auto mt-8 mb-28">
			{data && (
				<TwoColumnDetails heading="Scaffold Register Details">
					<Section title="Job" content={data?.jobData?.job_num} />
					<Section title="Job Address" content={data?.jobData?.site} />
					<Section title="Tag #" content={data?.tag_no} />
					<Section title="Description" content={data.description} />
					{/* <Section title='' content='' /> */}
					<Section title="Date Erected" content="" />
					<Section title="Last Inspection" content={data.last_inspection} />
					<Section title="Inspected By" content="" />
					<Section title="Inspection Due" content={data.inspection_due} />
					<Section
						title="Handover Doc"
						content={
							<a className="text-blue-600" href={data.handover_doc}>
								{data.handover_doc ? 'Download' : ''}
							</a>
						}
					/>
					<Section title="Status" content={data?.status} />
					<Section title="Created By" content={data.uploaded_by} />
					<Section
						title="Date Created"
						content={new Date(data.createdAt).toLocaleDateString('en-AU')}
					/>
				</TwoColumnDetails>
			)}
			<AppFilesComponents.FileList type="scaffoldInspection" id={id || ''} />
		</div>
	)
}
